import React from 'react';
import { render } from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from './components/ApolloClient';
import 'unfetch/polyfill';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'typeface-ubuntu';
import './index.scss';
import Router from './components/Router';
import './modules/synchronize-needs-attention-animations';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';

const WebApp = () => (
  <ApolloProvider client={ApolloClient}>
    <Router />
  </ApolloProvider>
);

const msalInstance = new PublicClientApplication(msalConfig);
render(
  <MsalProvider instance={msalInstance}>
    <WebApp />
  </MsalProvider>,
  document.getElementById('root')
);
