import React from 'react';

import style from './style.module.scss';

interface IProps {
  className?: string;
}

export default ({ className, ...props }: IProps) => (
  <svg
    className={[style.svg, className].join(' ')}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path d="M485.314,364.6,381.22,184.11c-14.23-24.672-49.837-24.672-64.067,0L213.059,364.6c-14.219,24.652,3.574,55.454,32.034,55.454H453.281C481.74,420.049,499.533,389.247,485.314,364.6ZM348.607,376.983q-19.04,0-19.037-19.038t19.037-19.037q19.039,0,19.042,19.037T348.607,376.983Zm21.418-136.821L357.63,312.23a6.359,6.359,0,0,1-6.267,5.282h-5.507a6.359,6.359,0,0,1-6.267-5.281l-12.4-72.069a23.512,23.512,0,0,1-.322-3.9q0-16.651,21.74-16.656t21.746,16.556A21.716,21.716,0,0,1,370.025,240.162ZM193.276,420.049h-128a41.645,41.645,0,0,1-41.594-41.594V133.545A41.645,41.645,0,0,1,65.279,91.951h258.3a41.646,41.646,0,0,1,41.6,41.594v7.3a61.808,61.808,0,0,0-26.784-1.026v-6.278a14.828,14.828,0,0,0-14.811-14.811H65.279a14.828,14.828,0,0,0-14.811,14.811v244.91a14.828,14.828,0,0,0,14.811,14.811h116.89a64.154,64.154,0,0,0,7.716,21.7C190.917,416.753,192.1,418.4,193.276,420.049ZM97.643,269.654a13.392,13.392,0,0,1-13.392-13.392V169.216a13.392,13.392,0,1,1,26.783,0v87.046A13.392,13.392,0,0,1,97.643,269.654Zm44.567,0a13.392,13.392,0,0,1-13.392-13.392V169.216a13.392,13.392,0,1,1,26.783,0v87.046A13.392,13.392,0,0,1,142.21,269.654Zm44.567,0a13.392,13.392,0,0,1-13.392-13.392V169.216a13.392,13.392,0,1,1,26.783,0v87.046A13.392,13.392,0,0,1,186.777,269.654Z" />
  </svg>
);
