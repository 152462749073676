import React from 'react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';

import style from './style.module.scss';

import NamedTank from '../../../../../../components/NamedTank';

const ZoneTank = ({ facilityId, className, zone }) => {
  const { name, tank } = zone;
  const { waterLevel, pumpState, gallons } = tank;
  return (
    <Link
      className={[style.container, className].join(' ')}
      to={`/facility/${facilityId}/zone/${zone.name}`}
    >
      <NamedTank
        entityType="Zone"
        entityName={name}
        waterLevel={waterLevel}
        pumpState={pumpState}
        gallons={gallons}
      />
    </Link>
  );
};

ZoneTank.fragment = gql`
  fragment ZoneTank on ZoneType {
    zoneId
    name
    tank {
      pumpState
      waterLevel
      gallons
    }
  }
`;

export default ZoneTank;
