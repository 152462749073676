import React, { useState, useEffect, FC } from 'react';
import { ApolloError } from 'apollo-client/errors/ApolloError';

import {
  Form,
  Modal,
  ModalProps,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';

import useModalVisible from '../../hooks/useModalVisible';

import Loading from '../Loading';
import ResultAlert from '../ResultAlert';
import ErrorMessages from '../ErrorMessages';

import style from './style.module.scss';

export interface SaveModalProps extends ModalProps {
  title: any;
  isFormValid?: boolean;
  isDirty?: boolean;
  saving: boolean;
  onSave: () => void;
  error: ApolloError | undefined;
  onComplete: (saved: boolean) => void;
  successContent?: any;
  saveButtonContent?: string;
  cancelButtonContent?: string;
  doneButtonContent?: string;
  hideSaveButton?: boolean;
  showCloseButton?: boolean;
}

export const SaveModal: FC<SaveModalProps> = ({
  title,
  className,
  isFormValid = true,
  isDirty,
  saving,
  onSave,
  error,
  onComplete,
  successContent,
  saveButtonContent = 'Save',
  cancelButtonContent = 'Cancel',
  doneButtonContent = 'Done',
  hideSaveButton = false,
  showCloseButton,
  children,
  ...props
}: SaveModalProps): JSX.Element => {
  const [visible, modalVisibleProps] = useModalVisible();
  const [hasSaved, setHasSaved] = useState<boolean>(false);

  useEffect(() => {
    if (!visible && hasSaved) {
      setHasSaved(false);
    }
  }, [visible, hasSaved, setHasSaved]);

  const effectiveModalProps = { ...props };

  effectiveModalProps.onOpened = (): void => {
    modalVisibleProps.onOpened();
    props.onOpened && props.onOpened();
  };

  effectiveModalProps.onClosed = (): void => {
    modalVisibleProps.onClosed();
    props.onClosed && props.onClosed();
  };

  const showSaving = saving || (!error && hasSaved && !successContent);
  const showError = !isDirty && hasSaved && error;
  const showSuccess = hasSaved && !showSaving && !showError && successContent;

  return (
    <Modal
      className={[style.modal, className].join(' ')}
      toggle={() => onComplete(false)}
      {...effectiveModalProps}
    >
      <ModalHeader>{title}</ModalHeader>

      <Form
        noValidate={true}
        onSubmit={e => {
          e.preventDefault();
          setHasSaved(true);
          onSave();
        }}
      >
        <ModalBody key="body" className={style.modalBody}>
          {saving && <Loading />}
          {showSuccess && (
            <div className={style.successContainer}>
              <ResultAlert success={true}>{successContent}</ResultAlert>
            </div>
          )}

          <div
            className={[
              style.contentContainer,
              showSaving ? style.saving : null,
              showSuccess ? style.success : null,
            ].join(' ')}
            style={{
              overflow: saving ? 'hidden' : undefined,
            }}
          >
            {children}
          </div>

          {showError && (
            <ResultAlert className={style.errorMessage} success={false}>
              <ErrorMessages error={error!} />
            </ResultAlert>
          )}
        </ModalBody>

        <ModalFooter key="footer">
          {showSuccess || showCloseButton ? (
            <Button color="primary" onClick={() => onComplete(true)}>
              Close
            </Button>
          ) : (
            <>
              <Button
                color="secondary"
                onClick={() => onComplete(false)}
                disabled={showSaving}
              >
                {cancelButtonContent}
              </Button>
              {!hideSaveButton && (
                <Button
                  type="submit"
                  color="primary"
                  disabled={!isFormValid || showSaving}
                >
                  {saveButtonContent}
                </Button>
              )}
            </>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};
