import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  zoneName: string;
  stackName: string;
  stackId: string;
  onCompleted?: () => void;
}

interface Result {
  openStackAutomaticModePrompt: () => void;
  openStackMaintenanceModePrompt: () => void;
  StackModePrompts: typeof StackModePrompts;
  stackModePromptsProps: Omit<Props, 'stackMode'>;
}

export const UPDATE_STACK_MODE = gql`
  mutation updateStackMode($stackId: ID!, $mode: StackMode!) {
    updateStackMode(stackId: $stackId, mode: $mode) {
      stackId
      mode
    }
  }
`;

interface Props extends Input {
  stackMode: string;
  automaticModePromptIsOpen: boolean;
  maintenanceModePromptIsOpen: boolean;
  closeAutomaticModePrompt: () => void;
  closeMaintenanceModePrompt: () => void;
}

const StackModePrompts = ({
  zoneName,
  stackName,
  stackId,
  stackMode,
  automaticModePromptIsOpen,
  maintenanceModePromptIsOpen,
  closeAutomaticModePrompt,
  closeMaintenanceModePrompt,
  onCompleted,
}: Props) => {
  const [updateStackMode, { error, loading: saving }] = useMutation(
    UPDATE_STACK_MODE,
    {
      onCompleted: () => {
        closeAutomaticModePrompt();
        closeMaintenanceModePrompt();
        onCompleted && onCompleted();
      },
    }
  );

  return (
    <>
      <PromptModal
        title={
          stackMode === 'HARVEST'
            ? `End Harvest and Put Zone ${zoneName} / Stack ${stackName} Into Automatic Mode`
            : `Put Zone ${zoneName} / Stack ${stackName} Into Automatic Mode`
        }
        isOpen={automaticModePromptIsOpen}
        saving={saving}
        error={error}
        onComplete={closeAutomaticModePrompt}
        onSave={() => {
          updateStackMode({
            variables: {
              stackId,
              mode: 'AUTOMATIC',
            },
          });
        }}
      >
        <p>
          Putting the stack into automatic mode will allow the level valves to
          automatically open according to their irrigation schedules.
        </p>

        <p>{`Are you sure you want to put Zone ${zoneName} / Stack ${stackName} into Automatic Mode?`}</p>
      </PromptModal>

      <PromptModal
        title={`Put Zone ${zoneName} / Stack ${stackName} Into Maintenance Mode`}
        isOpen={maintenanceModePromptIsOpen}
        danger={true}
        saving={saving}
        error={error}
        onComplete={closeMaintenanceModePrompt}
        onSave={() => {
          updateStackMode({
            variables: {
              stackId,
              mode: 'MAINTENANCE',
            },
          });
        }}
      >
        <p>
          Putting the stack into maintenance mode will prevent the level valves
          from automatically opening according to their irrigation schedules.
        </p>

        <p>{`Are you sure you want to put Zone ${zoneName} / Stack ${stackName} into Maintenance Mode?`}</p>
      </PromptModal>
    </>
  );
};

const useStackModePrompts: (input: Input) => Result = ({
  zoneName,
  stackName,
  stackId,
  onCompleted,
}: Input) => {
  const [automaticModePromptIsOpen, setAutomaticModePromptIsOpen] = useState(
    false
  );

  const [
    maintenanceModePromptIsOpen,
    setMaintenanceModePromptIsOpen,
  ] = useState(false);

  return {
    openStackAutomaticModePrompt: useCallback(
      () => setAutomaticModePromptIsOpen(true),
      [setAutomaticModePromptIsOpen]
    ),
    openStackMaintenanceModePrompt: useCallback(
      () => setMaintenanceModePromptIsOpen(true),
      [setMaintenanceModePromptIsOpen]
    ),
    StackModePrompts,
    stackModePromptsProps: {
      zoneName,
      stackName,
      stackId,
      automaticModePromptIsOpen,
      maintenanceModePromptIsOpen,
      onCompleted,
      closeAutomaticModePrompt: useCallback(
        () => setAutomaticModePromptIsOpen(false),
        [setAutomaticModePromptIsOpen]
      ),
      closeMaintenanceModePrompt: useCallback(
        () => setMaintenanceModePromptIsOpen(false),
        [setMaintenanceModePromptIsOpen]
      ),
    },
  };
};

export default useStackModePrompts;
