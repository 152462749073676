import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  ozoneSystemName: string;
  ozoneSystemId: string;
  onCompleted?: () => void;
}

interface Result {
  openOzoneActivateBypassValvePrompt: () => void;
  openOzoneDeactivateBypassPrompt: () => void;
  OzoneBypassValveStatePrompts: typeof OzoneBypassValveStatePrompts;
  ozoneBypassValveStatePromptsProps: Omit<Props, 'ozoneBypassValveState'>;
}

export const UPDATE_OZONE_BYPASS_VALVE_STATE = gql`
  mutation updateOzoneSystemBypassValveState(
    $input: UpdateOzoneSystemBypassValveStateInputType!
  ) {
    updateOzoneSystemBypassValveState(input: $input) {
      ozoneSystem {
        ozoneSystemId
        bypassValve {
          state
        }
      }
    }
  }
`;

interface Props extends Input {
  activateBypassValvePromptIsOpen: boolean;
  deactivateBypassValvePromptIsOpen: boolean;
  closeActivateBypassValvePrompt: () => void;
  closeDeactivateBypassPrompt: () => void;
}

const OzoneBypassValveStatePrompts = ({
  ozoneSystemName,
  ozoneSystemId,
  activateBypassValvePromptIsOpen,
  deactivateBypassValvePromptIsOpen,
  closeActivateBypassValvePrompt,
  closeDeactivateBypassPrompt,
  onCompleted,
}: Props) => {
  const [updateOzoneBypassValveState, { error, loading: saving }] = useMutation(
    UPDATE_OZONE_BYPASS_VALVE_STATE,
    {
      onCompleted: () => {
        closeActivateBypassValvePrompt();
        closeDeactivateBypassPrompt();
        onCompleted && onCompleted();
      },
    }
  );

  return (
    <>
      <PromptModal
        title={`Activate the Ozone System Bypass for Ozone System ${ozoneSystemName}`}
        isOpen={activateBypassValvePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateOzoneBypassValveState({
            variables: {
              input: { ozoneSystemId, bypassValveState: 'BYPASS_ACTIVE' },
            },
          });
        }}
        onComplete={closeActivateBypassValvePrompt}
      >
        <p>
          Activating the ozone system bypass valve will cause water being
          flushed from the stack tanks to flow back into the zone tanks.
        </p>

        <p>
          Active the ozone system bypass for Ozone System {ozoneSystemName}?
        </p>
      </PromptModal>

      <PromptModal
        title={`Deactivate the Ozone System Bypass for Ozone System ${ozoneSystemName}`}
        danger={true}
        isOpen={deactivateBypassValvePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateOzoneBypassValveState({
            variables: {
              input: { ozoneSystemId, bypassValveState: 'BYPASS_INACTIVE' },
            },
          });
        }}
        onComplete={closeDeactivateBypassPrompt}
      >
        <p>
          Deactivating the ozone system bypass will cause water being flushed
          from the stack tanks to flow into the ozone tank.
        </p>

        <p>
          WARNING: Deactivating the ozone system bypass may cause the ozone tank
          to overflow.
        </p>

        <p>
          Deactivate the ozone system bypass for Ozone System {ozoneSystemName}?
        </p>
      </PromptModal>
    </>
  );
};

const useOzoneBypassValveStatePrompts: (input: Input) => Result = ({
  ozoneSystemName,
  ozoneSystemId,
  onCompleted,
}: Input) => {
  const [
    activateBypassValvePromptIsOpen,
    setActivateBypassValvePromptIsOpen,
  ] = useState(false);

  const [
    deactivateBypassValvePromptIsOpen,
    setDeactivateBypassPromptIsOpen,
  ] = useState(false);

  return {
    openOzoneActivateBypassValvePrompt: useCallback(
      () => setActivateBypassValvePromptIsOpen(true),
      [setActivateBypassValvePromptIsOpen]
    ),
    openOzoneDeactivateBypassPrompt: useCallback(
      () => setDeactivateBypassPromptIsOpen(true),
      [setDeactivateBypassPromptIsOpen]
    ),
    OzoneBypassValveStatePrompts,
    ozoneBypassValveStatePromptsProps: {
      ozoneSystemName,
      ozoneSystemId,
      activateBypassValvePromptIsOpen,
      deactivateBypassValvePromptIsOpen,
      onCompleted,
      closeActivateBypassValvePrompt: useCallback(
        () => setActivateBypassValvePromptIsOpen(false),
        [setActivateBypassValvePromptIsOpen]
      ),
      closeDeactivateBypassPrompt: useCallback(
        () => setDeactivateBypassPromptIsOpen(false),
        [setDeactivateBypassPromptIsOpen]
      ),
    },
  };
};

export default useOzoneBypassValveStatePrompts;
