import React, { FC } from 'react';

import style from './style.module.scss';

interface Props {
  activityItem: any;
  biasPumpRoomNavigation?: any;
}

const Location: FC<Props> = ({
  activityItem,
  biasPumpRoomNavigation,
}: Props): JSX.Element => {
  const {
    facilityName,
    facilityId,
    ozoneSystemName,
    zoneName,
    nutrientMixerPumpName,
    stackName,
    levelName,
  } = activityItem;

  if (levelName) {
    return (
      <a
        className={style.locationName}
        href={`/facility/${facilityId}/zone/${zoneName}/stack/${stackName}`}
      >
        {`${facilityName} / Zone ${zoneName} / Stack ${stackName} / Level ${levelName}`}
      </a>
    );
  }

  if (stackName) {
    return (
      <a
        className={style.locationName}
        href={`/facility/${facilityId}/zone/${zoneName}/stack/${stackName}`}
      >
        {`${facilityName} / Zone ${zoneName} / Stack ${stackName}`}
      </a>
    );
  }

  if (nutrientMixerPumpName) {
    return (
      <a
        className={style.locationName}
        href={`/facility/${facilityId}/zone/${zoneName}`}
      >
        {`${facilityName} / Zone ${zoneName} / Nutrient Mixer Pump ${nutrientMixerPumpName}`}
      </a>
    );
  }

  if (biasPumpRoomNavigation && zoneName && ozoneSystemName) {
    return (
      <a
        className={style.locationName}
        href={`/facility/${facilityId}/ozone-system/${ozoneSystemName}`}
      >
        {`${facilityName} / Zone ${zoneName}`}
      </a>
    );
  }

  if (zoneName) {
    return (
      <a
        className={style.locationName}
        href={`/facility/${facilityId}/zone/${zoneName}`}
      >
        {`${facilityName} / Zone ${zoneName}`}
      </a>
    );
  }

  if (ozoneSystemName) {
    return (
      <a
        className={style.locationName}
        href={`/facility/${facilityId}/ozone-system/${ozoneSystemName}`}
      >
        {`${facilityName} / Ozone System ${ozoneSystemName}`}
      </a>
    );
  }

  return (
    <>
      the{' '}
      <a
        className={style.locationName}
        href={`/facility/${facilityId}`}
      >{`${facilityName} facility`}</a>
    </>
  );
};

export { Location };
