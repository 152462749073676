import sync from './css-animation-sync';

import waterLeakIconStyle from '../components/WaterLeakIcon/style.module.scss';
import pumpTimeoutIconStyle from '../components/PumpTimeoutIcon/style.module.scss';
import valveFailedIconStyle from '../components/ValveFailedIcon/style.module.scss';
import zoneButtonStyle from '../components/ZoneButton/style.module.scss';
import facilityZoneStacksStyle from '../pages/FacilityPage/components/Stacks/style.module.scss';
import valveStyle from '../components/Valve/style.module.scss';
import valveButtonStyle from '../components/ValveButton/style.module.scss';
import invalidWaterSensorStateIconStyle from '../components/InvalidWaterSensorStateIcon/style.module.scss';
import pumpRoomButtonStyle from '../pages/FacilityPage/components/FacilityNavigation/style.module.scss';
import cloggedStrainerIconStyle from '../components/CloggedStrainerIcon/style.module.scss';

sync([
  waterLeakIconStyle.needsAttentionPulse,
  pumpTimeoutIconStyle.needsAttentionPulse,
  valveFailedIconStyle.needsAttentionPulse,
  zoneButtonStyle.needsAttentionCriticalPulse,
  zoneButtonStyle.needsAttentionCriticalInactiveInnerPulse,
  facilityZoneStacksStyle.needsAttentionCriticalBorderPulse,
  valveStyle.needsAttentionPulse,
  valveButtonStyle.needsAttentionPulse,
  invalidWaterSensorStateIconStyle.needsAttentionPulse,
  pumpRoomButtonStyle.needsAttentionPulse,
  cloggedStrainerIconStyle.needsAttentionPulse,
]);
