import React from 'react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import {
  AttentionLevels,
  getPumpRoomAttentionLevel,
  getZoneAttentionLevel,
} from '../../../../modules/attention-levels';
import style from './style.module.scss';

import ZoneButton from '../../../../components/ZoneButton';

const SelectionType = {
  ZONE: 'zone',
  PUMP_ROOM: 'pumpRoom',
};

const pumpRoomButtonStyle = attentionLevel => {
  switch (attentionLevel) {
    case AttentionLevels.Critical:
      return style.attentionLevelCritical;
    case AttentionLevels.Low:
      return style.attentionLevelLow;
    default:
      return undefined;
  }
};

const FacilityNavigation = ({ facility, zones, currentSelection }) => {
  const { facilityId, pumpRoom } = facility;
  const pumpRoomAttentionLevel = getPumpRoomAttentionLevel(pumpRoom);

  return (
    <div className={style.container}>
      <div className={style.pumpRoomNavigationContainer}>
        <Link
          to={`/facility/${facilityId}`}
          className={[
            style.button,
            style.pumpRoom,
            pumpRoomButtonStyle(pumpRoomAttentionLevel),
            currentSelection &&
            currentSelection.type === FacilityNavigation.SelectionType.PUMP_ROOM
              ? style.active
              : null,
          ].join(' ')}
        >
          <div className={style.buttonLabel}>Pump Room</div>
        </Link>
      </div>

      <div className={style.zoneNavigationContainer}>
        {zones
          .filter(i => !i.isHidden)
          .map(zone => {
            const { zoneId, name } = zone;
            const attentionLevel = getZoneAttentionLevel(zone);

            return (
              <ZoneButton
                className={style.button}
                tag={Link}
                to={`/facility/${facilityId}/zone/${name}`}
                key={zoneId}
                zoneName={name}
                attentionLevel={attentionLevel}
                active={
                  currentSelection &&
                  currentSelection.type ===
                    FacilityNavigation.SelectionType.ZONE &&
                  zoneId === currentSelection.zone.zoneId
                }
              />
            );
          })}
      </div>

      <div className={style.pumpRoomNavigationContainer}></div>
    </div>
  );
};

FacilityNavigation.fragment = gql`
  fragment FacilityNavigation on FacilityType {
    facilityId
    zones {
      name
      isHidden
      zoneId
      ...GetZoneAttentionLevel
    }
    pumpRoom {
      ...GetPumpRoomAttentionLevel
    }
  }
  ${getZoneAttentionLevel.fragment}
  ${getPumpRoomAttentionLevel.fragment}
`;

FacilityNavigation.SelectionType = SelectionType;

export default FacilityNavigation;
