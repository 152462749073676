/*
  A form and form hook for providing a user with the means to read and modify subzone schedules.
 */

import React, { useState, useCallback, useEffect } from 'react';
import gql from 'graphql-tag';
import { useLazyQuery, useMutation, useQuery } from 'react-apollo';

import SubzoneLine from '../useModifyScheduleForm/components/ScheduleLine';
import { SaveModal, SaveModalProps } from '../../components/SaveModal';
import ScheduleLine from './components/ScheduleLine';
import { Subzone, newSubzone } from '../useModifySubzonesForm';
import moment from 'moment';

/* Definitions */

export interface SubzoneSchedule {
  rowIndex: number;
  days: number;
  timesPerDay: number;
  duration: number;
}

interface Result {
  openModifyScheduleSubzonePrompt: (e: Subzone) => void;
  ModifyScheduleSubzonePrompt: typeof ModifySubzoneSchedulePrompt;
  modifyScheduleSubzonePromptProps: Props;
}

interface Input {
  facilityId: number;
  zoneId: string;
  onCompleted?: () => void;
}

interface Props extends Input {
  subzone: Subzone;
  modifySubzoneSchedulePromptIsOpen: boolean;
  closeModifyScheduleSubzonePrompt: () => void;
}

/* Global Methods */

//Constructor for SubzoneSchedule.
export const newSubzoneSchedule: (i: number) => SubzoneSchedule = i => {
  return {
    duration: 2, //Minutes
    timesPerDay: 999,
    days: 1,
    rowIndex: i,
  };
};

export const allocSubzoneSchedule: () => SubzoneSchedule = () => {
  return {
    duration: 0, //Minutes
    timesPerDay: 0,
    days: 0,
    rowIndex: -1,
  };
};

export const noOp = () => {};

export const timeEpoch = () =>
  moment()
    .utcOffset(0)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

//Clone constructor for SubzoneSchedule.
export const cloneSubzoneSchedule: (
  self: SubzoneSchedule
) => SubzoneSchedule = self => {
  return {
    duration: self.duration,
    timesPerDay: self.timesPerDay,
    days: self.days,
    rowIndex: self.rowIndex,
  };
};

//An interface for sorting SubzoneSchedule.
export const subzoneScheduleComparable: (
  a: SubzoneSchedule,
  b: SubzoneSchedule
) => number = (a, b) => a.rowIndex - b.rowIndex;

//An interface for SubzoneSchedule comparisons.
export const isEqual: (
  self: SubzoneSchedule,
  other: SubzoneSchedule
) => boolean = (self, other) => {
  return (
    Number(self.rowIndex) === Number(other.rowIndex) &&
    Number(self.days) === Number(other.days) &&
    Number(self.timesPerDay) === Number(other.timesPerDay) &&
    Number(self.duration / 60) === Number(other.duration)
  );
};

/* GQL Queries */

const GET_SUBZONE_SCHEDULES = gql`
  query readSchedules($subzoneId: ID!, $facilityId: ID!) {
    subzoneSchedules(subzoneId: $subzoneId, facilityId: $facilityId) {
      zoneSubzoneScheduleId
      duration
      rowIndex
      subzoneId
      days
      timesPerDay
    }
    max: maxStacksInSubzone(subzoneId: $subzoneId, facilityId: $facilityId)
    subzoneTimespan(subzoneId: $subzoneId) {
      start
      end
    }
  }
`;

const SET_SUBZONE_SCHEDULES = gql`
  mutation setSchedules($levels: timespan!) {
    scheduleSubzone(input: $levels) {
      __typename
    }
  }
`;

/* React Components */

const ModifySubzoneSchedulePrompt = ({
  subzone,
  facilityId,
  zoneId,
  modifySubzoneSchedulePromptIsOpen,
  closeModifyScheduleSubzonePrompt,
  onCompleted,
}: Props) => {
  //GQL hooks.
  const [
    setSubzoneSchedules,
    { error: setSubzoneSchedulesError, loading: setSubzoneSchedulesLoading },
  ] = useMutation(SET_SUBZONE_SCHEDULES);

  const existingSchedulesStateHook = useQuery(GET_SUBZONE_SCHEDULES, {
    variables: { subzoneId: subzone.zoneSubzoneId, facilityId },
  });

  //Local schedules state and effect for dialog toggle.
  const [schedules, setSchedules] = useState<SubzoneSchedule[]>([]);
  const [startTime, setStartTime] = useState<string>(
    timeEpoch().format('HH:mm')
  );
  const [endTime, setEndTime] = useState<string>(
    timeEpoch()
      .add(23, 'h')
      .add(59, 'm')
      .add(59, 's')
      .format('HH:mm')
  );
  const [batchMarshal, setBatchMarshal] = useState<SubzoneSchedule>(
    allocSubzoneSchedule()
  );
  const [allMode, setAllMode] = useState<boolean>(false);
  useEffect(() => {
    if (!modifySubzoneSchedulePromptIsOpen) return;
    setSchedules([]);
    setStartTime(timeEpoch().format('HH:mm'));
    setEndTime(
      timeEpoch()
        .add(23, 'h')
        .add(59, 'm')
        .add(59, 's')
        .format('HH:mm')
    );
    existingSchedulesStateHook.refetch({
      subzoneId: subzone.zoneSubzoneId,
      facilityId,
    });
  }, [modifySubzoneSchedulePromptIsOpen]);

  useEffect(() => {}, [allMode]);

  useEffect(() => {
    if (
      existingSchedulesStateHook.error ||
      existingSchedulesStateHook.data == null
    )
      return;
    let next: SubzoneSchedule[] = [];

    const serverState = existingSchedulesStateHook.data.subzoneSchedules;
    if (serverState.length > 0) {
      //Bug Fix: Division will compound over refreshes so a new allocation is needed every time.
      next = serverState.map((i: SubzoneSchedule) => {
        let item = cloneSubzoneSchedule(i);
        item.duration /= 60;
        return item;
      });
    } else {
      [...Array(existingSchedulesStateHook.data.max + 1)].map((_, i) =>
        next.push(newSubzoneSchedule(i))
      );
    }

    if (existingSchedulesStateHook.data?.subzoneTimespan != null) {
      setStartTime(existingSchedulesStateHook.data.subzoneTimespan.start);
      setEndTime(existingSchedulesStateHook.data.subzoneTimespan.end);
    }

    next.sort(subzoneScheduleComparable);
    setSchedules(() => [...next]);
  }, [existingSchedulesStateHook.data]);

  //Update schedule callback.
  const updateSchedule = (newState: SubzoneSchedule) => {
    //Stop React bouncing.
    if (subzone == null) {
      return;
    }

    //Match new state to current state by index.
    for (let i = 0; i < schedules.length; i++) {
      if (schedules[i].rowIndex != newState.rowIndex) continue;

      //Update by index, sort, and update current state.
      schedules[i] = newState;
      schedules.sort(subzoneScheduleComparable);
      setSchedules([...schedules]);

      //Short circut once the index match is made.
      break;
    }
  };

  //Update all schedules callback
  const updateAllSchedules = (newState: SubzoneSchedule) => {
    //Stop React bouncing.
    if (subzone == null) {
      return;
    }

    //Match new state to current state by index.
    let stateProp = [];
    for (let i = 0; i < schedules.length; i++) {
      if (newState.days > 0) schedules[i].days = newState.days;
      if (newState.duration > 0) schedules[i].duration = newState.duration;
      if (newState.timesPerDay > 0)
        schedules[i].timesPerDay = newState.timesPerDay;
    }

    schedules.sort(subzoneScheduleComparable);
    setBatchMarshal(newState);
    setSchedules([...schedules]);
  };

  const saveSubzones = () => {
    //Stop React bouncing.
    if (subzone == null) {
      return;
    }

    //Find server state candidates for local state and check for modifications.
    const isModified = (i: SubzoneSchedule) => {
      let isNotModified = false;
      existingSchedulesStateHook.data.subzoneSchedules
        .filter((j: SubzoneSchedule) => j.rowIndex === i.rowIndex)
        .some((j: SubzoneSchedule) => isEqual(j, i));

      return !isNotModified;
    };

    //Set schedules where modifications have been made or a server state does not exist.
    setSubzoneSchedules({
      variables: {
        levels: {
          zoneId: Number(zoneId),
          subzoneId: Number(subzone.zoneSubzoneId),
          levelIrrigationParameters: schedules
            .filter(i => {
              return isModified(i);
            })
            .map(i => {
              return {
                index: Number(i.rowIndex),
                durationSeconds: Number(i.duration) * 60,
                everyNDays: Number(i.days),
                timesPerDay: Number(i.timesPerDay),
              };
            }),
          timeSpan: {
            subzoneId: Number(subzone.zoneSubzoneId),
            start: moment(startTime, 'HH:mm', true)
              .utcOffset(0)
              .toDate(),
            end: moment(endTime, 'HH:mm', true)
              .utcOffset(0)
              .toDate(),
          },
        },
      },
    }).then(() =>
      existingSchedulesStateHook.refetch({
        subzoneId: subzone.zoneSubzoneId,
        facilityId,
      })
    );

    //Close and call parent.
    closeModifyScheduleSubzonePrompt();
    onCompleted && onCompleted();
  };

  const inputTimeCallback = (callback: any, e: any) => {
    const value = e.target.value;
    callback(value);
  };

  return (
    <SaveModal
      title={`${subzone.name} Subzone Schedule`}
      isOpen={modifySubzoneSchedulePromptIsOpen}
      saving={false}
      error={undefined}
      onSave={saveSubzones}
      onComplete={() => {
        setSchedules([]);
        closeModifyScheduleSubzonePrompt();
      }}
      saveButtonContent="Save"
      isFormValid={true}
      className="my-5"
    >
      {existingSchedulesStateHook.loading && <p>loading</p>}
      <div className="row pb-4">
        <div className="col-12 col-sm-6 px-3 py-2">
          <input
            type="time"
            className="form-control"
            value={startTime}
            onChange={e => inputTimeCallback(setStartTime, e)}
          />
        </div>
        <div className="col-12 col-sm-6 px-3 py-2">
          <input
            type="time"
            className="form-control"
            value={endTime}
            onChange={e => inputTimeCallback(setEndTime, e)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-2">
          <span
            className="text-muted"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {subzone.stacks.length} stacks
          </span>
        </div>
        <div className="col-12 col-sm-3 text-center">Every</div>
        <div className="col-12 col-sm-3 text-center">Times per Day</div>
        <div className="col-12 col-sm-4 text-center">Duration</div>
      </div>
      <ScheduleLine
        className="pb-2"
        checkbox={true}
        schedule={allocSubzoneSchedule()}
        onChange={updateAllSchedules}
        onCheckChange={e => setAllMode(e)}
        label="All"
      />
      {schedules != null &&
        schedules.map((context, i) => (
          <div key={i} className={allMode ? 'd-none' : ''}>
            <ScheduleLine
              className={null}
              checkbox={false}
              schedule={context}
              onChange={updateSchedule}
              onCheckChange={noOp}
              label={null}
            />
          </div>
        ))}
    </SaveModal>
  );
};

/* React Hooks */

const useModifySubzonesForm: (input: Input) => Result = ({
  facilityId,
  zoneId,
  onCompleted,
}) => {
  const [
    modifySubzoneSchedulePromptIsOpen,
    setModifySubzoneSchedulePromptIsOpen,
  ] = useState(false);

  //Create state for required subzone parameter to open the form.
  const [subzone, setSubzone] = useState<Subzone>(newSubzone(-1));

  return {
    openModifyScheduleSubzonePrompt: useCallback(
      e => {
        setSubzone(e);
        setModifySubzoneSchedulePromptIsOpen(true);
      },
      [
        setModifySubzoneSchedulePromptIsOpen,
        modifySubzoneSchedulePromptIsOpen,
        setSubzone,
        subzone,
      ]
    ),
    ModifyScheduleSubzonePrompt: ModifySubzoneSchedulePrompt,
    modifyScheduleSubzonePromptProps: {
      subzone,
      facilityId,
      zoneId: zoneId,
      modifySubzoneSchedulePromptIsOpen: modifySubzoneSchedulePromptIsOpen,
      onCompleted,
      closeModifyScheduleSubzonePrompt: useCallback(
        () => setModifySubzoneSchedulePromptIsOpen(false),
        [modifySubzoneSchedulePromptIsOpen]
      ),
    },
  };
};

export default useModifySubzonesForm;
