import React from 'react';
import Pump from '../Pump';
import UnknownValueOverlay from '../../components/UnknownValueOverlay';
import TankSensorIndicator from '../TankSensorIndicator';
import NoWaterIcon from '../../assets/images/no-water-icon.svg';

import style from './style.module.scss';

const wavesHeightPercent = 20;
const minimumLevelPercent = 10;
const levelPercentDomain =
  (100 - wavesHeightPercent - minimumLevelPercent) / 100;

const getWaterLevelHeight = (waterLevel?: string) => {
  let waterLevelPercent;
  switch (waterLevel) {
    case 'EMPTY':
      waterLevelPercent = 0;
      break;
    case 'LOW':
      waterLevelPercent = 20;
      break;
    case 'NOMINAL':
      waterLevelPercent = 50;
      break;
    case 'FULL':
      waterLevelPercent = 80;
      break;
    case 'OVERFLOWING':
      waterLevelPercent = 100;
      break;
    default:
      waterLevelPercent = 0;
      break;
  }

  // Offset the space that the waves svg take up
  return `${waterLevelPercent * levelPercentDomain + minimumLevelPercent}%`;
};

const hasWaterLevelError = (waterLevel?: string) => {
  switch (waterLevel) {
    case 'LOW':
    case 'NOMINAL':
    case 'FULL':
      return false;
    default:
      return true;
  }
};

interface Props {
  waterLevel?: string;
  pumpState?: string;
  hidePump?: boolean;
  dryTankDetected?: boolean;
  gallons?: string;
  className?: string;
  unknownIconClassName?: string;
  volumeValueClassName?: string;
  volumeUnitClassName?: string;
  hasSensorStateAlert?: boolean;
  levelSensors?: boolean[];
  sensorsOnRight?: boolean;
  children?: any;
}

export default ({
  waterLevel,
  pumpState,
  hidePump,
  dryTankDetected,
  gallons,
  className,
  unknownIconClassName,
  volumeValueClassName,
  volumeUnitClassName,
  levelSensors,
  sensorsOnRight,
  children,
}: Props) => {
  const waterLevelError = hasWaterLevelError(waterLevel);
  const waterLevelHeight = getWaterLevelHeight(waterLevel);
  const waterLevelUnknown = waterLevel === null || waterLevel === undefined;
  const hasGallons = gallons !== null && gallons !== undefined;
  const hasLevelSensors = levelSensors !== null && levelSensors !== undefined;
  const sensorsOnTankRight = sensorsOnRight ?? true;

  const dryAlertIcon = (
    <img src={NoWaterIcon} alt="" className={style.dryAlertIcon} />
  );
  return (
    <UnknownValueOverlay
      hasUnknownValue={waterLevelUnknown}
      className={[
        style.container,
        waterLevelError ? style.error : null,
        pumpState === 'ON' ? style.pumpOn : style.pumpOff,
        className,
      ].join(' ')}
      iconClassName={`${style.unknownValueIcon} ${unknownIconClassName}`}
    >
      <div className={style.tankContainer}>
        <div className={style.tank}>
          <div className={style.tankInner}>
            {hasGallons && (
              <div
                className={[style.volumeValue, volumeValueClassName].join(' ')}
              >
                <div>{gallons && parseInt(gallons).toLocaleString()}</div>
              </div>
            )}

            {hasGallons && (
              <div
                className={[style.volumeUnit, volumeUnitClassName].join(' ')}
              >
                gal
              </div>
            )}

            {!hidePump && <Pump className={style.pump} pumpState={pumpState} />}

            {!dryTankDetected && <div className={style.tankWaves}></div>}

            {dryTankDetected && dryAlertIcon}

            <div
              className={[
                style.tankLevel,
                dryTankDetected ? style.dryTank : null,
              ].join(' ')}
              style={{ height: dryTankDetected ? '5%' : waterLevelHeight }}
            ></div>

            <div className={style.children}>{children}</div>
          </div>
        </div>
        {hasLevelSensors && (
          <div
            className={[
              style.levelSensorsContainer,
              sensorsOnTankRight ? style.onRight : style.onLeft,
            ].join(' ')}
          >
            {levelSensors?.map((active, index) => (
              <TankSensorIndicator
                waterDetected={active}
                onTankRight={sensorsOnTankRight}
                key={index}
              />
            ))}
          </div>
        )}
      </div>
    </UnknownValueOverlay>
  );
};
