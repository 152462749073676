import React from 'react';

import style from './style.module.scss';

interface Props {
  className?: string;
}

const CloggedStrainerIcon = ({ className }: Props) => (
  <svg
    className={[style.svg, className].join(' ')}
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    viewBox="0 0 100 100"
  >
    <path d="M73.8566,41.1409H26.1434a3.0631,3.0631,0,0,0-2.1907,5.2041L40.8068,63.5909V76.4727a1.0256,1.0256,0,0,0,.5863.9267l14.9448,7.0829a1.0255,1.0255,0,0,0,1.4647-.9267V65.0139L76.0474,46.345A3.0632,3.0632,0,0,0,73.8566,41.1409ZM53.231,53.42,56.94,57.1288a.5378.5378,0,0,1,0,.7605l-2.09,2.09a.5378.5378,0,0,1-.7605,0L50.38,56.2705a.5378.5378,0,0,0-.7605,0L45.91,59.98a.5378.5378,0,0,1-.7605,0l-2.0905-2.09a.5378.5378,0,0,1,0-.7605L46.7687,53.42a.5378.5378,0,0,0,0-.7605L43.0594,48.95a.5378.5378,0,0,1,0-.7605l2.0905-2.09a.5378.5378,0,0,1,.7605,0L49.62,49.8082a.5378.5378,0,0,0,.7605,0l3.7093-3.7093a.5378.5378,0,0,1,.7605,0l2.09,2.09a.5378.5378,0,0,1,0,.7605L53.231,52.6591A.5378.5378,0,0,0,53.231,53.42Zm-5.5649-19.69A4.7661,4.7661,0,1,1,42.9,28.9631,4.7661,4.7661,0,0,1,47.6661,33.7292Zm14.2,0A4.7661,4.7661,0,1,1,57.1,28.9631,4.7661,4.7661,0,0,1,61.8661,33.7292Zm-21.3-10.6785A4.7661,4.7661,0,1,1,35.8,18.2846,4.7661,4.7661,0,0,1,40.5661,23.0507Zm14.2,0A4.7661,4.7661,0,1,1,50,18.2846,4.7661,4.7661,0,0,1,54.7661,23.0507Zm14.2,0A4.7661,4.7661,0,1,1,64.2,18.2846,4.7662,4.7662,0,0,1,68.9661,23.0507Z" />
  </svg>
);

export default CloggedStrainerIcon;
