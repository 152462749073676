import React, { FC, useState, useEffect } from 'react';
import moment, { Moment } from 'moment';

import style from './style.module.scss';

interface Props {
  timestamp: any;
  currentServerDate: () => Moment;
}

const Timestamp: FC<Props> = ({
  timestamp,
  currentServerDate,
}: Props): JSX.Element => {
  const setNow = useState(new Date())[1];

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 60000);
    return () => clearInterval(interval);
  }, [setNow]);

  var time = moment(timestamp);
  return (
    <div className={style.timestamp}>
      <div>{time.format('MMMM Do YYYY @ h:mm:ss a')}</div>
      <div>{time.from(currentServerDate())}</div>
    </div>
  );
};

export { Timestamp };
