import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faPlus } from '@fortawesome/free-solid-svg-icons';

import { SaveModal, SaveModalProps } from '../SaveModal';

import style from './style.module.scss';
import { ModalProps } from 'reactstrap';
import { ApolloError } from 'apollo-client/errors/ApolloError';

export interface CrudModalProps extends ModalProps {
  title: any;
  isFormValid?: boolean;
  isDirty?: boolean;
  saving: boolean;
  onSave: () => void;
  onCreate: () => void;
  error: ApolloError | undefined;
  onComplete: (saved: boolean) => void;
  successContent?: any;
  saveButtonContent?: string;
  cancelButtonContent?: string;
  doneButtonContent?: string;
  hideSaveButton?: boolean;
  showCloseButton?: boolean;
}

export default ({
  className,
  title,
  isOpen,
  saving,
  onSave,
  error,
  onComplete,
  canCreate,
  onCreate,
  children,
  ...props
}: CrudModalProps) => {
  const onClickCreate = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    onCreate();
  };

  return (
    <SaveModal
      className={[style.modal, className].join(' ')}
      title={
        <div className={style.titleContainer}>
          <div>
            <FontAwesomeIcon
              className={style.icon}
              icon={faCogs}
              fixedWidth={true}
            />
          </div>
          {title}
        </div>
      }
      isOpen={isOpen}
      saving={saving}
      onSave={onSave}
      error={error}
      onComplete={onComplete}
      saveButtonContent="OK"
      cancelButtonContent="Cancel"
      {...props}
    >
      {children}
      <div className="row">
        <div className="col-12">&nbsp;</div>
      </div>
      {canCreate && (
        <button
          type="button"
          onClick={onClickCreate}
          style={{
            padding: '1em',
            width: '2.5em',
            height: '2.5em',
            position: 'absolute',
            borderRadius: '15em',
          }}
          className="btn btn-primary"
        >
          <FontAwesomeIcon
            style={{
              position: 'absolute',
              margin: '-0.5em 0 0 -0.6em',
            }}
            className={style.icon}
            icon={faPlus}
            fixedWidth={true}
          />
        </button>
      )}
    </SaveModal>
  );
};
