import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { Button, Label, Input, InputGroup, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faKey,
  faUserPlus,
  faSearch,
  faTrashAlt,
  faTrashRestoreAlt,
  faCaretUp,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';

import useUserDeletePrompt from '../../../../hooks/useUserDeletePrompt';
import useUserRestorePrompt from '../../../../hooks/useUserRestorePrompt';
import useUserInvalidatePasswordPrompt from '../../../../hooks/useUserInvalidatePasswordPrompt';
import useUserNewForm from '../../../../hooks/useUserNewForm';
import useUserEditForm from '../../../../hooks/useUserEditForm';

import {
  getInputValueAnd,
  useWaitForDelay,
} from '../../../../modules/form-helpers';
import Loading from '../../../../components/Loading';
import PageError from '../../../../components/PageError';

import style from './style.module.scss';

const USERS_QUERY = gql`
  query usersQuery($input: UserManagementUsersQueryInputType!, $after: String) {
    settings {
      userManagement {
        users(input: $input, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              userId
              firstName
              lastName
              email
              role {
                roleId
                name
              }
              deleted
            }
          }
        }
      }
    }
  }
`;

const SortColumnNames = {
  LAST_NAME: 'LAST_NAME',
  FIRST_NAME: 'FIRST_NAME',
  EMAIL: 'EMAIL',
  ROLE: 'ROLE',
  DELETED: 'DELETED',
};

const SEARCH_DELAY_MS = 500;

const getSortOrder = ({ name, desc }) =>
  `${name}_${desc ? 'DESCENDING' : 'ASCENDING'}`;

const SortableColumnHeader = ({
  className,
  columnName,
  currentSortColumn,
  onSort,
  children,
}) => {
  return (
    <th
      className={className}
      style={{ cursor: 'pointer' }}
      onClick={() => onSort(columnName)}
    >
      {children}
      {currentSortColumn.name === columnName && (
        <span className={style.sortIconContainer}>
          {currentSortColumn.desc ? (
            <FontAwesomeIcon icon={faCaretDown} />
          ) : (
            <FontAwesomeIcon icon={faCaretUp} />
          )}
        </span>
      )}
    </th>
  );
};

const DeleteUserButton = ({
  userFirstName,
  userLastName,
  userId,
  userDeleted: _,
  onCompleted,
  ...props
}) => {
  const {
    openUserDeletePrompt,
    UserDeletePrompt,
    userDeletePromptProps,
  } = useUserDeletePrompt({ userFirstName, userLastName, userId, onCompleted });

  return (
    <>
      <UserDeletePrompt {...userDeletePromptProps} />

      <Button onClick={openUserDeletePrompt} {...props}>
        <FontAwesomeIcon icon={faTrashAlt} fixedWidth={true} />
      </Button>
    </>
  );
};

const UserRestoreButton = ({
  userFirstName,
  userLastName,
  userId,
  userDeleted: _,
  onCompleted,
  ...props
}) => {
  const {
    openUserRestorePrompt,
    UserRestorePrompt,
    userRestorePromptProps,
  } = useUserRestorePrompt({
    userFirstName,
    userLastName,
    userId,
    onCompleted,
  });

  return (
    <>
      <UserRestorePrompt {...userRestorePromptProps} />

      <Button onClick={openUserRestorePrompt} {...props}>
        <FontAwesomeIcon icon={faTrashRestoreAlt} fixedWidth={true} />
      </Button>
    </>
  );
};

const UserInvalidatePasswordButton = ({
  userFirstName,
  userLastName,
  userId,
  userDeleted: _,
  onCompleted,
  ...props
}) => {
  const {
    openUserInvalidatePasswordPrompt,
    UserInvalidatePasswordPrompt,
    userInvalidatePasswordPromptProps,
  } = useUserInvalidatePasswordPrompt({
    userFirstName,
    userLastName,
    userId,
    onCompleted,
  });

  return (
    <>
      <UserInvalidatePasswordPrompt {...userInvalidatePasswordPromptProps} />

      <Button onClick={openUserInvalidatePasswordPrompt} {...props}>
        <FontAwesomeIcon icon={faKey} fixedWidth={true} />
      </Button>
    </>
  );
};

const UserEditFormElement = ({
  tag: Tag,
  userFirstName,
  userLastName,
  userId,
  userDeleted,
  onCompleted,
  children,
  ...props
}) => {
  const { openUserEditForm, UserEditForm, userEditFormProps } = useUserEditForm(
    {
      userId,
      onCompleted,
    }
  );

  const {
    openUserRestorePrompt,
    UserRestorePrompt,
    userRestorePromptProps,
  } = useUserRestorePrompt({
    userFirstName,
    userLastName,
    userId,
    onCompleted,
  });

  return (
    <>
      <UserEditForm {...userEditFormProps} />

      <UserRestorePrompt {...userRestorePromptProps} />

      <Tag
        onClick={userDeleted ? openUserRestorePrompt : openUserEditForm}
        {...props}
      >
        {children}
      </Tag>
    </>
  );
};

export default () => {
  const waitForSearchDelay = useWaitForDelay(SEARCH_DELAY_MS);
  const [users, setUsers] = useState(null);
  const [search, setSearch] = useState('');

  const [sortColumn, setSortColumn] = useState({
    name: SortColumnNames.LAST_NAME,
    desc: false,
  });

  const [loadingMore, setLoadingMore] = useState(false);
  const [queryInput, setQueryInput] = useState({
    sortOrder: getSortOrder(sortColumn),
  });

  const { loading, error, data, refetch, fetchMore } = useQuery(USERS_QUERY, {
    variables: { input: queryInput },
    onCompleted: () => setUsers(data?.settings?.userManagement?.users),
  });

  const { openUserNewForm, UserNewForm, userNewFormProps } = useUserNewForm({
    onCompleted: () => refetch({ variables: { input: queryInput } }),
  });

  const refetchWithSearch = value => {
    const newQueryInput = {
      ...queryInput,
      search: value,
    };

    setQueryInput(newQueryInput);
    refetch({ variables: { input: newQueryInput } });
  };

  const refetchWithToggleIncludeDeleted = () => {
    const newQueryInput = {
      ...queryInput,
      includeDeleted: !queryInput.includeDeleted,
    };

    setQueryInput(newQueryInput);
    refetch({ variables: { input: newQueryInput } });
  };

  const updateSearch = value => {
    setSearch(value);

    if (value.trim() !== (queryInput.search || '').trim()) {
      waitForSearchDelay(refetchWithSearch)(value);
    }
  };

  const isRefreshing = loading && !loadingMore;
  const hasError = !loading && error;
  const hasNextPage =
    !hasError && !isRefreshing && !!users?.pageInfo.hasNextPage;
  const isEmpty = !hasError && users?.edges.length === 0;

  const toggleSortColumn = sortColumnName => {
    const newSortColumn =
      sortColumn.name === sortColumnName
        ? {
            ...sortColumn,
            desc: !sortColumn.desc,
          }
        : {
            name: sortColumnName,
            desc: false,
          };

    const newQueryInput = {
      ...queryInput,
      sortOrder: getSortOrder(newSortColumn),
    };

    setSortColumn(newSortColumn);
    setQueryInput(newQueryInput);
    refetch({ variables: { input: newQueryInput } });
  };

  return (
    <div
      className={[style.container, isRefreshing ? style.refreshing : null].join(
        ' '
      )}
    >
      <UserNewForm {...userNewFormProps} />

      {isRefreshing && (
        <div className={style.loadingContainer}>
          <Loading />
        </div>
      )}

      <div className={style.usersTableContainer}>
        <Button color="success" onClick={openUserNewForm}>
          <FontAwesomeIcon icon={faUserPlus} fixedWidth /> New User
        </Button>

        <InputGroup className={style.searchInput}>
          <Input
            type="search"
            value={search}
            onChange={getInputValueAnd(updateSearch)}
            placeholder="Search"
          />
        </InputGroup>

        <div className={style.filterContainer}>
          <Input
            id="includeDeleted"
            addon
            type="checkbox"
            checked={queryInput.includeDeleted}
            onChange={refetchWithToggleIncludeDeleted}
          />

          <Label for="includeDeleted">Include Deleted</Label>
        </div>

        {!isEmpty && (
          <Table responsive dark>
            <thead>
              <tr>
                <th className={style.editColumn}></th>

                <SortableColumnHeader
                  columnName={SortColumnNames.FIRST_NAME}
                  currentSortColumn={sortColumn}
                  onSort={toggleSortColumn}
                >
                  First Name
                </SortableColumnHeader>

                <SortableColumnHeader
                  columnName={SortColumnNames.LAST_NAME}
                  currentSortColumn={sortColumn}
                  onSort={toggleSortColumn}
                >
                  Last Name
                </SortableColumnHeader>

                <SortableColumnHeader
                  columnName={SortColumnNames.EMAIL}
                  currentSortColumn={sortColumn}
                  onSort={toggleSortColumn}
                >
                  Email / Username
                </SortableColumnHeader>

                <SortableColumnHeader
                  columnName={SortColumnNames.ROLE}
                  currentSortColumn={sortColumn}
                  onSort={toggleSortColumn}
                >
                  Role
                </SortableColumnHeader>

                {queryInput.includeDeleted && (
                  <SortableColumnHeader
                    className={style.activeColumn}
                    columnName={SortColumnNames.DELETED}
                    currentSortColumn={sortColumn}
                    onSort={toggleSortColumn}
                  >
                    Active
                  </SortableColumnHeader>
                )}
              </tr>
            </thead>

            {!hasError && users && (
              <tbody>
                {users.edges.map(({ node: user }) => {
                  const userModalProps = {
                    userFirstName: user.firstName,
                    userLastName: user.lastName,
                    userId: user.userId,
                    userDeleted: user.deleted,
                    onCompleted: () =>
                      refetch({ variables: { input: queryInput } }),
                  };

                  return (
                    <tr key={user.userId}>
                      <td className={style.editColumn}>
                        {user.deleted ? (
                          <UserRestoreButton color="link" {...userModalProps} />
                        ) : (
                          <>
                            <DeleteUserButton
                              color="link"
                              {...userModalProps}
                            />

                            <UserInvalidatePasswordButton
                              color="link"
                              {...{ ...userModalProps, onCompleted: null }}
                            />
                          </>
                        )}
                      </td>

                      <UserEditFormElement tag="td" {...userModalProps}>
                        {user.firstName}
                      </UserEditFormElement>

                      <UserEditFormElement tag="td" {...userModalProps}>
                        {user.lastName}
                      </UserEditFormElement>

                      <UserEditFormElement tag="td" {...userModalProps}>
                        {user.email}
                      </UserEditFormElement>

                      <UserEditFormElement tag="td" {...userModalProps}>
                        {user.role?.name}
                      </UserEditFormElement>

                      {queryInput.includeDeleted && (
                        <UserEditFormElement
                          tag="td"
                          className={style.activeColumn}
                          {...userModalProps}
                        >
                          {!user.deleted && (
                            <FontAwesomeIcon icon={faCheck} fixedWidth={true} />
                          )}
                        </UserEditFormElement>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </Table>
        )}

        {hasError && <PageError error={error} showIcon={false} />}

        {isEmpty && !isRefreshing && (
          <div className={style.noResultsContainer}>
            <FontAwesomeIcon icon={faSearch} fixedWidth={true} /> No Results
          </div>
        )}

        {hasNextPage && (
          <div className={style.moreButtonContainer}>
            <Button
              className={style.moreButton}
              disabled={loadingMore}
              onClick={() => {
                setLoadingMore(true);
                fetchMore({
                  variables: {
                    after: users.pageInfo.endCursor,
                    input: queryInput,
                  },
                  updateQuery: (previousResult, { fetchMoreResult }) => {
                    setLoadingMore(false);
                    const newEdges =
                      fetchMoreResult.settings.userManagement.users.edges;
                    const pageInfo =
                      fetchMoreResult.settings.userManagement.users.pageInfo;

                    const data = newEdges.length
                      ? {
                          settings: {
                            __typename: previousResult.settings.__typename,
                            userManagement: {
                              __typename:
                                previousResult.settings.userManagement
                                  .__typename,
                              users: {
                                __typename:
                                  previousResult.settings.userManagement.users
                                    .__typename,
                                edges: [
                                  ...previousResult.settings.userManagement
                                    .users.edges,
                                  ...newEdges,
                                ],
                                pageInfo,
                              },
                            },
                          },
                        }
                      : previousResult;

                    setUsers(data?.settings?.userManagement?.users);
                    return data;
                  },
                });
              }}
            >
              {loadingMore ? 'Loading more...' : 'More'}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
