import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery, useSubscription } from '@apollo/react-hooks';

import StackStatus from '../../components/StackStatus';
import Levels from './components/Levels';
import LightSchedule from './components/LightSchedule';
import LightRecipe from './components/LightRecipe';
import PageLayout from '../../components/PageLayout';
import FacilityHeading from '../../components/PageLayout/FacilityHeading';
import PageLoadingContainer from '../../components/PageLoadingContainer';
import PageNotFound from '../../components/PageNotFound';
import ModalCloseButton from '../../components/ModalCloseButton';
import history from '../../modules/history';

import defaultFacilityId from '../../modules/defaultFacilityId';
import style from './style.module.scss';

const stackFragment = gql`
  fragment StackPageStack on StackType {
    stackId
    isMicrogreen
    name
    ...StackStatus
    ...LightSchedule
    ...LevelsStack
    growWise {
      ...LightRecipe
    }
  }
  ${StackStatus.fragment}
  ${LightSchedule.fragment}
  ${LightRecipe.fragment}
  ${Levels.stackFragment}
`;

const zoneFragment = gql`
  fragment StackPageZone on ZoneType {
    zoneId
    isMicrogreen
    isDistributionTankHidden
    name
    tank {
      gallons
      waterLevel
      pumpState
    }
    ...LevelsZone
  }
  ${Levels.zoneFragment}
`;

const QUERY = gql`
  query facility($facilityId: ID!, $zoneName: String!, $stackName: String!) {
    facilities {
      ...FacilityHeading
    }

    facility(facilityId: $facilityId) {
      facilityId
      ...FacilityHeading
      zone(zoneName: $zoneName) {
        name
        ...StackPageZone
        stack(stackName: $stackName) {
          ...StackPageStack
        }
      }
    }
  }
  ${FacilityHeading.fragment}
  ${zoneFragment}
  ${stackFragment}
`;

const ZONE_SUBSCRIPTION = gql`
  subscription zoneUpdated($zoneId: ID!, $stackName: String!) {
    zoneUpdated(zoneId: $zoneId) {
      zone {
        ...StackPageZone
        stack(stackName: $stackName) {
          ...StackPageStack
        }
      }
    }
  }
  ${zoneFragment}
  ${stackFragment}
`;

const PageContent = ({ facilityId, zone }) => {
  useSubscription(ZONE_SUBSCRIPTION, {
    variables: { zoneId: zone.zoneId, stackName: zone.stack.name },
  });

  const { stack } = zone;
  const { growWise } = stack;

  return (
    <div className={style.container}>
      <ModalCloseButton
        className={style.closeButton}
        onClick={() =>
          history.push(`/facility/${facilityId}/zone/${zone.name}`)
        }
      />

      <div className={style.lightScheduleContainer}>
        <LightSchedule stack={stack} />

        {growWise && (
          <LightRecipe
            className={style.lightRecipe}
            zoneName={zone.name}
            stackName={stack.name}
            stackId={stack.stackId}
            growWise={growWise}
          />
        )}
      </div>

      <div className={style.levelStatusContainer}>
        <div className={style.statusContainer}>
          <StackStatus
            className={style.stackStatus}
            tankClassName={style.stackTank}
            hideCollectorTank={false}
            zoneName={zone.name}
            stack={stack}
          />
        </div>

        <div className={style.levelsContainer}>
          <Levels
            zone={zone}
            stack={stack}
            collectionTankHidden={
              zone.isDistributionTankHidden || zone.isMicrogreen
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ({ facilityId, zoneName, stackName }) => {
  const result = useQuery(QUERY, {
    variables: { facilityId, zoneName, stackName },
  });

  const { data } = result;
  const { facility, facilities } = data || {};
  const { zone } = facility || {};

  useEffect(() => {
    if (facility) {
      defaultFacilityId.set(facility.facilityId);
    }
  }, [facility]);

  return (
    <PageLayout
      heading={
        <FacilityHeading facility={facility} facilities={facilities || []} />
      }
      subHeading={
        zone &&
        zone.stack && (
          <span>
            <Link to={`/facility/${facilityId}/zone/${zone.name}`}>
              Zone {zone.name}
            </Link>
            <span> / Stack {zone.stack.name}</span>
          </span>
        )
      }
    >
      <PageLoadingContainer
        resourceTypeName="Facility"
        result={result}
        resourceExists={!!facility}
        render={() =>
          !zone ? (
            <PageNotFound resourceTypeName={`Zone ${zoneName}`} />
          ) : !zone.stack ? (
            <PageNotFound resourceTypeName={`Stack ${stackName}`} />
          ) : (
            <PageContent facilityId={facilityId} zone={zone} />
          )
        }
      />
    </PageLayout>
  );
};
