import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  zoneName: string;
  stackName: string;
  stackId: string;
  onCompleted?: () => void;
}

interface Result {
  openStackValveFailureResetPrompt: () => void;
  StackValveFailureResetPrompt: typeof StackValveFailureResetPrompt;
  stackValveFailureResetPromptProps: Props;
}

export const RESET_STACK_VALVE_FAILURE = gql`
  mutation resetStackValveFailure($input: ResetStackValveFailureInputType!) {
    resetStackValveFailure(input: $input) {
      stack {
        stackId
        valveFailureDetected
      }
    }
  }
`;

interface Props extends Input {
  stackValveFailureResetPromptIsOpen: boolean;
  closeStackValveFailureResetPrompt: () => void;
}

const StackValveFailureResetPrompt = ({
  zoneName,
  stackName,
  stackId,
  stackValveFailureResetPromptIsOpen,
  closeStackValveFailureResetPrompt,
  onCompleted,
}: Props) => {
  const [stackValveFailureReset, { error, loading: saving }] = useMutation(
    RESET_STACK_VALVE_FAILURE,
    {
      onCompleted: () => {
        closeStackValveFailureResetPrompt();
        onCompleted && onCompleted();
      },
    }
  );

  return (
    <PromptModal
      title={`Reset Valve Failure Alert for Zone ${zoneName} / Stack ${stackName}`}
      danger={true}
      isOpen={stackValveFailureResetPromptIsOpen}
      saving={saving}
      error={error}
      onSave={() => {
        stackValveFailureReset({
          variables: {
            input: {
              stackId,
            },
          },
        });
      }}
      onComplete={closeStackValveFailureResetPrompt}
    >
      <p>
        A valve failure was detected at Zone{' '}
        {`${zoneName} / Stack ${stackName}`}.
      </p>

      <p>
        You should only reset the alert if you have physically verified the
        valves are functioning correctly.
      </p>

      <p>{`Are you sure you want to reset the valve failure alert for Zone ${zoneName} / Stack ${stackName}?`}</p>
    </PromptModal>
  );
};

const useStackValveFailureResetPrompt: (input: Input) => Result = ({
  zoneName,
  stackName,
  stackId,
  onCompleted,
}) => {
  const [
    stackValveFailureResetPromptIsOpen,
    setStackValveFailureResetPromptIsOpen,
  ] = useState(false);

  return {
    openStackValveFailureResetPrompt: useCallback(
      () => setStackValveFailureResetPromptIsOpen(true),
      [setStackValveFailureResetPromptIsOpen]
    ),
    StackValveFailureResetPrompt,
    stackValveFailureResetPromptProps: {
      zoneName,
      stackName,
      stackId,
      stackValveFailureResetPromptIsOpen,
      onCompleted,
      closeStackValveFailureResetPrompt: useCallback(
        () => setStackValveFailureResetPromptIsOpen(false),
        [setStackValveFailureResetPromptIsOpen]
      ),
    },
  };
};

export default useStackValveFailureResetPrompt;
