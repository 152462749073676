import React from 'react';
import { Link } from 'react-router-dom';
import HyCubeOs from '../../../HyCubeOs';
import MenuButton from '../../../MenuButton';

import style from './style.module.scss';

export default ({ className, heading, subHeading, onMenuButtonClick }) => {
  return (
    <div className={className}>
      <div className={style.container}>
        <div className={style.nav}>
          <div className={style.navLeft}>
            <Link className={style.brand} to={{ pathname: '/' }}>
              <HyCubeOs className={style.brandImage} iconizeForPhone={true} />
            </Link>
          </div>

          <div className={style.navCenter}>
            <h1 className={style.heading}>{heading}</h1>
            <div>{subHeading}</div>
          </div>

          <div className={style.navRight}>
            <MenuButton onClick={onMenuButtonClick} />
          </div>
        </div>
      </div>
    </div>
  );
};
