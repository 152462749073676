import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  userFirstName: string;
  userLastName: string;
  userId: string;
  onCompleted?: () => void;
}

interface Result {
  openUserInvalidatePasswordPrompt: () => void;
  UserInvalidatePasswordPrompt: typeof UserInvalidatePasswordPrompt;
  userInvalidatePasswordPromptProps: Props;
}

const INVALIDATE_USER_PASSWORD = gql`
  mutation invalidateUserPassword($input: InvalidateUserPasswordInputType!) {
    invalidateUserPassword(input: $input) {
      user {
        userId
      }
    }
  }
`;

interface Props extends Input {
  userInvalidatePasswordPromptIsOpen: boolean;
  closeUserInvalidatePasswordPrompt: () => void;
}

const UserInvalidatePasswordPrompt = ({
  userFirstName,
  userLastName,
  userId,
  userInvalidatePasswordPromptIsOpen,
  closeUserInvalidatePasswordPrompt,
  onCompleted,
}: Props) => {
  const [userInvalidatePassword, { error, loading: saving }] = useMutation(
    INVALIDATE_USER_PASSWORD,
    {
      onCompleted: () => {
        closeUserInvalidatePasswordPrompt();
        onCompleted && onCompleted();
      },
    }
  );

  return (
    <PromptModal
      title={`Invalidate Password for ${userFirstName} ${userLastName}?`}
      isOpen={userInvalidatePasswordPromptIsOpen}
      saving={saving}
      error={error}
      onSave={() => {
        userInvalidatePassword({
          variables: {
            input: { userId },
          },
        });
      }}
      onComplete={closeUserInvalidatePasswordPrompt}
    >
      <p>
        {`Invalidating the password for ${userFirstName} ${userLastName} will prevent them from logging in until they have reset their password.`}
      </p>

      <p>
        They will be sent an email with instructions on how to reset their
        password.
      </p>

      <p>{`Invalidate password for ${userFirstName} ${userLastName}?`}</p>
    </PromptModal>
  );
};

const useUserInvalidatePasswordPrompt: (input: Input) => Result = ({
  userFirstName,
  userLastName,
  userId,
  onCompleted,
}) => {
  const [
    userInvalidatePasswordPromptIsOpen,
    setUserInvalidatePasswordPromptIsOpen,
  ] = useState(false);

  return {
    openUserInvalidatePasswordPrompt: useCallback(
      () => setUserInvalidatePasswordPromptIsOpen(true),
      [setUserInvalidatePasswordPromptIsOpen]
    ),
    UserInvalidatePasswordPrompt,
    userInvalidatePasswordPromptProps: {
      userFirstName,
      userLastName,
      userId,
      userInvalidatePasswordPromptIsOpen,
      onCompleted,
      closeUserInvalidatePasswordPrompt: useCallback(
        () => setUserInvalidatePasswordPromptIsOpen(false),
        [setUserInvalidatePasswordPromptIsOpen]
      ),
    },
  };
};

export default useUserInvalidatePasswordPrompt;
