import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faLightbulb } from '@fortawesome/free-solid-svg-icons';

import style from './style.module.scss';
import TriStateSwitch from '../TriStateSwitch';

interface Props extends React.HTMLAttributes<HTMLElement> {
  scheduleIsEnabled: boolean;
  lightIsOn: boolean;
  onLightScheduleStateChange?: (lightState: LightState) => void;
  timeBlocks: Array<boolean>;
  activeTimeBlockIndex?: number;
  onToggleTimeBlock?: (timeBlockIndex: number) => void;
  allowTimeBlockCollapse?: boolean;
  disabled?: boolean;
}

export type LightState = 'USE_SCHEDULE' | 'ON' | 'OFF';

export default ({
  className,
  scheduleIsEnabled,
  lightIsOn,
  onLightScheduleStateChange,
  timeBlocks,
  activeTimeBlockIndex,
  onToggleTimeBlock,
  allowTimeBlockCollapse = false,
  disabled = false,
}: Props) => {
  const photoPeriodRaw = timeBlocks.reduce(
    (acc: number, cur: boolean) => (cur ? acc + 30 : acc),
    0
  );
  const photoPeriodHours = Math.floor(photoPeriodRaw / 60);
  const photoPeriodMinutes = photoPeriodRaw % 60;

  const switchState = scheduleIsEnabled ? 1 : lightIsOn ? 2 : 3;

  const handleLightScheduleStateChange = (newState: number) => {
    const lightState =
      newState === 1 ? 'USE_SCHEDULE' : newState === 2 ? 'ON' : 'OFF';
    onLightScheduleStateChange && onLightScheduleStateChange(lightState);
  };

  return (
    <div
      className={[
        style.container,
        allowTimeBlockCollapse
          ? style.collapsibleTimeBlocks
          : style.nonCollapsibleTimeBlocks,
        className,
      ].join(' ')}
    >
      <input
        className={style.showLightScheduleButton}
        type="checkbox"
        id="show-light-schedule-btn"
      />
      <div className={style.title}>
        {scheduleIsEnabled && photoPeriodRaw > 0 && (
          <div className={style.photoperiodPopover}>
            {photoPeriodHours > 0 &&
              `${photoPeriodHours} ${photoPeriodHours > 1 ? 'hours' : 'hour'}`}
            {photoPeriodHours > 0 && photoPeriodMinutes > 0 && ', '}
            {photoPeriodMinutes > 0 && `${photoPeriodMinutes} minutes`}
          </div>
        )}

        <label htmlFor="schedule-enabled-switch" className={style.switchLabel}>
          <FontAwesomeIcon
            icon={faLightbulb}
            className={
              lightIsOn || scheduleIsEnabled
                ? style.lightOnIcon
                : style.lightOffIcon
            }
            fixedWidth
          />
        </label>

        <TriStateSwitch
          id="light-schedule-switch"
          className={style.switch}
          state={switchState}
          onChange={state => handleLightScheduleStateChange(state)}
          width={120}
          handleDiameter={40}
          height={30}
          disabled={disabled}
        />
        <label
          className={style.showLightScheduleIcon}
          htmlFor="show-light-schedule-btn"
        >
          <FontAwesomeIcon icon={faCaretDown} size={'lg'} />
        </label>
      </div>
      <div
        className={`${style.timeBlocks} ${
          scheduleIsEnabled ? '' : style.disabled
        }`}
      >
        {timeBlocks.map((timeBlockIsOn, timeBlockIndex) => {
          const hoursPerTimeBlock = 24 / timeBlocks.length;
          const timeBlocksPerHour = timeBlocks.length / 24;
          const hour = Math.floor(timeBlockIndex * hoursPerTimeBlock);
          const minutes =
            (timeBlockIndex % timeBlocksPerHour) * (60 / timeBlocksPerHour);
          return (
            <div
              key={timeBlockIndex}
              className={[
                style.timeBlock,
                timeBlockIsOn ? style.timeBlockOn : style.timeBlockOff,
              ].join(' ')}
              style={onToggleTimeBlock && { cursor: 'pointer' }}
              onClick={() =>
                onToggleTimeBlock && onToggleTimeBlock(timeBlockIndex)
              }
            >
              {timeBlockIndex === activeTimeBlockIndex && (
                <div className={style.activeTimeBlockIndicator}></div>
              )}
              <div
                className={[
                  style.time,
                  minutes ? style.inTheHour : style.onTheHour,
                ].join(' ')}
              >
                <div className={style.hour}>
                  {hour.toString().padStart(2, '0')}
                </div>
                <div className={style.minute}>
                  {`:${minutes.toString().padStart(2, '0')}`}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
