import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  userFirstName: string;
  userLastName: string;
  userId: string;
  onCompleted?: () => void;
}

interface Result {
  openUserDeletePrompt: () => void;
  UserDeletePrompt: typeof UserDeletePrompt;
  userDeletePromptProps: Props;
}

const DELETE_USER = gql`
  mutation deleteUser($input: DeleteUserInputType!) {
    deleteUser(input: $input) {
      user {
        userId
        deleted
      }
    }
  }
`;

interface Props extends Input {
  userDeletePromptIsOpen: boolean;
  closeUserDeletePrompt: () => void;
}

const UserDeletePrompt = ({
  userFirstName,
  userLastName,
  userId,
  userDeletePromptIsOpen,
  closeUserDeletePrompt,
  onCompleted,
}: Props) => {
  const [userDelete, { error, loading: saving }] = useMutation(DELETE_USER, {
    onCompleted: () => {
      closeUserDeletePrompt();
      onCompleted && onCompleted();
    },
  });

  return (
    <PromptModal
      title={`Delete ${userFirstName} ${userLastName}?`}
      isOpen={userDeletePromptIsOpen}
      saving={saving}
      error={error}
      onSave={() => {
        userDelete({
          variables: {
            input: { userId },
          },
        });
      }}
      onComplete={closeUserDeletePrompt}
      danger
    >
      <p>
        {`Deleting ${userFirstName} ${userLastName} will prevent them from logging in. `}
      </p>

      <p>
        They will continue to appear in the activity log for any actions they
        took, and you may restore them in the future.
      </p>

      <p>{`Delete ${userFirstName} ${userLastName}?`}</p>
    </PromptModal>
  );
};

const useUserDeletePrompt: (input: Input) => Result = ({
  userFirstName,
  userLastName,
  userId,
  onCompleted,
}) => {
  const [userDeletePromptIsOpen, setUserDeletePromptIsOpen] = useState(false);

  return {
    openUserDeletePrompt: useCallback(() => setUserDeletePromptIsOpen(true), [
      setUserDeletePromptIsOpen,
    ]),
    UserDeletePrompt,
    userDeletePromptProps: {
      userFirstName,
      userLastName,
      userId,
      userDeletePromptIsOpen,
      onCompleted,
      closeUserDeletePrompt: useCallback(
        () => setUserDeletePromptIsOpen(false),
        [setUserDeletePromptIsOpen]
      ),
    },
  };
};

export default useUserDeletePrompt;
