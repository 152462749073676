import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  zoneName: string;
  stackName: string;
  stackId: string;
  onCompleted?: () => void;
}

interface Result {
  openCancelHarvestPrompt: () => void;
  CancelStackHarvestSchedulePrompt: typeof CancelStackHarvestSchedulePrompt;
  cancelStackHarvestSchedulePromptProps: Props;
}

export const UPDATE_STACK_HARVEST_SCHEDULE = gql`
  mutation updateStackHarvestScheduleTime(
    $input: UpdateStackHarvestScheduleTimeInputType!
  ) {
    updateStackHarvestScheduleTime(input: $input) {
      stack {
        stackId
        mode
        harvestSchedule {
          harvestBeginLocalTime
        }
      }
    }
  }
`;

interface Props extends Input {
  cancelHarvestPromptIsOpen: boolean;
  closeCancelHarvestPrompt: () => void;
}

const CancelStackHarvestSchedulePrompt = ({
  zoneName,
  stackName,
  stackId,
  cancelHarvestPromptIsOpen,
  closeCancelHarvestPrompt,
  onCompleted,
}: Props) => {
  const [updateStackHarvestSchedule, { error, loading: saving }] = useMutation(
    UPDATE_STACK_HARVEST_SCHEDULE,
    {
      onCompleted: () => {
        closeCancelHarvestPrompt();
        onCompleted && onCompleted();
      },
    }
  );

  return (
    <PromptModal
      title={`Cancel Scheduled Harvest for Zone ${zoneName} / Stack ${stackName}`}
      isOpen={cancelHarvestPromptIsOpen}
      saving={saving}
      error={error}
      onSave={() => {
        updateStackHarvestSchedule({
          variables: {
            input: {
              stackId: stackId,
              harvestStartTime: null,
            },
          },
        });
      }}
      onComplete={closeCancelHarvestPrompt}
    >
      <p>
        Cancelling the scheduled harvest will cause the stack to continue
        irrigating according to its current mode.
      </p>

      <p>{`Cancel the scheduled harvest for Zone ${zoneName} / Stack ${stackName}?`}</p>
    </PromptModal>
  );
};

const useCancelStackHarvestSchedulePrompt: (input: Input) => Result = ({
  zoneName,
  stackName,
  stackId,
  onCompleted,
}) => {
  const [cancelHarvestPromptIsOpen, setCancelHarvestPromptIsOpen] = useState(
    false
  );

  return {
    openCancelHarvestPrompt: useCallback(
      () => setCancelHarvestPromptIsOpen(true),
      [setCancelHarvestPromptIsOpen]
    ),
    CancelStackHarvestSchedulePrompt,
    cancelStackHarvestSchedulePromptProps: {
      zoneName,
      stackName,
      stackId,
      cancelHarvestPromptIsOpen,
      onCompleted,
      closeCancelHarvestPrompt: useCallback(
        () => setCancelHarvestPromptIsOpen(false),
        [setCancelHarvestPromptIsOpen]
      ),
    },
  };
};

export default useCancelStackHarvestSchedulePrompt;
