import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  zoneName: string;
  zoneId: string;
  onCompleted?: () => void;
}

interface Result {
  openZoneAttachedCollectorTankFlushPrompt: () => void;
  ZoneAttachedCollectorTankFlushPrompt: typeof ZoneAttachedCollectorTankFlushPrompt;
  zoneAttachedCollectorTankFlushPromptProps: Props;
}

const FLUSH_ZONE_ATTACHED_COLLECTOR_TANK = gql`
  mutation flushZoneAttachedCollectorTank(
    $input: FlushZoneAttachedCollectorTankInputType!
  ) {
    flushZoneAttachedCollectorTank(input: $input) {
      zone {
        zoneId
        collectorTank {
          pumpState
        }
      }
    }
  }
`;

interface Props extends Input {
  zoneAttachedCollectorTankFlushPromptIsOpen: boolean;
  closeZoneAttachedCollectorTankFlushPrompt: () => void;
}

const ZoneAttachedCollectorTankFlushPrompt = ({
  zoneName,
  zoneId,
  zoneAttachedCollectorTankFlushPromptIsOpen,
  closeZoneAttachedCollectorTankFlushPrompt,
  onCompleted,
}: Props) => {
  const [
    flushZoneAttachedCollectorTank,
    { error, loading: saving },
  ] = useMutation(FLUSH_ZONE_ATTACHED_COLLECTOR_TANK, {
    onCompleted: () => {
      closeZoneAttachedCollectorTankFlushPrompt();
      onCompleted && onCompleted();
    },
  });

  return (
    <PromptModal
      title={`Flush Return Tank for Zone ${zoneName}`}
      isOpen={zoneAttachedCollectorTankFlushPromptIsOpen}
      saving={saving}
      error={error}
      onSave={() => {
        flushZoneAttachedCollectorTank({
          variables: {
            input: {
              zoneId,
            },
          },
        });
      }}
      onComplete={closeZoneAttachedCollectorTankFlushPrompt}
    >
      {`Are you sure you want to flush the return tank for Zone ${zoneName}?`}
    </PromptModal>
  );
};

const useZoneAttachedCollectorTankFlushPrompt: (input: Input) => Result = ({
  zoneName,
  zoneId,
  onCompleted,
}) => {
  const [
    zoneAttachedCollectorTankFlushPromptIsOpen,
    setZoneAttachedCollectorTankFlushPromptIsOpen,
  ] = useState(false);

  return {
    openZoneAttachedCollectorTankFlushPrompt: useCallback(
      () => setZoneAttachedCollectorTankFlushPromptIsOpen(true),
      [setZoneAttachedCollectorTankFlushPromptIsOpen]
    ),
    ZoneAttachedCollectorTankFlushPrompt,
    zoneAttachedCollectorTankFlushPromptProps: {
      zoneName,
      zoneId,
      zoneAttachedCollectorTankFlushPromptIsOpen,
      onCompleted,
      closeZoneAttachedCollectorTankFlushPrompt: useCallback(
        () => setZoneAttachedCollectorTankFlushPromptIsOpen(false),
        [setZoneAttachedCollectorTankFlushPromptIsOpen]
      ),
    },
  };
};

export default useZoneAttachedCollectorTankFlushPrompt;
