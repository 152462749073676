import React from 'react';

import style from './style.module.scss';

export default ({ className = undefined }) => {
  return (
    <svg
      className={[style.svg, className].join(' ')}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <g>
        <g>
          <path d="M89.6,35.1L52.6,3.4c-1.5-1.3-3.6-1.3-5.1,0L10.4,35.1C9.5,35.9,9,37,9,38.1v55.5c0,2.2,1.8,3.9,3.9,3.9h74.2    c2.2,0,3.9-1.8,3.9-3.9V38.1C91,37,90.5,35.9,89.6,35.1z M56.9,72.1c-4.4,3-10.9,2.3-16-0.5c-1.9,2.5-3.6,5.5-5,8.9    c-0.3,0.8-1.1,1.2-1.8,1.2c-0.2,0-0.5,0-0.7-0.1c-1-0.4-1.5-1.6-1.1-2.6c6.1-14.9,17.9-21.8,20.1-23.1c-0.1,0-0.2,0-0.3,0.1    c-0.5,0.1-8.9,2.9-16.9,10.9c-1.6-2.1-2.6-4.6-2.3-7.2C34,48,50.8,48.3,61.7,37.3c1.3-1.3,3.6-0.4,3.7,1.4    C65.9,49.2,64.4,67,56.9,72.1z"></path>
        </g>
      </g>
    </svg>
  );
};
