import React, { useState, useCallback, useEffect } from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';

import { LightState } from '../../components/LightSchedule';

import useModalVisible from '../../hooks/useModalVisible';

import { SaveModal } from '../../components/SaveModal';
import ModalTitle from '../../components/ModalTitle';
import Loading from '../../components/Loading';
import LightSchedule from '../../components/LightSchedule';

import style from './style.module.scss';

interface Input {
  zoneName: string;
  zoneId: string;
  onCompleted?: () => void;
}

interface Result {
  openZoneLightsBulkEditForm: () => void;
  ZoneLightsBulkEditForm: typeof ZoneLightsBulkEditForm;
  zoneLightsBulkEditFormProps: Props;
}

const BULK_UPDATE_ZONE_STACK_LIGHTS = gql`
  mutation updateZoneStackLights($input: UpdateZoneStackLightsInputType!) {
    updateZoneStackLights(input: $input) {
      stacks {
        stackId
        light {
          isOn
          schedule {
            enabled
            activeTimeBlockIndex
            timeBlocks
          }
        }
      }
    }
  }
`;

const ZONE_QUERY = gql`
  query zone($zoneId: ID!) {
    zone(zoneId: $zoneId) {
      zoneId
      prototypeStack: stack(stackName: "A") {
        stackId
        light {
          isOn
          schedule {
            enabled
            activeTimeBlockIndex
            timeBlocks
          }
        }
      }
      stacks {
        stackId
        name
        light {
          isOn
          schedule {
            enabled
            activeTimeBlockIndex
            timeBlocks
          }
        }
      }
    }
  }
`;

interface StackLight {
  isOn: boolean;
  schedule: {
    enabled: boolean;
    activeTimeBlockIndex: number;
    timeBlocks: Array<boolean>;
  };
}

interface StackLightSetting {
  stackId: string;
  name: string;
  light: StackLight;
}

interface Props extends Input {
  zoneLightsBulkEditFormIsOpen: boolean;
  closeZoneLightsBulkEditForm: () => void;
}

const ZoneLightsBulkEditForm = ({
  zoneName,
  zoneId,
  zoneLightsBulkEditFormIsOpen,
  closeZoneLightsBulkEditForm,
  onCompleted,
}: Props) => {
  const [visible, modalVisibleProps] = useModalVisible();

  const { data, loading: queryLoading, refetch } = useQuery(ZONE_QUERY, {
    variables: {
      zoneId,
    },
    skip: !visible,
  });

  const [
    bulkUpdateZoneStackLights,
    { error: saveError, loading: saving },
  ] = useMutation(BULK_UPDATE_ZONE_STACK_LIGHTS, {
    onCompleted: () => {
      closeZoneLightsBulkEditForm();
      onCompleted && onCompleted();
    },
  });

  const [wasOpen, setWasOpen] = useState(false);

  useEffect(() => {
    const reopened = zoneLightsBulkEditFormIsOpen && !wasOpen;

    if (reopened) {
      refetch();
    }

    setWasOpen(zoneLightsBulkEditFormIsOpen);
  }, [zoneLightsBulkEditFormIsOpen, refetch, wasOpen, setWasOpen]);

  const stacks = data?.zone?.stacks;

  const [stackLightSettings, setStackLightSettings] = useState<
    Array<StackLightSetting>
  >([]);

  useEffect(() => {
    setStackLightSettings(stacks);
  }, [stacks]);

  return (
    <SaveModal
      scrollable
      className={[style.modal, queryLoading ? style.loading : null].join(' ')}
      title={
        <ModalTitle
          icon={<FontAwesomeIcon icon={faLightbulb} />}
          title={`Set All Stack Lights for Zone ${zoneName}`}
        />
      }
      isOpen={zoneLightsBulkEditFormIsOpen}
      saving={saving}
      error={saveError}
      onComplete={closeZoneLightsBulkEditForm}
      onSave={() =>
        bulkUpdateZoneStackLights({
          variables: {
            input: {
              zoneId: zoneId,
              lightScheduleArguments: stackLightSettings.map(
                (x: StackLightSetting) => ({
                  stackId: x.stackId,
                  lightState: x.light.schedule.enabled
                    ? 'USE_SCHEDULE'
                    : x.light.isOn
                    ? 'ON'
                    : 'OFF',
                  timeBlocks: x.light.schedule.timeBlocks,
                })
              ),
            },
          },
        })
      }
      {...modalVisibleProps}
    >
      <>
        {queryLoading && <Loading />}

        <div className={style.modalContentContainer}>
          {stackLightSettings?.map((stack: StackLightSetting, key: number) => (
            <div className={style.stackControlsContainer} key={key}>
              <h5>Stack {stack.name}</h5>
              <LightSchedule
                scheduleIsEnabled={stack.light?.schedule?.enabled}
                lightIsOn={stack.light?.isOn}
                onLightScheduleStateChange={(lightState: LightState) => {
                  const updatedStackLightSettings = stackLightSettings.map(x =>
                    x.stackId === stack.stackId
                      ? {
                          ...x,
                          light: {
                            ...x.light,
                            isOn:
                              lightState === 'ON' ||
                              (lightState === 'USE_SCHEDULE' &&
                                stack.light?.schedule?.timeBlocks[
                                  stack.light?.schedule?.activeTimeBlockIndex
                                ]),
                            schedule: {
                              ...x.light.schedule,
                              enabled: lightState === 'USE_SCHEDULE',
                            },
                          },
                        }
                      : x
                  );
                  setStackLightSettings(updatedStackLightSettings);
                }}
                timeBlocks={stack.light?.schedule?.timeBlocks}
                activeTimeBlockIndex={
                  stack.light?.schedule?.activeTimeBlockIndex
                }
                onToggleTimeBlock={(timeBlockIndex: number) => {
                  const updatedTimeBlocks = [
                    ...stack.light?.schedule?.timeBlocks,
                  ];
                  updatedTimeBlocks[timeBlockIndex] = !updatedTimeBlocks[
                    timeBlockIndex
                  ];
                  const updatedStackLightSettings = stackLightSettings.map(x =>
                    x.stackId === stack.stackId
                      ? {
                          ...x,
                          light: {
                            ...x.light,
                            schedule: {
                              ...x.light.schedule,
                              timeBlocks: updatedTimeBlocks,
                            },
                          },
                        }
                      : x
                  );
                  setStackLightSettings(updatedStackLightSettings);
                }}
              />
            </div>
          ))}
        </div>
      </>
    </SaveModal>
  );
};

const useZoneLightsBulkEditForm: (input: Input) => Result = ({
  zoneName,
  zoneId,
  onCompleted,
}) => {
  const [
    zoneLightsBulkEditFormIsOpen,
    setZoneLightsBulkEditFormIsOpen,
  ] = useState(false);

  return {
    openZoneLightsBulkEditForm: useCallback(
      () => setZoneLightsBulkEditFormIsOpen(true),
      [setZoneLightsBulkEditFormIsOpen]
    ),
    ZoneLightsBulkEditForm,
    zoneLightsBulkEditFormProps: {
      zoneName,
      zoneId,
      zoneLightsBulkEditFormIsOpen,
      onCompleted,
      closeZoneLightsBulkEditForm: useCallback(
        () => setZoneLightsBulkEditFormIsOpen(false),
        [setZoneLightsBulkEditFormIsOpen]
      ),
    },
  };
};

export default useZoneLightsBulkEditForm;
