import React, { FC } from 'react';
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Input as FormInput,
  Button,
} from 'reactstrap';
import { getInputValueAnd, updateState } from '../../modules/form-helpers';

interface Props {
  className: any;
  manualCalibrationPoints: number[];
  isDirtyState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  currentWaterDepth: number;
  setCurrentWaterDepth: (value: number) => void;
  addCalibrationPoints: (currentWaterDepth: number) => void;
  removeCalibrationPoints: () => void;
}

const ManualCalibrationForm: FC<Props> = ({
  className,
  manualCalibrationPoints,
  isDirtyState,
  currentWaterDepth,
  setCurrentWaterDepth,
  addCalibrationPoints,
  removeCalibrationPoints,
}: Props): JSX.Element => {
  return (
    <>
      <div className={className.calibrationPointsHeading}>
        Current Calibration Points
      </div>
      <div className={className.calibrationDisplay}>
        <div className={className.calibrationPointsGauge} />
        <div className={className.calibrationPointsContainer}>
          {manualCalibrationPoints.map((point: number, index: number) => (
            <div className={className.calibrationPoint} key={index}>
              <div className={className.calibrationPointIndicator} />
              <div className={className.calibrationPointValue}>{point}"</div>
            </div>
          ))}
        </div>
      </div>
      <div className={className.manualInputContainer}>
        <FormGroup>
          <Label for="currentWaterDepth">Current Water Depth</Label>
          <InputGroup>
            <FormInput
              id="currentWaterDepth"
              type="number"
              required
              min={0}
              max={250}
              className={className.sensorInput}
              value={currentWaterDepth}
              onChange={getInputValueAnd(
                updateState({
                  valueState: [
                    currentWaterDepth,
                    (value: number) => {
                      if (value >= 0 && value <= 250) {
                        setCurrentWaterDepth(Number(value));
                      }
                    },
                  ],
                  isDirtyState,
                })
              )}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>in</InputGroupText>
            </InputGroupAddon>
            <Button
              className={className.calibrationButton}
              onClick={event => {
                event.preventDefault();
                addCalibrationPoints(currentWaterDepth);
              }}
            >
              Add
            </Button>
          </InputGroup>
          <div className={className.clearCalibrationContainer}>
            <Button
              className={className.calibrationButton}
              onClick={event => {
                event.preventDefault();
                removeCalibrationPoints();
              }}
            >
              Clear All Points
            </Button>
          </div>
        </FormGroup>
      </div>
    </>
  );
};

export default ManualCalibrationForm;
