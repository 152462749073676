import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export interface LevelBeeSensorValues {
  tempInF: string;
  humidityInPercentRh: string;
  pressureInHPa: string;
  co2InPpm: string;
}

export interface LevelBeeSensorRawValues {
  rawTempInF: string;
  rawHumidityInPercentRh: string;
  rawPressureInHPa: string;
  rawCo2InPpm: string;
}

export interface LevelBeeSensorThresholds {
  tempLowInF: string;
  tempLowAlertInF: string;
  tempHighInF: string;
  tempHighAlertInF: string;

  humidityLowInPercentRh: string;
  humidityLowAlertInPercentRh: string;
  humidityHighInPercentRh: string;
  humidityHighAlertInPercentRh: string;

  pressureLowInHPa: string;
  pressureLowAlertInHPa: string;
  pressureHighInHPa: string;
  pressureHighAlertInHPa: string;

  cO2LowInPpm: string;
  cO2LowAlertInPpm: string;
  cO2HighInPpm: string;
  cO2HighAlertInPpm: string;
}

export interface LevelBeeSensorCalibrationOffsets {
  tempCalibrationOffsetInF: string;
  humidityCalibrationOffsetInPercentRh: string;
  pressureCalibrationOffsetInHPa: string;
  cO2CalibrationOffsetInPpm: string;
}

class BeeSensorValue {
  label: string;
  unit?: string;
  color: string;
  valueKey: keyof LevelBeeSensorValues;
  calibrationOffsetValueKey: keyof LevelBeeSensorCalibrationOffsets;
  lowAlertKey: keyof LevelBeeSensorThresholds;
  lowKey: keyof LevelBeeSensorThresholds;
  highKey: keyof LevelBeeSensorThresholds;
  highAlertKey: keyof LevelBeeSensorThresholds;

  constructor({
    label,
    unit,
    valueKey,
    calibrationOffsetValueKey,
    color,
    lowAlertKey,
    lowKey,
    highKey,
    highAlertKey,
  }: {
    label: string;
    unit?: string;
    color: string;
    valueKey: keyof LevelBeeSensorValues;
    calibrationOffsetValueKey: keyof LevelBeeSensorCalibrationOffsets;
    lowAlertKey: keyof LevelBeeSensorThresholds;
    lowKey: keyof LevelBeeSensorThresholds;
    highKey: keyof LevelBeeSensorThresholds;
    highAlertKey: keyof LevelBeeSensorThresholds;
  }) {
    this.label = label;
    this.unit = unit;
    this.valueKey = valueKey;
    this.calibrationOffsetValueKey = calibrationOffsetValueKey;
    this.color = color;
    this.lowAlertKey = lowAlertKey;
    this.lowKey = lowKey;
    this.highKey = highKey;
    this.highAlertKey = highAlertKey;
  }

  valueSelector = (values?: LevelBeeSensorValues) =>
    values && values[this.valueKey];

  calibrationOffsetValueSelector = (
    values?: LevelBeeSensorCalibrationOffsets
  ) => values && values[this.calibrationOffsetValueKey];

  lowAlertValueSelector = (thresholds?: LevelBeeSensorThresholds) =>
    thresholds && thresholds[this.lowAlertKey];

  lowValueSelector = (thresholds?: LevelBeeSensorThresholds) =>
    thresholds && thresholds[this.lowKey];

  highValueSelector = (thresholds?: LevelBeeSensorThresholds) =>
    thresholds && thresholds[this.highKey];

  highAlertValueSelector = (thresholds?: LevelBeeSensorThresholds) =>
    thresholds && thresholds[this.highAlertKey];

  withLowAlertValue = (
    thresholds: LevelBeeSensorThresholds,
    value: number
  ) => ({
    ...thresholds,
    [this.lowAlertKey]: value.toString(),
  });

  withLowValue = (thresholds: LevelBeeSensorThresholds, value: number) => ({
    ...thresholds,
    [this.lowKey]: value.toString(),
  });

  withHighValue = (thresholds: LevelBeeSensorThresholds, value: number) => ({
    ...thresholds,
    [this.highKey]: value.toString(),
  });

  withHighAlertValue = (
    thresholds: LevelBeeSensorThresholds,
    value: number
  ) => ({ ...thresholds, [this.highAlertKey]: value.toString() });
}

export const LevelBeeSensorMeasurementColors: {
  temp: string;
  humidity: string;
  pressure: string;
  co2: string;
} = {
  temp: 'grey',
  humidity: '#ac3541',
  pressure: '#0e4d92',
  co2: '#f9a602',
};

export const LevelBeeSensorMeasurements: {
  all: Array<BeeSensorValue>;
  fragments: {
    LevelBeeSensorCurrentValue: DocumentNode;
    LevelBeeSensorCurrentRawValue: DocumentNode;
    LevelBeeSensorAggregatedValues: DocumentNode;
    LevelBeeSensorThresholds: DocumentNode;
    LevelBeeSensorCalibrationOffsets: DocumentNode;
  };
} = {
  all: [
    new BeeSensorValue({
      label: 'Temp',
      unit: 'ºF',
      color: LevelBeeSensorMeasurementColors.temp,
      valueKey: 'tempInF',
      calibrationOffsetValueKey: 'tempCalibrationOffsetInF',
      lowAlertKey: 'tempLowAlertInF',
      lowKey: 'tempLowInF',
      highKey: 'tempHighInF',
      highAlertKey: 'tempHighAlertInF',
    }),

    new BeeSensorValue({
      label: 'Humidity',
      unit: '%',
      color: LevelBeeSensorMeasurementColors.humidity,
      valueKey: 'humidityInPercentRh',
      calibrationOffsetValueKey: 'humidityCalibrationOffsetInPercentRh',
      lowAlertKey: 'humidityLowAlertInPercentRh',
      lowKey: 'humidityLowInPercentRh',
      highKey: 'humidityHighInPercentRh',
      highAlertKey: 'humidityHighAlertInPercentRh',
    }),

    new BeeSensorValue({
      label: 'Pressure',
      unit: 'hPa',
      color: LevelBeeSensorMeasurementColors.pressure,
      valueKey: 'pressureInHPa',
      calibrationOffsetValueKey: 'pressureCalibrationOffsetInHPa',
      lowAlertKey: 'pressureLowAlertInHPa',
      lowKey: 'pressureLowInHPa',
      highKey: 'pressureHighInHPa',
      highAlertKey: 'pressureHighAlertInHPa',
    }),

    new BeeSensorValue({
      label: 'Co2',
      unit: 'ppm',
      color: LevelBeeSensorMeasurementColors.co2,
      valueKey: 'co2InPpm',
      calibrationOffsetValueKey: 'cO2CalibrationOffsetInPpm',
      lowAlertKey: 'cO2LowAlertInPpm',
      lowKey: 'cO2LowInPpm',
      highKey: 'cO2HighInPpm',
      highAlertKey: 'cO2HighAlertInPpm',
    }),
  ],
  fragments: {
    LevelBeeSensorCurrentValue: gql`
      fragment LevelBeeSensorValues on BeeSensorTelemetryEventType {
        tempInF
        humidityInPercentRh
        pressureInHPa
        co2InPpm
      }
    `,
    LevelBeeSensorCurrentRawValue: gql`
      fragment LevelBeeSensorRawValues on BeeSensorTelemetryEventType {
        rawTempInF
        rawHumidityInPercentRh
        rawPressureInHPa
        rawCo2InPpm
      }
    `,
    LevelBeeSensorAggregatedValues: gql`
      fragment LevelBeeSensorAggregatedValues on BeeSensorAggregatedDataType {
        minimum {
          tempInF
          humidityInPercentRh
          pressureInHPa
          co2InPpm
        }
        average {
          tempInF
          humidityInPercentRh
          pressureInHPa
          co2InPpm
        }
        maximum {
          tempInF
          humidityInPercentRh
          pressureInHPa
          co2InPpm
        }
      }
    `,
    LevelBeeSensorThresholds: gql`
      fragment LevelBeeSensorThresholds on BeeSensorThresholdsType {
        tempLowInF
        tempLowAlertInF
        tempHighInF
        tempHighAlertInF
        humidityLowInPercentRh
        humidityLowAlertInPercentRh
        humidityHighInPercentRh
        humidityHighAlertInPercentRh
        pressureLowInHPa
        pressureLowAlertInHPa
        pressureHighInHPa
        pressureHighAlertInHPa
        cO2LowInPpm
        cO2LowAlertInPpm
        cO2HighInPpm
        cO2HighAlertInPpm
      }
    `,
    LevelBeeSensorCalibrationOffsets: gql`
      fragment LevelBeeSensorCalibrationOffsets on BeeSensorType {
        tempCalibrationOffsetInF
        humidityCalibrationOffsetInPercentRh
        pressureCalibrationOffsetInHPa
        cO2CalibrationOffsetInPpm
      }
    `,
  },
};
