import React from 'react';

import style from './style.module.scss';

import Tank from '../Tank';

interface Props {
  className?: string;
  entityType: string;
  entityName: string;
  waterLevel?: string;
  pumpState?: string;
  hidePump?: boolean;
  dryTankDetected?: boolean;
  gallons?: string;
  hasSensorStateAlert?: boolean;
  levelSensors?: boolean[];
  sensorsOnRight?: boolean;
  children?: any;
}

export default ({
  className,
  entityType,
  entityName,
  waterLevel,
  pumpState,
  dryTankDetected,
  gallons,
  hidePump,
  levelSensors,
  sensorsOnRight,
  children,
}: Props) => {
  return (
    <div className={[style.container, className].join(' ')}>
      <div className={style.identifier}>
        <div className={style.entity}>{entityType}</div>
        <div className={style.name}>{entityName}</div>
      </div>
      <Tank
        unknownIconClassName={style.unknownIcon}
        waterLevel={waterLevel}
        pumpState={pumpState}
        gallons={gallons}
        hidePump={hidePump}
        volumeValueClassName={style.volumeValue}
        volumeUnitClassName={style.volumeUnit}
        levelSensors={levelSensors}
        sensorsOnRight={sensorsOnRight}
        dryTankDetected={dryTankDetected}
      >
        {children}
      </Tank>
    </div>
  );
};
