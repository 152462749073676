import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';

import PromptModal from '../components/PromptModal';

const RESET_ZONE_CLOGGED_STRAINER = gql`
  mutation resetZoneCloggedStrainerAlert(
    $input: ResetZoneCloggedStrainerAlertInputType!
  ) {
    resetZoneCloggedStrainerAlert(input: $input) {
      zone {
        zoneId
        mode
        strainerClogDetected
        resetZoneCloggedStrainerAlertCommand {
          canExecute
        }
      }
    }
  }
`;

interface Input {
  zoneName: string;
  zoneId: string;
  onCompleted?: () => void;
}

interface Result {
  openZoneResetCloggedStrainerPrompt: () => void;
  ZoneResetCloggedStrainerPrompt: typeof ZoneResetCloggedStrainerPrompt;
  zoneResetCloggedStrainerPromptProps: Props;
}

interface Props extends Input {
  zoneResetCloggedStrainerPromptIsOpen: boolean;
  closeZoneResetCloggedStrainerPrompt: () => void;
}

const ZoneResetCloggedStrainerPrompt = ({
  zoneName,
  zoneId,
  zoneResetCloggedStrainerPromptIsOpen,
  closeZoneResetCloggedStrainerPrompt,
  onCompleted,
}: Props) => {
  const [
    resetZoneCloggedStrainerAlert,
    { error, loading: saving },
  ] = useMutation(RESET_ZONE_CLOGGED_STRAINER, {
    onCompleted: () => {
      closeZoneResetCloggedStrainerPrompt();
      onCompleted && onCompleted();
    },
  });

  return (
    <PromptModal
      title={`Clear Clogged Strainer Alert for Zone ${zoneName}`}
      isOpen={zoneResetCloggedStrainerPromptIsOpen}
      saving={saving}
      error={error}
      onSave={() => {
        resetZoneCloggedStrainerAlert({
          variables: {
            input: {
              zoneId,
            },
          },
        });
      }}
      onComplete={closeZoneResetCloggedStrainerPrompt}
    >
      <p>
        A clogged strainer has been detected in {`Zone ${zoneName}.`} <br />
        As a precaution, the system has placed this Zone into Maintenance Mode.
      </p>

      <p>
        {`Reset the alert and return Zone ${zoneName} to regular operations?`}
      </p>
    </PromptModal>
  );
};

const useZoneResetCloggedStrainerPrompt: (input: Input) => Result = ({
  zoneName,
  zoneId,
  onCompleted,
}) => {
  const [
    zoneResetCloggedStrainerPromptIsOpen,
    setZoneResetCloggedStrainerPromptIsOpen,
  ] = useState(false);

  return {
    openZoneResetCloggedStrainerPrompt: useCallback(
      () => setZoneResetCloggedStrainerPromptIsOpen(true),
      [setZoneResetCloggedStrainerPromptIsOpen]
    ),
    ZoneResetCloggedStrainerPrompt,
    zoneResetCloggedStrainerPromptProps: {
      zoneName,
      zoneId,
      zoneResetCloggedStrainerPromptIsOpen,
      onCompleted,
      closeZoneResetCloggedStrainerPrompt: useCallback(
        () => setZoneResetCloggedStrainerPromptIsOpen(false),
        [setZoneResetCloggedStrainerPromptIsOpen]
      ),
    },
  };
};

export default useZoneResetCloggedStrainerPrompt;
