import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Input, FormText } from 'reactstrap';
import zxcvbn from 'zxcvbn';

import style from './style.module.scss';

const SCORE_WORDS = ['weak', 'weak', 'okay', 'good', 'strong'];

export default ({ minimumPasswordScore = 2, onPasswordChanged }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const passwordScore = newPassword ? zxcvbn(newPassword).score : 0;
  const passwordStrength = SCORE_WORDS[passwordScore];
  const passwordMeetsMinimumStrength =
    !!newPassword && passwordScore >= minimumPasswordScore;
  const passwordsMatch = newPassword === confirmNewPassword;
  const isValid =
    !!newPassword && passwordMeetsMinimumStrength && passwordsMatch;

  useEffect(() => {
    onPasswordChanged && onPasswordChanged(isValid ? newPassword : null);
  }, [onPasswordChanged, isValid, newPassword]);

  return (
    <>
      <FormGroup>
        <Label for="newPassword">New Password</Label>
        <Input
          id="newPassword"
          type="password"
          required
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
          valid={passwordMeetsMinimumStrength}
          invalid={!!newPassword && !passwordMeetsMinimumStrength}
        />

        {newPassword && (
          <FormText className={`${style.passwordStrength} ${passwordStrength}`}>
            {passwordStrength}
          </FormText>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="confirmNewPassword">Confirm New Password</Label>
        <Input
          id="confirmNewPassword"
          type="password"
          required
          value={confirmNewPassword}
          onChange={e => setConfirmNewPassword(e.target.value)}
          valid={!!newPassword && passwordsMatch}
          invalid={!!newPassword && !passwordsMatch}
        />
      </FormGroup>
    </>
  );
};
