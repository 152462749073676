import React from 'react';
import sync from '../../modules/css-animation-sync';

import style from './style.module.scss';

const isPipeAnimationRegExp = RegExp('pipe-animation');

const isPipeAnimation = (animationName: string) =>
  isPipeAnimationRegExp.test(animationName);

sync(isPipeAnimation, '--animation-sync-delay');

interface Props {
  waterIsFlowing: boolean;
  className?: string;
}

const Pipe = ({ waterIsFlowing, className }: Props) => {
  return (
    <div
      className={[
        style.pipe,
        waterIsFlowing ? null : style.notFlowing,
        className,
      ].join(' ')}
    ></div>
  );
};

export default Pipe;
