import React from 'react';

import ValveButton from '../ValveButton';

import useStackLevelValveStatePrompts from '../../hooks/useStackLevelValveStatePrompts';

interface IProps {
  zoneName: string;
  stackName: string;
  levelName: string;
  levelId: string;
  levelValveState: 'OPEN' | 'CLOSED';
  className?: string;
  needsAttention?: boolean;
}

const LevelValveButton = ({
  zoneName,
  stackName,
  levelName,
  levelId,
  levelValveState,
  className,
  needsAttention = false,
  ...props
}: IProps) => {
  const {
    openStackLevelOpenValvePrompt,
    openStackLevelCloseValvePrompt,
    StackLevelValveStatePrompts,
    stackLevelValveStatePromptsProps,
  } = useStackLevelValveStatePrompts({
    zoneName,
    stackName,
    levelName,
    levelId,
  });

  return (
    <>
      <StackLevelValveStatePrompts {...stackLevelValveStatePromptsProps} />

      <ValveButton
        valveState={levelValveState}
        needsAttention={needsAttention}
        className={className}
        onClick={
          levelValveState === 'OPEN'
            ? openStackLevelCloseValvePrompt
            : openStackLevelOpenValvePrompt
        }
        {...props}
      />
    </>
  );
};

export default LevelValveButton;
