import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import style from './style.module.scss';

interface IProps {
  waterDetected: boolean;
  onTankRight?: boolean;
}
export default ({ waterDetected, onTankRight = true }: IProps) => (
  <div
    className={[
      style.indicator,
      onTankRight ? style.pointerLeft : style.pointerRight,
      waterDetected ? style.enabled : style.disabled,
    ].join(' ')}
  >
    <FontAwesomeIcon icon={waterDetected ? faCheck : faTimes} />
  </div>
);
