import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  facilityId: string;
  onCompleted?: () => void;
}

interface Result {
  openReverseOsmosisAutomaticModePrompt: () => void;
  openReverseOsmosisMaintenanceModePrompt: () => void;
  ReverseOsmosisModePrompts: typeof ReverseOsmosisModePrompts;
  reverseOsmosisModePromptsProps: Omit<Props, 'reverseOsmosisMode'>;
}

const UPDATE_REVERSE_OSMOSIS_MODE = gql`
  mutation updateReverseOsmosisMode(
    $facilityId: ID!
    $mode: ReverseOsmosisMode!
  ) {
    updateReverseOsmosisMode(facilityId: $facilityId, mode: $mode) {
      facilityId
      pumpRoom {
        reverseOsmosisSystem {
          mode
        }
      }
    }
  }
`;

interface Props extends Input {
  automaticModePromptIsOpen: boolean;
  maintenanceModePromptIsOpen: boolean;
  closeAutomaticModePrompt: () => void;
  closeMaintenanceModePrompt: () => void;
}

const ReverseOsmosisModePrompts = ({
  facilityId,
  automaticModePromptIsOpen,
  maintenanceModePromptIsOpen,
  closeAutomaticModePrompt,
  closeMaintenanceModePrompt,
  onCompleted,
}: Props) => {
  const [updateReverseOsmosisMode, { error, loading: saving }] = useMutation(
    UPDATE_REVERSE_OSMOSIS_MODE,
    {
      onCompleted: () => {
        closeAutomaticModePrompt();
        closeMaintenanceModePrompt();
        onCompleted && onCompleted();
      },
    }
  );

  return (
    <>
      <PromptModal
        title={`Put Reverse Osmosis System to Automatic Mode`}
        isOpen={automaticModePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateReverseOsmosisMode({
            variables: { facilityId, mode: 'AUTOMATIC' },
          });
        }}
        onComplete={closeAutomaticModePrompt}
      >
        <p>
          Putting the reverse osmosis system into automatic mode will allow the
          reverse osmosis system to automatically fill the zone tanks as needed.
        </p>

        <p>Put the reverse osmosis system into automatic mode?</p>
      </PromptModal>

      <PromptModal
        title={`Put Reverse Osmosis System to Maintenance Mode`}
        isOpen={maintenanceModePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateReverseOsmosisMode({
            variables: { facilityId, mode: 'MAINTENANCE' },
          });
        }}
        onComplete={closeMaintenanceModePrompt}
      >
        <p>
          Putting the reverse osmosis system into maintenance mode will prevent
          the reverse osmosis system from automatically filling the zone tanks
          as needed.
        </p>

        <p>Put the reverse osmosis system into maintenance mode?</p>
      </PromptModal>
    </>
  );
};

const useReverseOsmosisModePrompts: (input: Input) => Result = ({
  facilityId,
  onCompleted,
}: Input) => {
  const [automaticModePromptIsOpen, setAutomaticModePromptIsOpen] = useState(
    false
  );

  const [
    maintenanceModePromptIsOpen,
    setMaintenanceModePromptIsOpen,
  ] = useState(false);

  return {
    openReverseOsmosisAutomaticModePrompt: useCallback(
      () => setAutomaticModePromptIsOpen(true),
      [setAutomaticModePromptIsOpen]
    ),
    openReverseOsmosisMaintenanceModePrompt: useCallback(
      () => setMaintenanceModePromptIsOpen(true),
      [setMaintenanceModePromptIsOpen]
    ),
    ReverseOsmosisModePrompts,
    reverseOsmosisModePromptsProps: {
      facilityId,
      automaticModePromptIsOpen,
      maintenanceModePromptIsOpen,
      onCompleted,
      closeAutomaticModePrompt: useCallback(
        () => setAutomaticModePromptIsOpen(false),
        [setAutomaticModePromptIsOpen]
      ),
      closeMaintenanceModePrompt: useCallback(
        () => setMaintenanceModePromptIsOpen(false),
        [setMaintenanceModePromptIsOpen]
      ),
    },
  };
};

export default useReverseOsmosisModePrompts;
