import React from 'react';
import { ApolloError } from 'apollo-client/errors/ApolloError';

import { getErrorMessages } from '../../modules/errors';

interface Props {
  error: ApolloError;
}

export default ({ error }: Props) => {
  return (
    <>
      {getErrorMessages(error).map((message, i) => (
        <div key={i}>{message}</div>
      ))}
    </>
  );
};
