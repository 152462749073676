const caseInsensitiveConfig = { sensitivity: 'base' };

export const equalsIgnoringCase = (x, y) => {
  if (!x && !y) {
    return true;
  }

  if (!x) {
    return false;
  }

  return x.localeCompare(y, undefined, caseInsensitiveConfig) === 0;
};
