import React from 'react';
import gql from 'graphql-tag';
import { useQuery, useSubscription } from '@apollo/react-hooks';
import { Switch, Route, Redirect } from 'react-router-dom';
import { equalsIgnoringCase } from '../../../../modules/string';

import OzoneSystem from './components/OzoneSystem';
import ReverseOsmosisSystem from './components/ReverseOsmosisSystem';
import PageLoadingContainer from '../../../../components/PageLoadingContainer';
import PageNotFound from '../../../../components/PageNotFound';

import style from './style.module.scss';

const pumpRoomDetailsFragment = gql`
  fragment PumpRoomDetails on FacilityType {
    facilityId
    pumpRoom {
      ozoneSystems {
        ozoneSystemId
        name
      }
    }
    ...ReverseOsmosisSystem
    ...OzoneSystem
  }
  ${ReverseOsmosisSystem.fragment}
  ${OzoneSystem.fragment}
`;

const FACILITY_QUERY = gql`
  query facility($facilityId: ID!) {
    facility(facilityId: $facilityId) {
      facilityId
      ...PumpRoomDetails
    }
  }
  ${pumpRoomDetailsFragment}
`;

const FACILITY_SUBSCRIPTION = gql`
  subscription facilityUpdated($facilityId: ID!) {
    facilityUpdated(facilityId: $facilityId) {
      facility {
        facilityId
        ...PumpRoomDetails
      }
    }
  }
  ${pumpRoomDetailsFragment}
`;

const PageContent = ({ facilityId }) => {
  const result = useQuery(FACILITY_QUERY, {
    variables: {
      facilityId: facilityId,
    },
  });

  useSubscription(FACILITY_SUBSCRIPTION, {
    variables: { facilityId },
  });

  const { data } = result;
  const { facility } = data ? data : {};
  const ozoneSystems = facility?.pumpRoom?.ozoneSystems || [];

  return (
    <div className={style.container}>
      <PageLoadingContainer
        resourceTypeName="Facility"
        result={result}
        resourceExists={!!facility}
        render={() => (
          <div className={style.detailContainer}>
            <ReverseOsmosisSystem
              className={style.reverseOsmosisSystem}
              facility={facility}
            />

            <Switch>
              <Route
                exact
                path="/facility/:facilityId/ozone-system/:ozoneSystemName"
                render={({
                  match: {
                    params: { ozoneSystemName },
                  },
                }) => {
                  const ozoneSystem = ozoneSystems?.find(x =>
                    equalsIgnoringCase(x.name, ozoneSystemName)
                  );

                  return (
                    <>
                      {ozoneSystem ? (
                        <OzoneSystem
                          className={style.ozoneSystem}
                          facilityId={facilityId}
                          ozoneSystemName={ozoneSystemName}
                          ozoneSystemId={ozoneSystem.ozoneSystemId}
                          pumpRoom={facility.pumpRoom}
                        />
                      ) : (
                        <PageNotFound
                          className={style.ozoneSystemNotFound}
                          resourceTypeName={`Ozone System ${ozoneSystemName}`}
                        />
                      )}
                    </>
                  );
                }}
              />

              <Redirect to={`/facility/${facilityId}/ozone-system/1`} />
            </Switch>
          </div>
        )}
      />
    </div>
  );
};

export default () => {
  return (
    <Switch>
      <Route
        exact
        path="/facility/:facilityId"
        render={({
          match: {
            params: { facilityId },
          },
        }) => <Redirect to={`/facility/${facilityId}/ozone-system/1`} />}
      ></Route>

      <Route
        path="/facility/:facilityId"
        render={({
          match: {
            params: { facilityId },
          },
        }) => <PageContent facilityId={facilityId} />}
      />
    </Switch>
  );
};
