import React from 'react';
import gql from 'graphql-tag';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHistory,
  faCog,
  faExclamationTriangle,
  faTachometerAlt,
} from '@fortawesome/free-solid-svg-icons';

import Gauge from '../../../../components/Gauge';

import useLevelBeeSensorValuesModal from '../../../../hooks/useLevelBeeSensorHistoryModal';
import useLevelBeeSensorConfigureThresholdsForm from '../../../../hooks/useLevelBeeSensorConfigureThresholdsForm';

import { LevelBeeSensorMeasurements } from '../../../../modules/bee-sensors';

import style from './style.module.scss';

interface IProps {
  zoneName: string;
  stackName: string;
  levelName: string;
  levelId: string;
  beeSensor: any;
  className?: string;
}

const LevelBeeSensorGauges = ({
  zoneName,
  stackName,
  levelName,
  levelId,
  beeSensor,
  className,
}: IProps) => {
  const {
    openLevelBeeSensorValuesModal,
    LevelBeeSensorValuesModal,
    levelBeeSensorValuesModalProps,
  } = useLevelBeeSensorValuesModal({
    zoneName,
    stackName,
    levelName,
    levelId,
  });

  const {
    openLevelBeeSensorConfigureThresholdsForm,
    LevelBeeSensorConfigureThresholdsForm,
    levelBeeSensorConfigureThresholdsFormProps,
  } = useLevelBeeSensorConfigureThresholdsForm({
    zoneName,
    stackName,
    levelName,
    levelId,
  });

  const {
    currentValue,
    thresholds,
    updateBeeSensorThresholdsCommand,
    serialNumber,
  } = beeSensor || {};

  return (
    <div className={[style.container, className].join(' ')}>
      <LevelBeeSensorValuesModal {...levelBeeSensorValuesModalProps} />

      <LevelBeeSensorConfigureThresholdsForm
        {...levelBeeSensorConfigureThresholdsFormProps}
      />

      <div className={style.levelBeeSensorTitleContainer}>
        {!!serialNumber ? (
          <FontAwesomeIcon icon={faTachometerAlt} fixedWidth />
        ) : (
          <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
        )}
        <div className={style.levelBeeSensorTitleText}>
          <span
            className={style.levelBeeSensorTitle}
          >{`Zone ${zoneName} / Stack ${stackName} / Level ${levelName}`}</span>

          <span className={style.levelBeeSensorSerial}>
            {!!serialNumber
              ? `Serial: ${serialNumber}`
              : 'No sensor configured'}
          </span>
        </div>
      </div>

      <div className={style.gauges}>
        {LevelBeeSensorMeasurements.all.map(measurement => (
          <Gauge
            className={style.gauge}
            key={measurement.label}
            label={measurement.label}
            color={measurement.color}
            unit={measurement.unit}
            value={measurement.valueSelector(currentValue)}
            lowCritical={measurement.lowAlertValueSelector(thresholds)}
            low={measurement.lowValueSelector(thresholds)}
            high={measurement.highValueSelector(thresholds)}
            highCritical={measurement.highAlertValueSelector(thresholds)}
            onClick={openLevelBeeSensorValuesModal}
          />
        ))}
      </div>

      <div className={style.levelBeeSensorCommandsContainer}>
        <Button className="rounded" onClick={openLevelBeeSensorValuesModal}>
          <FontAwesomeIcon className="btn-icon" icon={faHistory} fixedWidth />{' '}
          History
        </Button>

        {updateBeeSensorThresholdsCommand?.canExecute && (
          <Button
            className="rounded"
            onClick={openLevelBeeSensorConfigureThresholdsForm}
          >
            <FontAwesomeIcon className="btn-icon" icon={faCog} fixedWidth />{' '}
            Thresholds
          </Button>
        )}
      </div>
    </div>
  );
};

LevelBeeSensorGauges.fragment = gql`
  fragment LevelBeeSensorGauges on BeeSensorType {
    currentValue {
      ...LevelBeeSensorValues
    }
    thresholds {
      ...LevelBeeSensorThresholds
    }
    updateBeeSensorThresholdsCommand {
      canExecute
    }
  }
  ${LevelBeeSensorMeasurements.fragments.LevelBeeSensorCurrentValue}
  ${LevelBeeSensorMeasurements.fragments.LevelBeeSensorThresholds}
`;

export default LevelBeeSensorGauges;
