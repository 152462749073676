import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import gql from 'graphql-tag';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import useStackLevelValveModePrompts from '../../../../hooks/useStackLevelValveModePrompts';

import plantsIconGray from '../../../../assets/images/plantsIconGray.svg';
import plantsIconGreen from '../../../../assets/images/plantsIconGreen.svg';
import { getBorderStatusClass } from '../../../../components/ticket-status-summary';

import StackCommands from './components/StackCommands';
import NamedTank from '../../../../components/NamedTank';
import CollectorTank from '../../../../components/CollectorTank';
import Pipe from '../../../../components/Pipe';
import Valve from '../../../../components/Valve';
import Switch from '../../../../components/Switch';
import BeeSensorDisplay from '../../../../components/BeeSensorDisplay';
import LevelValveButton from '../../../../components/LevelValveButton';

import { LevelBeeSensorMeasurements } from '../../../../modules/bee-sensors';

import style from './style.module.scss';

const LevelSwitch = ({
  zoneName,
  stackName,
  levelName,
  levelId,
  levelMode,
  updateLevelModeCommand,
  ...props
}) => {
  const {
    openStackLevelAutomaticModePrompt,
    openStackLevelMaintenanceModePrompt,
    StackLevelValveModePrompts,
    stackLevelValveModePromptsProps,
  } = useStackLevelValveModePrompts({
    zoneName,
    stackName,
    levelName,
    levelId,
  });

  return (
    <>
      <StackLevelValveModePrompts {...stackLevelValveModePromptsProps} />

      <Switch
        checked={levelMode === 'AUTOMATIC'}
        disabled={!updateLevelModeCommand.canExecute}
        onChange={
          levelMode === 'AUTOMATIC'
            ? openStackLevelMaintenanceModePrompt
            : openStackLevelAutomaticModePrompt
        }
        {...props}
      />
    </>
  );
};

const LevelConfigureButton = ({
  facilityId,
  zoneName,
  stackName,
  levelName,
}) => (
  <Link
    to={`/facility/${facilityId}/zone/${zoneName}/stack/${stackName}/level/${levelName}`}
  >
    <Button className={style.configureSensorButton}>
      <FontAwesomeIcon icon={faCog} fixedWidth />
    </Button>
  </Link>
);

const Levels = ({ zone, stack, collectionTankHidden }) => {
  const { mode: stackMode } = stack;

  const showCleanInPlaceMode = stack.mode === 'CLEAN_IN_PLACE';

  const waterFlowDetected = stack.waterFlowDetected ?? false;

  const waterIsFlowingOutOfCollectorTank =
    stack.collectorTank?.pumpState === 'ON' ||
    zone.collectorTank?.pumpState === 'ON';

  const waterIsFlowingOutOfZoneTank = showCleanInPlaceMode
    ? waterIsFlowingOutOfCollectorTank
    : zone.tank.pumpState === 'ON' &&
      stack.levels.some(x => x.valveState === 'OPEN') &&
      waterFlowDetected;

  const isLevelBeingIrrigated = level => {
    if (showCleanInPlaceMode) {
      return waterIsFlowingOutOfCollectorTank && level.valveState === 'OPEN';
    }

    return (
      zone.tank.pumpState === 'ON' &&
      level.valveState === 'OPEN' &&
      waterFlowDetected
    );
  };

  const isWaterFlowingThroughInFeederPipe = level =>
    stack.levels.some(x => x.index <= level.index && isLevelBeingIrrigated(x));

  const isWaterFlowingThroughOutFeederPipe = level =>
    stack.levels.some(x => x.index > level.index && isLevelBeingIrrigated(x));

  const isMobile = useMediaQuery({ query: `(max-width: 899px)` });
  console.log(isMobile);

  return (
    <div className={style.container}>
      <StackCommands
        className={style.stackCommands}
        zoneName={zone.name}
        stack={stack}
      />

      <div className={style.zoneAndStackContainer}>
        <div
          className={[
            style.collectorTankInPipeContainer,
            stackMode === 'MAINTENANCE' ? style.stackModeMaintenance : null,
          ].join(' ')}
        >
          <Pipe
            className={style.collectorTankInPipeHorizontal}
            waterIsFlowing={waterIsFlowingOutOfZoneTank}
          />

          <Pipe
            className={style.collectorTankInPipeVertical}
            waterIsFlowing={waterIsFlowingOutOfZoneTank}
          />

          <Pipe
            className={[
              style.collectorTankOutPipeHorizontal,
              showCleanInPlaceMode ? style.cleanInPlace : undefined,
            ].join(' ')}
            waterIsFlowing={waterIsFlowingOutOfCollectorTank}
          />

          <Pipe
            className={[
              style.collectorTankOutPipeVertical,
              showCleanInPlaceMode ? style.cleanInPlace : undefined,
            ].join(' ')}
            waterIsFlowing={waterIsFlowingOutOfCollectorTank}
          />
        </div>

        <div className={style.zoneTankContainer}>
          {showCleanInPlaceMode && (
            <Pipe
              className={style.cleanInPlaceDetourPipe}
              waterIsFlowing={waterIsFlowingOutOfCollectorTank}
            />
          )}

          <div className={style.controlContainer}>
            {!showCleanInPlaceMode && (
              <Pipe
                className={style.zoneTankInPipeHorizontal}
                waterIsFlowing={waterIsFlowingOutOfCollectorTank}
              />
            )}

            {collectionTankHidden && !isMobile && (
              <div className="py-5">
                <Pipe
                  className={style.zoneTankFullPipeHorizontal}
                  waterIsFlowing={waterIsFlowingOutOfCollectorTank}
                />
              </div>
            )}
          </div>

          {collectionTankHidden === false && (
            <Link to={`/facility/${zone.facilityId}/zone/${zone.name}`}>
              <NamedTank
                entityType="Zone"
                entityName={zone.name}
                className={style.zoneTank}
                waterLevel={zone.tank.waterLevel}
                gallons={zone.tank.gallons}
                pumpState={zone.tank.pumpState}
              />
            </Link>
          )}

          {collectionTankHidden && isMobile ? (
            <Pipe
              className={style.zoneTankFullMobilePipeHorizontal}
              waterIsFlowing={waterIsFlowingOutOfCollectorTank}
            />
          ) : (
            <div className={style.controlContainer}>
              {!showCleanInPlaceMode && (
                <Pipe
                  className={style.zoneTankOutPipeHorizontal}
                  waterIsFlowing={waterIsFlowingOutOfZoneTank}
                />
              )}
              <Pipe
                className={[
                  style.zoneTankOutPipeVertical,
                  showCleanInPlaceMode ? style.cleanInPlace : undefined,
                ].join(' ')}
                waterIsFlowing={waterIsFlowingOutOfZoneTank}
              />
              {!showCleanInPlaceMode && (
                <Pipe
                  className={style.zoneTankInPipeHorizontalMobile}
                  waterIsFlowing={waterIsFlowingOutOfCollectorTank}
                />
              )}
            </div>
          )}
        </div>

        <h5>Stack {stack.name}</h5>

        {stack.levels
          .map((level, index) => {
            const borderStatusClass = getBorderStatusClass('NONE');
            const {
              levelId,
              name,
              updateLevelModeCommand,
              updateLevelValveStateCommand,
            } = level;
            const waterIsFlowingThroughInFeederPipe = isWaterFlowingThroughInFeederPipe(
              level
            );
            const waterIsFlowingThroughOutFeederPipe = isWaterFlowingThroughOutFeederPipe(
              level
            );
            const levelIsBeingIrrigated = isLevelBeingIrrigated(level);

            return (
              <div
                id={style[stack.levels.length - 1 - index]}
                key={levelId}
                className={[
                  style.levelContainer,
                  index === 0 ? style.firstLevel : null,
                  index === stack.levels.length - 1 ? style.lastLevel : null,
                  levelIsBeingIrrigated ? style.levelIsBeingIrrigated : null,
                ].join(' ')}
              >
                <div
                  className={[
                    style.levelFeederPipeContainer,
                    style.levelWaterOut,
                  ].join(' ')}
                >
                  <Pipe
                    className={style.levelFeederPipe}
                    waterIsFlowing={waterIsFlowingThroughOutFeederPipe}
                  />
                </div>

                <Pipe
                  className={style.levelOutPipe}
                  waterIsFlowing={levelIsBeingIrrigated}
                />

                <div
                  className={`${style.level} position-relative border text-light border${borderStatusClass}`}
                >
                  <div
                    className={`${style.levelTitle} position-absolute rounded-circle text-center bg${borderStatusClass}`}
                  >
                    {name}
                  </div>

                  <div className={style.levelSwitchContainer}>
                    <LevelSwitch
                      zoneName={zone.name}
                      stackName={stack.name}
                      levelName={level.name}
                      levelId={level.levelId}
                      levelMode={level.mode}
                      updateLevelModeCommand={updateLevelModeCommand}
                    />
                  </div>

                  {level.hasBeeSensor ? (
                    <BeeSensorDisplay
                      sensorValues={level.beeSensor?.currentValue}
                      zoneName={zone.name}
                      stackName={stack.name}
                      levelName={level.name}
                      levelId={level.levelId}
                    />
                  ) : (
                    <img
                      className={style.levelPlant}
                      src={
                        stack.mode === 'AUTOMATIC' && level.mode === 'AUTOMATIC'
                          ? plantsIconGreen
                          : plantsIconGray
                      }
                      alt=""
                    />
                  )}

                  <div className={style.sensorOptionsContainer}>
                    <LevelConfigureButton
                      facilityId={zone.facilityId}
                      zoneName={zone.name}
                      stackName={stack.name}
                      levelName={level.name}
                    />
                  </div>
                </div>

                <Pipe
                  className={style.levelInPipe}
                  waterIsFlowing={levelIsBeingIrrigated}
                />

                {updateLevelValveStateCommand.canExecute ? (
                  <LevelValveButton
                    zoneName={zone.name}
                    stackName={stack.name}
                    levelName={level.name}
                    levelId={level.levelId}
                    className={style.levelInValve}
                    levelValveState={level.valveState}
                    needsAttention={level.valveOpenFailureDetected}
                  />
                ) : (
                  <Valve
                    className={style.levelInValve}
                    valveState={level.valveState}
                    needsAttention={level.valveOpenFailureDetected}
                  />
                )}

                <Pipe
                  className={style.levelInPipe}
                  waterIsFlowing={levelIsBeingIrrigated}
                />

                <div
                  className={[
                    style.levelFeederPipeContainer,
                    style.levelWaterIn,
                  ].join(' ')}
                >
                  <Pipe
                    className={style.levelFeederPipe}
                    waterIsFlowing={waterIsFlowingThroughInFeederPipe}
                  />
                </div>
              </div>
            );
          })
          .reverse()}

        {stack.collectorTank.zone ? (
          <Link
            style={{ zIndex: 2 }}
            to={`/facility/${stack.collectorTank.zone?.facilityId}/zone/${zone.name}`}
          >
            <CollectorTank
              className={style.stackTank}
              zoneName={zone.name}
              collectorTank={stack.collectorTank}
              sensorsOnRight={false}
              hidePumpCommands={true}
            />
          </Link>
        ) : (
          <CollectorTank
            className={style.stackTank}
            zoneName={zone.name}
            collectorTank={stack.collectorTank}
            hidePumpCommands={false}
            sensorsOnRight={false}
          />
        )}
      </div>
    </div>
  );
};

Levels.stackFragment = gql`
  fragment LevelsStack on StackType {
    name
    stackId
    mode
    waterFlowDetected
    collectorTank {
      ...CollectorTank

      ... on ZoneAttachedCollectorTankType {
        zone {
          zoneId
          facilityId
        }
      }
    }
    levels {
      levelId
      name
      index
      mode
      hasBeeSensor
      beeSensor {
        serialNumber
        currentValue {
          ...LevelBeeSensorValues
        }
      }
      updateLevelModeCommand {
        canExecute
      }
      updateLevelValveStateCommand {
        canExecute
      }
      updateLevelBeeSensorConfigurationCommand {
        canExecute
      }
      valveState
      valveOpenFailureDetected
    }
    ...StackCommands
  }
  ${CollectorTank.fragment}
  ${LevelBeeSensorMeasurements.fragments.LevelBeeSensorCurrentValue}
  ${StackCommands.fragment}
`;

Levels.zoneFragment = gql`
  fragment LevelsZone on ZoneType {
    name
    facilityId
    tank {
      pumpState
    }
  }
`;

export default Levels;
