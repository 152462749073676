import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  zoneSupplyTankName: string;
  zoneSupplyTankId: string;
  onCompleted?: () => void;
}

interface Result {
  openReverseOsmosisZoneSupplyValveAutomaticModePrompt: () => void;
  openReverseOsmosisZoneSupplyValveMaintenanceModePrompt: () => void;
  ReverseOsmosisZoneSupplyValveModePrompts: typeof ReverseOsmosisZoneSupplyValveModePrompts;
  reverseOsmosisZoneSupplyValveModePromptsProps: Props;
}

const UPDATE_REVERSE_OSMOSIS_ZONE_SUPPLY_VALVE_MODE = gql`
  mutation updateReverseOsmosisZoneSupplyValveMode(
    $input: UpdateReverseOsmosisZoneSupplyValveModeInputType!
  ) {
    updateReverseOsmosisZoneSupplyValveMode(input: $input) {
      zoneDistributionTankValve {
        mode
      }
    }
  }
`;

interface Props extends Input {
  maintenanceModePromptIsOpen: boolean;
  automaticModePromptIsOpen: boolean;
  closeMaintenanceModePrompt: () => void;
  closeAutomaticModePrompt: () => void;
}

const ReverseOsmosisZoneSupplyValveModePrompts = ({
  zoneSupplyTankName,
  zoneSupplyTankId,
  automaticModePromptIsOpen,
  maintenanceModePromptIsOpen,
  closeAutomaticModePrompt,
  closeMaintenanceModePrompt,
  onCompleted,
}: Props) => {
  const [
    updateReverseOsmosisZoneSupplyValveMode,
    { error, loading: saving },
  ] = useMutation(UPDATE_REVERSE_OSMOSIS_ZONE_SUPPLY_VALVE_MODE, {
    onCompleted: () => {
      closeAutomaticModePrompt();
      closeMaintenanceModePrompt();
      onCompleted && onCompleted();
    },
  });

  return (
    <>
      <PromptModal
        title={`Put Reverse Osmosis Valve for Zone Supply Tank ${zoneSupplyTankName} into Automatic Mode`}
        isOpen={automaticModePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateReverseOsmosisZoneSupplyValveMode({
            variables: {
              input: { zoneSupplyId: zoneSupplyTankId, mode: 'AUTOMATIC' },
            },
          });
        }}
        onComplete={closeAutomaticModePrompt}
      >
        <p>
          {`Putting the reverse osmosis valve for Zone Supply Tank ${zoneSupplyTankName} into automatic mode will allow the
          reverse osmosis system to automatically fill the zone tank as needed.`}
        </p>

        <p>{`Put the reverse osmosis valve for Zone Supply Tank ${zoneSupplyTankName} into automatic mode?`}</p>
      </PromptModal>

      <PromptModal
        title={`Put Reverse Osmosis Valve for Zone Supply Tank ${zoneSupplyTankName} into Maintenance Mode`}
        isOpen={maintenanceModePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateReverseOsmosisZoneSupplyValveMode({
            variables: {
              input: {
                zoneSupplyId: zoneSupplyTankId,
                mode: 'MAINTENANCE',
              },
            },
          });
        }}
        onComplete={closeMaintenanceModePrompt}
      >
        <p>
          {`Putting the reverse osmosis valve for Zone Supply Tank ${zoneSupplyTankName} into maintenance mode will prevent
          the reverse osmosis system from automatically filling the zone tanks
          as needed.`}
        </p>

        <p>{`Put the reverse osmosis valve for Zone Supply Tank ${zoneSupplyTankName} into maintenance mode?`}</p>
      </PromptModal>
    </>
  );
};

const useReverseOsmosisZoneSupplyValveModePrompts: (input: Input) => Result = ({
  zoneSupplyTankName,
  zoneSupplyTankId,
  onCompleted,
}: Input) => {
  const [automaticModePromptIsOpen, setAutomaticModePromptIsOpen] = useState(
    false
  );

  const [
    maintenanceModePromptIsOpen,
    setMaintenanceModePromptIsOpen,
  ] = useState(false);

  return {
    openReverseOsmosisZoneSupplyValveAutomaticModePrompt: useCallback(
      () => setAutomaticModePromptIsOpen(true),
      [setAutomaticModePromptIsOpen]
    ),
    openReverseOsmosisZoneSupplyValveMaintenanceModePrompt: useCallback(
      () => setMaintenanceModePromptIsOpen(true),
      [setMaintenanceModePromptIsOpen]
    ),
    ReverseOsmosisZoneSupplyValveModePrompts: ReverseOsmosisZoneSupplyValveModePrompts,
    reverseOsmosisZoneSupplyValveModePromptsProps: {
      zoneSupplyTankName,
      zoneSupplyTankId,
      automaticModePromptIsOpen,
      maintenanceModePromptIsOpen,
      onCompleted,
      closeAutomaticModePrompt: useCallback(
        () => setAutomaticModePromptIsOpen(false),
        [setAutomaticModePromptIsOpen]
      ),
      closeMaintenanceModePrompt: useCallback(
        () => setMaintenanceModePromptIsOpen(false),
        [setMaintenanceModePromptIsOpen]
      ),
    },
  };
};

export default useReverseOsmosisZoneSupplyValveModePrompts;
