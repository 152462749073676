import React, { useState, useCallback, useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo';
import {
  FormGroup,
  Input as FormInput,
  Label,
  FormFeedback,
  Row,
  Col,
} from 'reactstrap';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import { getValidationErrors } from '../../modules/errors';
import { getInputValueAnd, updateState } from '../../modules/form-helpers';

import useModalVisible from '../../hooks/useModalVisible';

import ModalLoadingContainer from '../../components/ModalLoadingContainer';
import FormMultiFacilitySelector from '../../components/FormMultiFacilitySelector';
import ModalTitle from '../../components/ModalTitle';
import { SaveModal } from '../../components/SaveModal';

import style from './style.module.scss';

interface Input {
  userId: string;
  onCompleted?: () => void;
}

interface Result {
  openUserEditForm: () => void;
  UserEditForm: typeof UserEditForm;
  userEditFormProps: Props;
}

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInputType!) {
    updateUser(input: $input) {
      user {
        userId
      }
    }
  }
`;

const USER_QUERY = gql`
  query user($userId: ID!) {
    facilities {
      facilityId
      name
    }
    settings {
      userManagement {
        user(userId: $userId) {
          userId
          firstName
          lastName
          email
          role {
            roleId
          }
          facilities {
            facilityId
          }
        }

        roles {
          roleId
          name
          isAdministrator
        }
      }
    }
  }
`;

interface Props extends Input {
  userEditFormIsOpen: boolean;
  closeUserEditForm: () => void;
}

const UserEditForm = ({
  userId,
  userEditFormIsOpen,
  closeUserEditForm,
  onCompleted,
}: Props) => {
  const [visible, modalVisibleProps] = useModalVisible();

  const loadingResult = useQuery(USER_QUERY, {
    skip: !visible,
    variables: { userId },
  });

  const { data, loading, refetch } = loadingResult;

  const [updateUser, { error: saveError, loading: saving }] = useMutation(
    UPDATE_USER,
    {
      onCompleted: () => {
        closeUserEditForm();
        onCompleted && onCompleted();
      },
    }
  );

  const [userModifications, setUserModifications] = useState({
    roleId: '' as string,
    facilityIds: [] as Array<string>,
  });

  const [wasOpen, setWasOpen] = useState(false);
  const [saveAttempted, setSaveAttempted] = useState(false);

  const user = data?.settings?.userManagement?.user;

  useEffect(() => {
    const reopenedWithSameUser =
      userEditFormIsOpen && !wasOpen && user && user?.userId === userId;

    if (reopenedWithSameUser) {
      refetch();
    }

    setWasOpen(userEditFormIsOpen);
  }, [userEditFormIsOpen, user, userId, refetch, wasOpen, setWasOpen]);

  useEffect(() => {
    setUserModifications({
      roleId: user?.role?.roleId === undefined ? '' : user?.role.roleId,
      facilityIds:
        user?.facilities === undefined
          ? []
          : user?.facilities.map((x: { facilityId: string }) => x.facilityId),
    });
    setSaveAttempted(false);
  }, [user]);

  const roles = data?.settings?.userManagement?.roles || [];
  const availableFacilities = data?.facilities;

  const isAdministratorRole = (roleId: string) =>
    !!roles?.find(
      (x: { roleId: string; isAdministrator: boolean }) =>
        x.roleId === roleId && x.isAdministrator
    );

  const isDirtyState = useState(false);
  const [isDirty, setIsDirty] = isDirtyState;

  const validationErrors: any = {
    ...(isEmpty(userModifications.roleId) && { roleId: 'Role is required' }),
    ...(!isDirty && saveAttempted && getValidationErrors(saveError)),
  };

  const isFormValid = !loading && isEmpty(validationErrors);

  return (
    <SaveModal
      className={style.modal}
      title={
        <ModalTitle
          icon={<FontAwesomeIcon icon={faEdit} />}
          title="Edit User"
        />
      }
      isOpen={userEditFormIsOpen}
      isFormValid={isFormValid}
      saving={saving}
      error={saveError}
      isDirty={isDirty}
      onComplete={closeUserEditForm}
      onSave={() => {
        setIsDirty(false);
        setSaveAttempted(true);
        updateUser({
          variables: {
            input: {
              userId: user?.userId,
              roleId:
                userModifications.roleId !== '' &&
                userModifications.roleId !== user?.role?.roleId
                  ? userModifications.roleId
                  : undefined,
              facilityIds: userModifications.facilityIds,
            },
          },
        });
      }}
      {...modalVisibleProps}
    >
      <ModalLoadingContainer
        className={style.modalContentContainer}
        resourceTypeName="User"
        resourceExists={!!user}
        result={loadingResult}
      >
        <>
          <Row form>
            <Col sm={6}>
              <FormGroup>
                <Label for="firstName">First Name</Label>

                <FormInput
                  id="firstName"
                  type="text"
                  readOnly
                  value={user?.firstName}
                />
              </FormGroup>
            </Col>

            <Col sm={6}>
              <FormGroup>
                <Label for="lastName">Last Name</Label>

                <FormInput
                  id="lastName"
                  type="text"
                  readOnly
                  value={user?.lastName}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col sm={6}>
              <FormGroup>
                <Label for="email">Email Address / Username</Label>

                <FormInput
                  id="email"
                  type="email"
                  readOnly
                  value={user?.email}
                />
              </FormGroup>
            </Col>

            <Col sm={6}>
              <FormGroup>
                <Label for="role">Role</Label>

                <FormInput
                  id="role"
                  type="select"
                  className="custom-select"
                  value={userModifications.roleId}
                  onChange={getInputValueAnd(
                    updateState({
                      valueState: [
                        userModifications.roleId,
                        (value: string) =>
                          setUserModifications({
                            ...userModifications,
                            roleId: value,
                          }),
                      ],
                      isDirtyState,
                    })
                  )}
                  invalid={!!validationErrors.roleId}
                >
                  {userModifications.roleId === '' && (
                    <option value={''}></option>
                  )}

                  {roles.map(
                    ({ name, roleId }: { name: string; roleId: string }) => (
                      <option key={roleId} value={roleId}>
                        {name}
                      </option>
                    )
                  )}
                </FormInput>

                <FormFeedback invalid={validationErrors.roleId}>
                  {validationErrors.roleId}
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col sm={12}>
              <FormGroup>
                <Label for="facilityIds">Facilities</Label>

                <FormMultiFacilitySelector
                  id="facilityIds"
                  selectedFacilityIds={userModifications.facilityIds}
                  availableFacilities={availableFacilities}
                  isAdministratorRole={isAdministratorRole(
                    userModifications.roleId
                  )}
                  invalid={!!validationErrors.facilityIds}
                  onFacilityIdSelectionChange={({ facilityId, selected }) =>
                    updateState({
                      valueState: [
                        userModifications.facilityIds.includes(facilityId),
                        (value: boolean) =>
                          setUserModifications({
                            ...userModifications,
                            facilityIds: value
                              ? [...userModifications.facilityIds, facilityId]
                              : userModifications.facilityIds.filter(
                                  x => x !== facilityId
                                ),
                          }),
                      ],
                      isDirtyState,
                    })(selected)
                  }
                />

                <FormFeedback invalid={validationErrors.facilityIds}>
                  {validationErrors.facilityIds}
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </>
      </ModalLoadingContainer>
    </SaveModal>
  );
};

const useUserEditForm: (input: Input) => Result = ({ userId, onCompleted }) => {
  const [userEditFormIsOpen, setUserEditFormIsOpen] = useState(false);

  return {
    openUserEditForm: useCallback(() => setUserEditFormIsOpen(true), [
      setUserEditFormIsOpen,
    ]),
    UserEditForm,
    userEditFormProps: {
      userId,
      userEditFormIsOpen,
      onCompleted,
      closeUserEditForm: useCallback(() => setUserEditFormIsOpen(false), [
        setUserEditFormIsOpen,
      ]),
    },
  };
};

export default useUserEditForm;
