/*
  A form and form hook for providing a user with the means to create, read, update, and modify subzones.
 */

import React, { useState, useCallback, useEffect } from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';

import PromptModal from '../../components/PromptModal';
import ModalLoadingContainer from '../../components/ModalLoadingContainer';
import style from '../useModifySubzonesForm/style.module.scss';

import SubzoneLine from '../useModifySubzonesForm/components/SubzoneLine';
import { Option } from '../useModifySubzonesForm/components/SubzoneLine';
import CrudModal from '../../components/CrudModal';
import { ApolloError } from 'apollo-client/errors/ApolloError';

/* Definitions */

export interface Subzone {
  zoneSubzoneId: number;
  name: string;
  stacks: number[];
  isDeleted: boolean;
  color: string;
}

interface Result {
  openModifySubzonesPrompt: () => void;
  ModifySubzonesPrompt: typeof ModifySubzonesPrompt;
  modifySubzonesPromptProps: Props;
}

interface Input {
  facilityId: number;
  zoneName: string;
  zoneId: string;
  stacks: any;
  onCompleted?: () => void;
}

interface Props extends Input {
  modifySubzonesPromptIsOpen: boolean;
  closeModifySubzonesPrompt: () => void;
}

/* Global Methods */

//Constructor for Subzone.
export function newSubzone(id: number): Subzone {
  return {
    zoneSubzoneId: id,
    name: '',
    stacks: [],
    isDeleted: false,
    color: '',
  };
}

//An interface for Subzone comparisons.
function isEqual(self: Subzone, other: Subzone): boolean {
  return (
    String(self.color) === String(other.color) &&
    Boolean(self.isDeleted) === Boolean(other.isDeleted) &&
    String(self.name) === String(other.name) &&
    JSON.stringify(self.stacks) === JSON.stringify(other.stacks) &&
    Number(self.zoneSubzoneId) === Number(other.zoneSubzoneId)
  );
}

//Checks if a Subzone does not originate from the database.
export function isNew(id: number) {
  return id < 0; //Negative IDs are used to track uncommitted new items.
}

/* GQL Queries */

const GET_SUBZONES = gql`
  query readStacks($zoneId: ID!, $facilityId: ID!) {
    subzones(zoneId: $zoneId, facilityId: $facilityId) {
      zoneSubzoneId
      name
      stacks
      color
    }
    count: countSubzone(facilityId: $facilityId)
  }
`;

const SET_SUBZONES = gql`
  mutation SetSubzone($declare: DeclareSubzoneType!) {
    declareSubzone(input: $declare) {
      zoneId
      name
      stacks
    }
  }
`;

const DEL_SUBZONES = gql`
  mutation RemoveSubzone($remove: RemoveSubzoneType!) {
    removeSubzone(input: $remove) {
      zoneId
    }
  }
`;

/* React Components */

const ModifySubzonesPrompt = ({
  facilityId,
  zoneName,
  zoneId,
  stacks,
  modifySubzonesPromptIsOpen,
  closeModifySubzonesPrompt,
  onCompleted,
}: Props) => {
  const MAX_FACILITY_SUBZONES = 32;

  //GQL hooks.
  const [
    declareSubzone,
    { error: declareSubzoneError, loading: declareSubzoneLoading },
  ] = useMutation(SET_SUBZONES);

  const [
    removeSubzone,
    { error: removeSubzoneError, loading: removeSubzoneLoading },
  ] = useMutation(DEL_SUBZONES);

  const existingSubzonesStateHook = useQuery(GET_SUBZONES, {
    variables: { zoneId, facilityId },
  });

  //Transform stacks provided by parent into dropdown options.
  const stackOptions =
    stacks != null &&
    stacks.map(
      (i: any): Option => {
        return {
          label: i.name,
          value: i.stackId,
        };
      }
    );

  //Create local state for new item UID tracking, we will use negative numbers to track new items.
  const [uid, setUid] = useState(-1);

  //Create local state for metric on total subzones.
  const [subzoneTotal, setSubzoneTotal] = useState(0);

  //Create local state for subzones with a base case of null for bootstrap tracking.
  const [subzones, setSubzones] = useState<Subzone[] | null>(null);

  //Add the side effect of base-casing the local state when the prompt is toggled.
  useEffect(() => setSubzones(null), [modifySubzonesPromptIsOpen]);

  //Base state promotion.
  if (
    subzones == null &&
    !existingSubzonesStateHook.error &&
    existingSubzonesStateHook.data
  ) {
    //Set count metric.
    setSubzoneTotal(existingSubzonesStateHook.data.count);

    //Get server state or use a default state.
    const next = existingSubzonesStateHook.data.subzones ?? [];
    setSubzones(prev => [...(prev ?? []), ...next]);
  }

  //Callback for creating a subzone.
  const createSubzone = () => {
    //Stop React bouncing.
    if (subzones == null) return;

    //Create a new subzone using the current uid and set the next uid.
    let subzone = newSubzone(uid);
    setUid(uid - 1);
    setSubzoneTotal(subzoneTotal + 1);

    //Push new subzone into current local state.
    subzones.push(subzone);
    setSubzones([...subzones]);
  };

  //Callback for updating a subzone.
  const updateSubzone = (newState: any) => {
    //Stop React bouncing.
    if (subzones == null) return;

    //Match new state to current state by ID.
    for (let i = 0; i < subzones.length; i++) {
      if (subzones[i].zoneSubzoneId != newState.zoneSubzoneId) continue;

      //Ignore deleted uncommitted new states.
      if (newState.isDeleted && newState.zoneSubzoneId < 0) continue;

      //Insert new state into current state and update the current local state.
      subzones[i] = newState;
      setSubzones([...subzones]);

      //Short circuit when IDs match.
      break;
    }
  };

  //Callback for saving subzones.
  const saveSubzones = () => {
    //Stop React bouncing.
    if (subzones == null) return;

    //Map subzones to server-side actions.
    subzones.map(i => {
      //Separate locally deleted items into another branch.
      if (i.isDeleted) {
        //Remove subzone server-side.
        removeSubzone({
          variables: {
            remove: {
              zoneId: Number(zoneId),
              id: Number(i.zoneSubzoneId),
            },
          },
        }).then(() =>
          //Update the local state with the server state as soon as the entity is removed.
          existingSubzonesStateHook.refetch({ zoneId, facilityId })
        );
      } else {
        //Check if current mapping item is different from the server state.
        const isModified = () => {
          let isModified = false;

          //Check if any server state with the same ID is not equal to the local state.
          existingSubzonesStateHook.data.subzones
            .filter((j: Subzone) => j.zoneSubzoneId === i.zoneSubzoneId)
            .some((j: Subzone) => {
              isModified = !isEqual(j, i);
              return isModified; //This will short circuit on true.
            });

          return isModified;
        };

        //Upsert item server side if it is new or different from the last server-state.
        if (isNew(i.zoneSubzoneId) || isModified()) {
          declareSubzone({
            variables: {
              declare: {
                zoneId: Number(zoneId),
                id: Number(i.zoneSubzoneId),
                name: i.name,
                stacks: i.stacks,
                color: i.color,
              },
            },
          }).then(() =>
            //Update the local state with the server state as soon as the entity is upserted.
            existingSubzonesStateHook.refetch({ zoneId, facilityId })
          );
        }
      }
    });

    //Close and call parent.
    closeModifySubzonesPrompt();
    onCompleted && onCompleted();
  };

  return (
    <CrudModal
      title={`Subzones for Zone ${zoneName}`}
      isOpen={modifySubzonesPromptIsOpen}
      saving={false}
      error={undefined}
      onSave={saveSubzones}
      canCreate={subzoneTotal < MAX_FACILITY_SUBZONES}
      onComplete={isSave => {
        closeModifySubzonesPrompt();
      }}
      onCreate={createSubzone}
      saveButtonContent="Save"
      isFormValid={
        subzones != null &&
        subzones.length > 0 &&
        subzones.filter(i => i.stacks.length == 0 || i.name.length == 0)
          .length == 0
      }
    >
      <div className="row pb-2">
        <div className="col-2"></div>
        <div className="col-4 text-center">Name</div>
        <div className="col-5 text-center">Stacks</div>
      </div>
      {subzones != null &&
        subzones.length > 0 &&
        subzones.map((context: Subzone, i: number) => {
          let contextStacks: Option[] =
            stacks != null &&
            stacks
              .filter((stack: any) =>
                context.stacks.includes(Number(stack.stackId))
              )
              .map((item: any) => {
                return {
                  label: item.name,
                  value: item.stackId,
                };
              });
          return (
            <div key={i}>
              <SubzoneLine
                color={context.color}
                name={context.name}
                selectedStacks={contextStacks}
                id={context.zoneSubzoneId}
                onChange={updateSubzone}
                isDeleted={context.isDeleted}
                stackOptions={stackOptions}
              />
            </div>
          );
        })}
    </CrudModal>
  );
};

/* React Hooks */

const useModifySubzonesForm: (input: Input) => Result = ({
  facilityId,
  zoneName,
  zoneId,
  stacks,
  onCompleted,
}) => {
  const [modifySubzonesPromptIsOpen, setModifySubzonesPromptIsOpen] = useState(
    false
  );

  return {
    openModifySubzonesPrompt: useCallback(
      () => setModifySubzonesPromptIsOpen(true),
      [setModifySubzonesPromptIsOpen]
    ),
    ModifySubzonesPrompt: ModifySubzonesPrompt,
    modifySubzonesPromptProps: {
      facilityId,
      zoneName,
      stacks,
      zoneId: zoneId,
      modifySubzonesPromptIsOpen: modifySubzonesPromptIsOpen,
      onCompleted,
      closeModifySubzonesPrompt: useCallback(
        () => setModifySubzonesPromptIsOpen(false),
        [setModifySubzonesPromptIsOpen]
      ),
    },
  };
};

export default useModifySubzonesForm;
