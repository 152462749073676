import React, { useState, useCallback, useEffect } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { isEmpty, sortBy } from 'lodash';
import {
  Label,
  Input as FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/free-solid-svg-icons';

import { NutrientMixerMeasurementColors } from '../../modules/nutrient-mixer';

import { getValidationErrors } from '../../modules/errors';
import {
  getInputValueAnd,
  convertValue,
  updateState,
} from '../../modules/form-helpers';

import useModalVisible from '../useModalVisible';

import ModalLoadingContainer from '../../components/ModalLoadingContainer';
import ModalTitle from '../../components/ModalTitle';
import { SaveModal } from '../../components/SaveModal';

import style from './style.module.scss';

interface Input {
  zoneName: string;
  zoneId: string;
  onCompleted?: () => void;
}

interface Result {
  openZoneNutrientMixerConfigurePumpsForm: () => void;
  ZoneNutrientMixerConfigurePumpsForm: typeof ZoneNutrientMixerConfigurePumpsForm;
  zoneNutrientMixerConfigurePumpsFormProps: Props;
}

const zoneFragment = gql`
  fragment ZoneNutrientMixerConfigurePumpsForm on ZoneType {
    zoneId
    nutrientMixer {
      pumps {
        nutrientMixerPumpId
        name
        index
        isAcidResistant
        role {
          type

          ... on NutrientMixerPumpRoleEffectMeasurementsType {
            doseTimeSeconds
            waitTimeSeconds
          }

          ... on NutrientMixerPumpRoleTimedDoseType {
            doseTimeSeconds
            waitTimeSeconds
            timedDosePeriodMinutes
          }
        }
      }
    }
  }
`;

const UPDATE_NUTRIENT_MIXER_PUMP_CONFIGURATION = gql`
  mutation updateNutrientMixerPumpConfiguration(
    $input: UpdateNutrientMixerPumpConfigurationInputType!
  ) {
    updateNutrientMixerPumpConfiguration(input: $input) {
      zone {
        ...ZoneNutrientMixerConfigurePumpsForm
      }
    }
  }
  ${zoneFragment}
`;

const ZONE_QUERY = gql`
  query zone($zoneId: ID!) {
    zone(zoneId: $zoneId) {
      ...ZoneNutrientMixerConfigurePumpsForm
    }
  }
  ${zoneFragment}
`;

enum RoleType {
  Disabled = 'DISABLED',
  RaiseEc = 'RAISE_EC',
  RaisePh = 'RAISE_PH',
  LowerPh = 'LOWER_PH',
  TimedDose = 'TIMED_DOSE',
}

interface PumpConfiguration {
  pumpName: string;
  pumpIndex: number;
  type: RoleType;
  isAcidResistant: boolean;
  doseTimeSeconds?: number;
  waitTimeSeconds?: number;
  timedDosePeriodMinutes?: number;
}

interface Props extends Input {
  zoneNutrientMixerConfigurePumpsFormIsOpen: boolean;
  closeZoneNutrientMixerConfigurePumpsForm: () => void;
}
const typeKey = (index: number) => `pumpRoles[${index}].type`;
const doseTimeSecondsKey = (index: number) =>
  `pumpRoles[${index}].doesTimeSeconds`;
const waitTimeSecondsKey = (index: number) =>
  `pumpRoles[${index}].waitTimeSeconds`;
const timedDosePeriodMinutesKey = (index: number) =>
  `pumpRoles[${index}].timedDosePeriodMinutes`;

const getPumpColor = (type: RoleType) => {
  switch (type) {
    case RoleType.LowerPh:
    case RoleType.RaisePh:
      return NutrientMixerMeasurementColors.ph;
    case RoleType.RaiseEc:
      return NutrientMixerMeasurementColors.ec;
    default:
      return undefined;
  }
};

const parseTimeNumber = (x: string) => {
  const value = Number.parseInt(x);
  return Number.isNaN(value) ? undefined : value;
};

const getMinimumTimeDosePeriodMinutes = (configuration: PumpConfiguration) =>
  configuration.type !== RoleType.TimedDose
    ? undefined
    : configuration.doseTimeSeconds === undefined ||
      configuration.waitTimeSeconds === undefined
    ? undefined
    : Math.ceil(
        configuration.doseTimeSeconds / 60 + configuration.waitTimeSeconds / 60
      );

const ZoneNutrientMixerConfigurePumpsForm = ({
  zoneName,
  zoneId,
  zoneNutrientMixerConfigurePumpsFormIsOpen,
  closeZoneNutrientMixerConfigurePumpsForm,
  onCompleted,
}: Props) => {
  const [visible, modalVisibleProps] = useModalVisible();

  const result = useQuery(ZONE_QUERY, {
    variables: { zoneId },
    skip: !visible,
  });

  const [
    updateNutrientMixerPumpConfiguration,
    { error: saveError, loading: saving },
  ] = useMutation(UPDATE_NUTRIENT_MIXER_PUMP_CONFIGURATION, {
    onCompleted: () => {
      closeZoneNutrientMixerConfigurePumpsForm();
      onCompleted && onCompleted();
    },
  });

  const { refetch } = result;
  const [wasOpen, setWasOpen] = useState(false);
  const [configurations, setConfigurationsInner] = useState<Array<
    PumpConfiguration
  > | null>(null);

  const setConfigurations = (x: Array<PumpConfiguration> | null) =>
    setConfigurationsInner(x === null ? null : sortBy(x, y => y.pumpIndex));

  useEffect(() => {
    const reopened = zoneNutrientMixerConfigurePumpsFormIsOpen && !wasOpen;

    if (reopened) {
      refetch();
    }

    setWasOpen(zoneNutrientMixerConfigurePumpsFormIsOpen);
  }, [zoneNutrientMixerConfigurePumpsFormIsOpen, refetch, wasOpen, setWasOpen]);

  const { data, loading } = result;
  const zone = data?.zone || null;
  const nutrientMixer = zone?.nutrientMixer || null;

  const isDirtyState = useState(false);
  const [isDirty, setIsDirty] = isDirtyState;
  const [saveAttempted, setSaveAttempted] = useState(false);

  useEffect(() => {
    if (nutrientMixer) {
      if (!configurations) {
        setConfigurations(
          nutrientMixer.pumps.map((pump: any) => ({
            pumpName: pump.name,
            pumpIndex: pump.index,
            isAcidResistant: pump.isAcidResistant,
            type: pump.role.type,
            doseTimeSeconds: pump.role.doseTimeSeconds,
            waitTimeSeconds: pump.role.waitTimeSeconds,
            timedDosePeriodMinutes: pump.role.timedDosePeriodMinutes,
          }))
        );
      }
    } else {
      setConfigurations(null);
    }
  }, [nutrientMixer, configurations]);

  const validationErrors: any = {
    ...(configurations || []).reduce(
      (acc, configuration, i) => ({
        ...acc,
        ...(configuration.type !== RoleType.Disabled &&
        (!configuration.doseTimeSeconds || configuration.doseTimeSeconds <= 0)
          ? {
              [doseTimeSecondsKey(
                i
              )]: `${configuration.pumpName} dose time must be specified.`,
            }
          : {}),

        ...(configuration.type !== RoleType.Disabled &&
        (!configuration.waitTimeSeconds || configuration.waitTimeSeconds < 0)
          ? {
              [waitTimeSecondsKey(
                i
              )]: `${configuration.pumpName} flush time must be specified.`,
            }
          : {}),

        ...(configuration.type === RoleType.TimedDose &&
        (!configuration.timedDosePeriodMinutes ||
          configuration.timedDosePeriodMinutes * 60 <
            (configuration.doseTimeSeconds || 0) +
              (configuration.waitTimeSeconds || 0))
          ? {
              [timedDosePeriodMinutesKey(
                i
              )]: `${configuration.pumpName} schedule period must be greater than the sum of the dose and flush times.`,
            }
          : {}),
      }),
      {}
    ),

    ...(!isDirty &&
      saveAttempted &&
      saveError &&
      getValidationErrors(saveError)),
  };

  const isFormValid = !loading && isEmpty(validationErrors);

  (configurations || []).forEach(configuration => {
    const minimumTimeDosePeriodMinutes = getMinimumTimeDosePeriodMinutes(
      configuration
    );

    const adjustedTimedDosePeriodMinutes =
      minimumTimeDosePeriodMinutes === undefined
        ? undefined
        : configuration.timedDosePeriodMinutes === undefined
        ? minimumTimeDosePeriodMinutes
        : Math.max(
            minimumTimeDosePeriodMinutes,
            configuration.timedDosePeriodMinutes
          );

    configuration.timedDosePeriodMinutes !== adjustedTimedDosePeriodMinutes &&
      setConfigurations([
        ...configurations!.filter(x => x.pumpIndex !== configuration.pumpIndex),
        {
          ...configuration,
          timedDosePeriodMinutes: minimumTimeDosePeriodMinutes,
        },
      ]);
  });

  return (
    <SaveModal
      className={style.modal}
      scrollable
      title={
        <ModalTitle
          icon={<FontAwesomeIcon icon={faCogs} />}
          title={`Configure Zone ${zoneName} Nutrient Mixer Pumps`}
        />
      }
      isOpen={zoneNutrientMixerConfigurePumpsFormIsOpen}
      isFormValid={isFormValid}
      saving={saving}
      error={saveError}
      onComplete={closeZoneNutrientMixerConfigurePumpsForm}
      onSave={() => {
        setIsDirty(false);
        setSaveAttempted(true);
        updateNutrientMixerPumpConfiguration({
          variables: {
            input: {
              zoneId,
              pumpRoles: (configurations || []).map(
                ({
                  pumpIndex,
                  type,
                  doseTimeSeconds,
                  waitTimeSeconds,
                  timedDosePeriodMinutes,
                }) => ({
                  pumpIndex,
                  type,
                  doseTimeSeconds,
                  waitTimeSeconds,
                  timedDosePeriodMinutes,
                })
              ),
            },
          },
        });
      }}
      {...modalVisibleProps}
    >
      <ModalLoadingContainer
        className={style.pumpConfigurationsContainer}
        resourceTypeName="Zone"
        result={result}
        resourceExists={!!zone}
      >
        <>
          {(configurations || []).map((configuration, i) => {
            const minimumTimeDosePeriodMinutes = getMinimumTimeDosePeriodMinutes(
              configuration
            );

            return (
              <div
                className={[
                  style.pumpConfigurationContainer,
                  configuration.type !== RoleType.Disabled
                    ? style.enabled
                    : null,
                  configuration.type === RoleType.RaiseEc ? style.ec : null,
                  configuration.type === RoleType.RaisePh ||
                  configuration.type === RoleType.LowerPh
                    ? style.ph
                    : null,
                ].join(' ')}
                style={{
                  ['--pump-color' as any]: getPumpColor(configuration.type),
                }}
                key={configuration.pumpName}
              >
                <div className={style.pumpContainer}>
                  <span className={style.pumpName}>
                    {configuration.pumpName}
                  </span>

                  <FormInput
                    id={typeKey(i)}
                    type="select"
                    className="custom-select"
                    required
                    value={configuration.type}
                    onChange={getInputValueAnd(
                      convertValue(
                        (x: string) => x as RoleType,
                        updateState({
                          valueState: [
                            configuration.type,
                            (value: RoleType) =>
                              setConfigurations([
                                ...configurations!.filter(
                                  x => x.pumpIndex !== configuration.pumpIndex
                                ),
                                {
                                  ...configuration,
                                  type: value,
                                },
                              ]),
                          ],
                          isDirtyState,
                        })
                      )
                    )}
                  >
                    <option value={RoleType.Disabled}>Disabled</option>
                    <option value={RoleType.RaisePh}>↑ pH</option>
                    {configuration.isAcidResistant && (
                      <option value={RoleType.LowerPh}>↓ pH</option>
                    )}
                    <option value={RoleType.RaiseEc}>↑ EC</option>
                    <option value={RoleType.TimedDose}>Periodic</option>
                  </FormInput>
                </div>

                <div className={style.configurationValues}>
                  <FormGroup>
                    <Label for={doseTimeSecondsKey(i)}>Dose For</Label>

                    <InputGroup>
                      <FormInput
                        id={doseTimeSecondsKey(i)}
                        type="number"
                        min="1"
                        disabled={configuration.type === RoleType.Disabled}
                        value={configuration.doseTimeSeconds}
                        invalid={!!validationErrors[doseTimeSecondsKey(i)]}
                        onChange={getInputValueAnd(
                          convertValue(
                            parseTimeNumber,
                            updateState({
                              valueState: [
                                configuration.doseTimeSeconds,
                                value =>
                                  setConfigurations([
                                    ...configurations!.filter(
                                      x =>
                                        x.pumpIndex !== configuration.pumpIndex
                                    ),
                                    {
                                      ...configuration,
                                      doseTimeSeconds: value,
                                    },
                                  ]),
                              ],
                              isDirtyState,
                            })
                          )
                        )}
                      />

                      <InputGroupAddon addonType="append">
                        <InputGroupText>sec.</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <Label for={waitTimeSecondsKey(i)}>Then Flush For</Label>

                    <InputGroup>
                      <FormInput
                        id={waitTimeSecondsKey(i)}
                        type="number"
                        min="0"
                        disabled={configuration.type === RoleType.Disabled}
                        value={configuration.waitTimeSeconds}
                        invalid={!!validationErrors[waitTimeSecondsKey(i)]}
                        onChange={getInputValueAnd(
                          convertValue(
                            parseTimeNumber,
                            updateState({
                              valueState: [
                                configuration.waitTimeSeconds,
                                value =>
                                  setConfigurations([
                                    ...configurations!.filter(
                                      x =>
                                        x.pumpIndex !== configuration.pumpIndex
                                    ),
                                    {
                                      ...configuration,
                                      waitTimeSeconds: value,
                                    },
                                  ]),
                              ],
                              isDirtyState,
                            })
                          )
                        )}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>sec.</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <Label for={timedDosePeriodMinutesKey(i)}>
                      Schedule Every
                    </Label>

                    <InputGroup>
                      <FormInput
                        id={timedDosePeriodMinutesKey(i)}
                        type="number"
                        min={minimumTimeDosePeriodMinutes}
                        disabled={configuration.type !== RoleType.TimedDose}
                        value={configuration.timedDosePeriodMinutes}
                        invalid={
                          !!validationErrors[timedDosePeriodMinutesKey(i)]
                        }
                        onChange={getInputValueAnd(
                          convertValue(
                            parseTimeNumber,
                            updateState({
                              valueState: [
                                configuration.timedDosePeriodMinutes,
                                value =>
                                  setConfigurations([
                                    ...configurations!.filter(
                                      x =>
                                        x.pumpIndex !== configuration.pumpIndex
                                    ),
                                    {
                                      ...configuration,
                                      timedDosePeriodMinutes: value,
                                    },
                                  ]),
                              ],
                              isDirtyState,
                            })
                          )
                        )}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>min.</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </div>
              </div>
            );
          })}
        </>
      </ModalLoadingContainer>
    </SaveModal>
  );
};

const useZoneNutrientMixerConfigurePumpsForm: (input: Input) => Result = ({
  zoneName,
  zoneId,
  onCompleted,
}) => {
  const [
    zoneNutrientMixerConfigurePumpsFormIsOpen,
    setZoneNutrientMixerConfigurePumpsFormIsOpen,
  ] = useState(false);

  return {
    openZoneNutrientMixerConfigurePumpsForm: useCallback(
      () => setZoneNutrientMixerConfigurePumpsFormIsOpen(true),
      [setZoneNutrientMixerConfigurePumpsFormIsOpen]
    ),
    ZoneNutrientMixerConfigurePumpsForm,
    zoneNutrientMixerConfigurePumpsFormProps: {
      zoneName,
      zoneId,
      zoneNutrientMixerConfigurePumpsFormIsOpen,
      onCompleted,
      closeZoneNutrientMixerConfigurePumpsForm: useCallback(
        () => setZoneNutrientMixerConfigurePumpsFormIsOpen(false),
        [setZoneNutrientMixerConfigurePumpsFormIsOpen]
      ),
    },
  };
};

export default useZoneNutrientMixerConfigurePumpsForm;
