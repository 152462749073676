import gql from 'graphql-tag';
import { FRAGMENT_ZONE_COLLECTOR } from './fragmentZoneCollector';

export const GET_ZONE_COLLECTOR = gql`
  query zone($zoneId: ID!) {
    zone(zoneId: $zoneId) {
      ...CalibrateZoneCollectorTankForm
    }
  }
  ${FRAGMENT_ZONE_COLLECTOR}
`;
