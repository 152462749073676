import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  zoneName: string;
  stackName: string;
  stackId: string;
  onCompleted?: () => void;
}

interface Result {
  openStackValveTestPrompt: () => void;
  StackValveTestPrompt: typeof StackValveTestPrompt;
  stackValveTestPromptProps: Props;
}

export const PERFORM_STACK_VALVE_TEST = gql`
  mutation performStackValveTest($input: PerformStackValveTestInputType!) {
    performStackValveTest(input: $input) {
      stack {
        stackId
        performStackValveTestCommand {
          canExecute
        }
      }
    }
  }
`;

interface Props extends Input {
  stackValveTestPromptIsOpen: boolean;
  closeStackValveTestPrompt: () => void;
}

const StackValveTestPrompt = ({
  zoneName,
  stackName,
  stackId,
  stackValveTestPromptIsOpen,
  closeStackValveTestPrompt,
  onCompleted,
}: Props) => {
  const [stackValveTest, { error, loading: saving }] = useMutation(
    PERFORM_STACK_VALVE_TEST,
    {
      onCompleted: () => {
        closeStackValveTestPrompt();
        onCompleted && onCompleted();
      },
    }
  );

  return (
    <PromptModal
      title={`Perform Stack Valve Test for Zone ${zoneName} / Stack ${stackName}`}
      isOpen={stackValveTestPromptIsOpen}
      saving={saving}
      error={error}
      danger={true}
      onSave={() => {
        stackValveTest({
          variables: {
            input: {
              stackId,
            },
          },
        });
      }}
      onComplete={closeStackValveTestPrompt}
    >
      <p>
        Performing a stack valve test will interrupt the stack's irrigation
        while the test is in progress.
      </p>

      <p>{`Perform a stack valve test for Zone ${zoneName} / Stack ${stackName}?`}</p>
    </PromptModal>
  );
};

const useStackValveTestPrompt: (input: Input) => Result = ({
  zoneName,
  stackName,
  stackId,
  onCompleted,
}) => {
  const [stackValveTestPromptIsOpen, setStackValveTestPromptIsOpen] = useState(
    false
  );

  return {
    openStackValveTestPrompt: useCallback(
      () => setStackValveTestPromptIsOpen(true),
      [setStackValveTestPromptIsOpen]
    ),
    StackValveTestPrompt,
    stackValveTestPromptProps: {
      zoneName,
      stackName,
      stackId,
      stackValveTestPromptIsOpen,
      onCompleted,
      closeStackValveTestPrompt: useCallback(
        () => setStackValveTestPromptIsOpen(false),
        [setStackValveTestPromptIsOpen]
      ),
    },
  };
};

export default useStackValveTestPrompt;
