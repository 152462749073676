import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import FacilityIcon from '../../FacilityIcon';
import ModalCloseButton from '../../ModalCloseButton';
import style from './style.module.scss';

interface Facility {
  facilityId: number;
  name: string;
}

interface Props {
  facility?: Facility;
  facilities: Array<Facility>;
}

let cachedFacility: Facility | null = null;
let cachedFacilities: Array<Facility> = [];

const FacilityHeading = ({ facility, facilities }: Props) => {
  const [facilitySelectorIsOpen, setFacilitySelectorIsOpen] = useState(false);

  useEffect(() => {
    if (facility) {
      cachedFacility = facility;
    }
  }, [facility]);

  useEffect(() => {
    if (facilities?.length) {
      cachedFacilities = facilities;
    }
  }, [facilities]);

  const effectiveFacility = facility || cachedFacility;
  const effectiveFacilities = facilities?.length
    ? facilities
    : cachedFacilities;

  const hasMultipleFacilities = effectiveFacilities.length > 1;

  return (
    <div className={style.container}>
      <Modal
        className={style.facilitySelectorModal}
        isOpen={facilitySelectorIsOpen}
        toggle={() => setFacilitySelectorIsOpen(false)}
      >
        <ModalCloseButton onClick={() => setFacilitySelectorIsOpen(false)} />

        <ModalHeader>
          <FontAwesomeIcon icon={faGlobeAmericas} fixedWidth /> Select Facility
        </ModalHeader>

        <ModalBody>
          {effectiveFacilities.map(({ facilityId, name }) => (
            <h5 key={facilityId} className={style.facilityItem}>
              <Link
                to={{ pathname: `/facility/${facilityId}` }}
                onClick={() => setFacilitySelectorIsOpen(false)}
              >
                <FacilityIcon className={style.facilityIcon} />
                {name}
              </Link>
            </h5>
          ))}
        </ModalBody>
      </Modal>

      {effectiveFacility && (
        <>
          {hasMultipleFacilities ? (
            <button
              className="btn-link"
              onClick={() => setFacilitySelectorIsOpen(true)}
            >
              <div>
                <FacilityIcon /> {effectiveFacility.name}
              </div>
            </button>
          ) : (
            <Link
              to={{ pathname: `/facility/${effectiveFacility.facilityId}` }}
            >
              <div>
                <FacilityIcon /> {effectiveFacility.name}
              </div>
            </Link>
          )}
        </>
      )}
    </div>
  );
};

FacilityHeading.fragment = gql`
  fragment FacilityHeading on FacilityType {
    facilityId
    name
  }
`;

export default FacilityHeading;
