import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import LightScheduleComponent from '../../../../components/LightSchedule';

export const UPDATE_STACK_LIGHT_STATE = gql`
  mutation updateStackLightState($stackId: ID!, $state: LightState!) {
    updateStackLightState(stackId: $stackId, state: $state) {
      stackId
      light {
        isOn
        schedule {
          enabled
          activeTimeBlockIndex
          timeBlocks
        }
      }
    }
  }
`;

export const UPDATE_STACK_LIGHT_SCHEDULE = gql`
  mutation updateStackLightSchedule($stackId: ID!, $timeBlocks: [Boolean!]!) {
    updateStackLightSchedule(stackId: $stackId, timeBlocks: $timeBlocks) {
      stackId
      light {
        isOn
        schedule {
          activeTimeBlockIndex
          timeBlocks
        }
      }
    }
  }
`;

const LightSchedule = ({ stack }) => {
  const [updateStackLightState] = useMutation(UPDATE_STACK_LIGHT_STATE);
  const [updateStackLightSchedule] = useMutation(UPDATE_STACK_LIGHT_SCHEDULE);

  const { stackId, light } = stack;
  const { isOn: lightIsOn } = light;
  const {
    enabled: scheduleIsEnabled,
    timeBlocks,
    activeTimeBlockIndex,
    updateStackLightScheduleCommand,
  } = light.schedule;

  return (
    <LightScheduleComponent
      allowTimeBlockCollapse={true}
      scheduleIsEnabled={scheduleIsEnabled}
      lightIsOn={lightIsOn}
      onLightScheduleStateChange={
        updateStackLightScheduleCommand.canExecute
          ? lightState => {
              updateStackLightState({
                variables: { stackId, state: lightState },
              });
            }
          : undefined
      }
      timeBlocks={timeBlocks}
      activeTimeBlockIndex={activeTimeBlockIndex}
      onToggleTimeBlock={
        updateStackLightScheduleCommand.canExecute
          ? timeBlockIndex => {
              const updatedTimeBlocks = [...timeBlocks];
              updatedTimeBlocks[timeBlockIndex] = !updatedTimeBlocks[
                timeBlockIndex
              ];
              updateStackLightSchedule({
                variables: { stackId, timeBlocks: updatedTimeBlocks },
              });
            }
          : undefined
      }
      disabled={!updateStackLightScheduleCommand.canExecute}
    />
  );
};

LightSchedule.fragment = gql`
  fragment LightSchedule on StackType {
    light {
      schedule {
        enabled
        activeTimeBlockIndex
        timeBlocks
        updateStackLightScheduleCommand {
          canExecute
        }
      }
    }
  }
`;

export default LightSchedule;
