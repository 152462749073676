import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  zoneName: string;
  stackName: string;
  stackId: string;
  onCompleted?: () => void;
}

interface Result {
  openStackAttachedCollectorTankPumpAutomaticModePrompt: () => void;
  openStackAttachedCollectorTankPumpMaintenanceModePrompt: () => void;
  StackAttachedCollectorTankPumpModePrompts: typeof StackAttachedCollectorTankPumpModePrompts;
  stackAttachedCollectorTankPumpModePromptsProps: Props;
}

const UPDATE_STACK_ATTACHED_COLLECTOR_TANK_PUMP_MODE = gql`
  mutation updateStackAttachedCollectorTankPumpMode(
    $input: UpdateStackAttachedCollectorTankPumpModeInputType!
  ) {
    updateStackAttachedCollectorTankPumpMode(input: $input) {
      stack {
        stackId
        collectorTank {
          pumpMode
        }
      }
    }
  }
`;

interface Props extends Input {
  maintenanceModePromptIsOpen: boolean;
  automaticModePromptIsOpen: boolean;
  closeMaintenanceModePrompt: () => void;
  closeAutomaticModePrompt: () => void;
}

const StackAttachedCollectorTankPumpModePrompts = ({
  zoneName,
  stackName,
  stackId,
  automaticModePromptIsOpen,
  maintenanceModePromptIsOpen,
  closeAutomaticModePrompt,
  closeMaintenanceModePrompt,
  onCompleted,
}: Props) => {
  const [
    updateStackAttachedCollectorTankPumpMode,
    { error, loading: saving },
  ] = useMutation(UPDATE_STACK_ATTACHED_COLLECTOR_TANK_PUMP_MODE, {
    onCompleted: () => {
      closeAutomaticModePrompt();
      closeMaintenanceModePrompt();
      onCompleted && onCompleted();
    },
  });

  return (
    <>
      <PromptModal
        title={`Put the Return Tank Pump for Zone ${zoneName} / Stack ${stackName} Into Automatic Mode`}
        isOpen={automaticModePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateStackAttachedCollectorTankPumpMode({
            variables: {
              input: { stackId, mode: 'AUTOMATIC' },
            },
          });
        }}
        onComplete={closeAutomaticModePrompt}
      >
        <p>
          Putting the return tank pump into automatic mode will cause it to
          automatically flush the tank when the water level reaches a certain
          threshold.
        </p>
        <p>
          {`Are you sure you want to put the return tank pump for Zone ${zoneName} / Stack ${stackName} into automatic mode?`}
        </p>
      </PromptModal>

      <PromptModal
        title={`Put the Return Tank Pump for Zone ${zoneName} / Stack ${stackName} Into Maintenance Mode`}
        danger={true}
        isOpen={maintenanceModePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateStackAttachedCollectorTankPumpMode({
            variables: {
              input: { stackId, mode: 'MAINTENANCE' },
            },
          });
        }}
        onComplete={closeMaintenanceModePrompt}
      >
        <p>
          Putting the return tank pump into maintenance mode will stop the pump
          from running automatically, potentially causing tha tank to overflow
          if left unchecked.
        </p>
        <p>
          {`Are you sure you want to put the return tank pump for Zone ${zoneName} / Stack ${stackName} into maintenance mode?`}
        </p>
      </PromptModal>
    </>
  );
};

const useStackAttachedCollectorTankPumpModePrompts: (
  input: Input
) => Result = ({ zoneName, stackName, stackId, onCompleted }: Input) => {
  const [automaticModePromptIsOpen, setAutomaticModePromptIsOpen] = useState(
    false
  );

  const [
    maintenanceModePromptIsOpen,
    setMaintenanceModePromptIsOpen,
  ] = useState(false);

  return {
    openStackAttachedCollectorTankPumpAutomaticModePrompt: useCallback(
      () => setAutomaticModePromptIsOpen(true),
      [setAutomaticModePromptIsOpen]
    ),
    openStackAttachedCollectorTankPumpMaintenanceModePrompt: useCallback(
      () => setMaintenanceModePromptIsOpen(true),
      [setMaintenanceModePromptIsOpen]
    ),
    StackAttachedCollectorTankPumpModePrompts,
    stackAttachedCollectorTankPumpModePromptsProps: {
      zoneName,
      stackName,
      stackId,
      automaticModePromptIsOpen,
      maintenanceModePromptIsOpen,
      onCompleted,
      closeAutomaticModePrompt: useCallback(
        () => setAutomaticModePromptIsOpen(false),
        [setAutomaticModePromptIsOpen]
      ),
      closeMaintenanceModePrompt: useCallback(
        () => setMaintenanceModePromptIsOpen(false),
        [setMaintenanceModePromptIsOpen]
      ),
    },
  };
};

export default useStackAttachedCollectorTankPumpModePrompts;
