import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  zoneName: string;
  stackName: string;
  levelName: string;
  levelId: string;
  onCompleted?: () => void;
}

interface Result {
  openStackLevelAutomaticModePrompt: () => void;
  openStackLevelMaintenanceModePrompt: () => void;
  StackLevelValveModePrompts: typeof StackLevelValveModePrompts;
  stackLevelValveModePromptsProps: Props;
}

export const UPDATE_LEVEL_MODE = gql`
  mutation updateLevelMode($levelId: ID!, $mode: LevelMode!) {
    updateLevelMode(levelId: $levelId, mode: $mode) {
      levelId
      mode
    }
  }
`;

interface Props extends Input {
  maintenanceModePromptIsOpen: boolean;
  automaticModePromptIsOpen: boolean;
  closeMaintenanceModePrompt: () => void;
  closeAutomaticModePrompt: () => void;
}

const StackLevelValveModePrompts = ({
  zoneName,
  stackName,
  levelName,
  levelId,
  automaticModePromptIsOpen,
  maintenanceModePromptIsOpen,
  closeAutomaticModePrompt,
  closeMaintenanceModePrompt,
  onCompleted,
}: Props) => {
  const [updateStackLevelValveMode, { error, loading: saving }] = useMutation(
    UPDATE_LEVEL_MODE,
    {
      onCompleted: () => {
        closeAutomaticModePrompt();
        closeMaintenanceModePrompt();
        onCompleted && onCompleted();
      },
    }
  );

  return (
    <>
      <PromptModal
        title={`Put Zone ${zoneName} / Stack ${stackName} / Level ${levelName} Into Automatic Mode`}
        isOpen={automaticModePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateStackLevelValveMode({
            variables: {
              levelId,
              mode: 'AUTOMATIC',
            },
          });
        }}
        onComplete={closeAutomaticModePrompt}
      >
        <p>
          {`Putting Level ${levelName} into automatic mode will allow it to be irrigated according its irrigation schedule.`}
        </p>
        <p>
          {`Put Zone ${zoneName} / Stack ${stackName} / Level ${levelName} into automatic mode?`}
        </p>
      </PromptModal>

      <PromptModal
        title={`Put Zone ${zoneName} / Stack ${stackName} / Level ${levelName} Into Maintenance Mode`}
        isOpen={maintenanceModePromptIsOpen}
        saving={saving}
        error={error}
        danger={true}
        onSave={() => {
          updateStackLevelValveMode({
            variables: {
              levelId: levelId,
              mode: 'MAINTENANCE',
            },
          });
        }}
        onComplete={closeMaintenanceModePrompt}
      >
        <p>
          {`Putting Level ${levelName} into maintenance mode will prevent it from being irrigated according to its irrigation schedule.`}
        </p>
        <p>
          {`Put Zone ${zoneName} / Stack ${stackName} / Level ${levelName} into maintenance mode?`}
        </p>
      </PromptModal>
    </>
  );
};

const useStackLevelValveModePrompts: (input: Input) => Result = ({
  zoneName,
  stackName,
  levelName,
  levelId,
  onCompleted,
}: Input) => {
  const [automaticModePromptIsOpen, setAutomaticModePromptIsOpen] = useState(
    false
  );

  const [
    maintenanceModePromptIsOpen,
    setMaintenanceModePromptIsOpen,
  ] = useState(false);

  return {
    openStackLevelAutomaticModePrompt: useCallback(
      () => setAutomaticModePromptIsOpen(true),
      [setAutomaticModePromptIsOpen]
    ),
    openStackLevelMaintenanceModePrompt: useCallback(
      () => setMaintenanceModePromptIsOpen(true),
      [setMaintenanceModePromptIsOpen]
    ),
    StackLevelValveModePrompts,
    stackLevelValveModePromptsProps: {
      zoneName,
      stackName,
      levelName,
      levelId,
      automaticModePromptIsOpen,
      maintenanceModePromptIsOpen,
      onCompleted,
      closeAutomaticModePrompt: useCallback(
        () => setAutomaticModePromptIsOpen(false),
        [setAutomaticModePromptIsOpen]
      ),
      closeMaintenanceModePrompt: useCallback(
        () => setMaintenanceModePromptIsOpen(false),
        [setMaintenanceModePromptIsOpen]
      ),
    },
  };
};

export default useStackLevelValveModePrompts;
