import React from 'react';

import Loading from './Loading';
import PageError from './PageError';
import PageNotFound from './PageNotFound';

export default ({ result, resourceTypeName, resourceExists, render }) => {
  return result.loading ? (
    <Loading />
  ) : result.error ? (
    <PageError error={result.error} />
  ) : !resourceExists ? (
    <PageNotFound resourceTypeName={resourceTypeName} />
  ) : (
    render()
  );
};
