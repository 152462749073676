import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  zoneName: string;
  nutrientMixerPumpName: string;
  nutrientMixerPumpId: string;
  onCompleted?: () => void;
}

interface Result {
  openNutrientMixerPumpEnableAcidResistancePrompt: () => void;
  openNutrientMixerPumpDisableAcidResistancePrompt: () => void;
  NutrientMixerPumpConfigureAcidResistancePrompts: typeof NutrientMixerPumpConfigureAcidResistancePrompts;
  nutrientMixerPumpConfigureAcidResistancePromptsProps: Omit<
    Props,
    'nutrientMixerPumpConfigureAcidResistance'
  >;
}

export const ENABLE_NUTRIENT_MIXER_PUMP_ACID_RESISTANCE = gql`
  mutation enableNutrientMixerPumpAcidResistance(
    $input: EnableNutrientMixerPumpAcidResistanceInputType!
  ) {
    enableNutrientMixerPumpAcidResistance(input: $input) {
      pump {
        nutrientMixerPumpId
        isAcidResistant
      }
    }
  }
`;

export const DISABLE_NUTRIENT_MIXER_PUMP_ACID_RESISTANCE = gql`
  mutation disableNutrientMixerPumpAcidResistance(
    $input: DisableNutrientMixerPumpAcidResistanceInputType!
  ) {
    disableNutrientMixerPumpAcidResistance(input: $input) {
      pump {
        nutrientMixerPumpId
        isAcidResistant
      }
    }
  }
`;

interface Props extends Input {
  enableAcidResistancePromptIsOpen: boolean;
  disableAcidResistancePromptIsOpen: boolean;
  closeEnableAcidResistancePrompt: () => void;
  closeDisableAcidResistancePrompt: () => void;
}

const NutrientMixerPumpConfigureAcidResistancePrompts = ({
  zoneName,
  nutrientMixerPumpName,
  nutrientMixerPumpId,
  enableAcidResistancePromptIsOpen,
  disableAcidResistancePromptIsOpen,
  closeEnableAcidResistancePrompt,
  closeDisableAcidResistancePrompt,
  onCompleted,
}: Props) => {
  const [
    enableNutrientMixerPumpAcidResistance,
    {
      error: errorEnableNutrientMixerPumpAcidResistance,
      loading: savingEnableNutrientMixerPumpAcidResistance,
    },
  ] = useMutation(ENABLE_NUTRIENT_MIXER_PUMP_ACID_RESISTANCE, {
    onCompleted: () => {
      closeEnableAcidResistancePrompt();
      closeDisableAcidResistancePrompt();
      onCompleted && onCompleted();
    },
  });

  const [
    disableNutrientMixerPumpAcidResistance,
    {
      error: errorDisableNutrientMixerPumpAcidResistance,
      loading: savingDisableNutrientMixerPumpAcidResistance,
    },
  ] = useMutation(DISABLE_NUTRIENT_MIXER_PUMP_ACID_RESISTANCE, {
    onCompleted: () => {
      closeEnableAcidResistancePrompt();
      closeDisableAcidResistancePrompt();
      onCompleted && onCompleted();
    },
  });

  return (
    <>
      <PromptModal
        title={`Enable Acid Resistance for Zone ${zoneName} / Nutrient Mixer Pump ${nutrientMixerPumpName}`}
        danger
        isOpen={enableAcidResistancePromptIsOpen}
        saving={savingEnableNutrientMixerPumpAcidResistance}
        error={errorEnableNutrientMixerPumpAcidResistance}
        onSave={() => {
          enableNutrientMixerPumpAcidResistance({
            variables: {
              input: { nutrientMixerPumpId },
            },
          });
        }}
        onComplete={closeEnableAcidResistancePrompt}
      >
        <p>
          Enabling acid resistance will allow the pump to be used to lower pH
          levels. Physically verify that the pump is equipped to be acid
          resistant.
        </p>

        <p>
          Enable acid resistance for Zone {zoneName} / Nutrient Mixer Pump{' '}
          {nutrientMixerPumpName}?
        </p>
      </PromptModal>

      <PromptModal
        title={`Disable Acid Resistance for Zone ${zoneName} / Nutrient Mixer Pump ${nutrientMixerPumpName}`}
        isOpen={disableAcidResistancePromptIsOpen}
        saving={savingDisableNutrientMixerPumpAcidResistance}
        error={errorDisableNutrientMixerPumpAcidResistance}
        onSave={() => {
          disableNutrientMixerPumpAcidResistance({
            variables: {
              input: { nutrientMixerPumpId },
            },
          });
        }}
        onComplete={closeDisableAcidResistancePrompt}
      >
        <p>
          Disabling acid resistance will prevent the pump from being used to
          lower pH levels.
        </p>

        <p>
          Disable acid resistance for Zone {zoneName} / Nutrient Mixer Pump{' '}
          {nutrientMixerPumpName}?
        </p>
      </PromptModal>
    </>
  );
};

const useNutrientMixerPumpConfigureAcidResistancePrompts: (
  input: Input
) => Result = ({
  zoneName,
  nutrientMixerPumpName,
  nutrientMixerPumpId,
  onCompleted,
}: Input) => {
  const [
    enableAcidResistancePromptIsOpen,
    setEnableAcidResistancePromptIsOpen,
  ] = useState(false);

  const [
    disableAcidResistancePromptIsOpen,
    setDisableAcidResistancePromptIsOpen,
  ] = useState(false);

  return {
    openNutrientMixerPumpEnableAcidResistancePrompt: useCallback(
      () => setEnableAcidResistancePromptIsOpen(true),
      [setEnableAcidResistancePromptIsOpen]
    ),
    openNutrientMixerPumpDisableAcidResistancePrompt: useCallback(
      () => setDisableAcidResistancePromptIsOpen(true),
      [setDisableAcidResistancePromptIsOpen]
    ),
    NutrientMixerPumpConfigureAcidResistancePrompts,
    nutrientMixerPumpConfigureAcidResistancePromptsProps: {
      zoneName,
      nutrientMixerPumpName,
      nutrientMixerPumpId,
      enableAcidResistancePromptIsOpen,
      disableAcidResistancePromptIsOpen,
      onCompleted,
      closeEnableAcidResistancePrompt: useCallback(
        () => setEnableAcidResistancePromptIsOpen(false),
        [setEnableAcidResistancePromptIsOpen]
      ),
      closeDisableAcidResistancePrompt: useCallback(
        () => setDisableAcidResistancePromptIsOpen(false),
        [setDisableAcidResistancePromptIsOpen]
      ),
    },
  };
};

export default useNutrientMixerPumpConfigureAcidResistancePrompts;
