import React, { useCallback, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock,
  faLightbulb,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import useReferredState from '../../hooks/useReferredState';

import { useFocus } from '../../modules/form-helpers';

import style from './style.module.scss';

interface Props {
  id: string;
  height: number;
  width: number;
  handleDiameter?: number;
  state: number;
  className?: string;
  disabled?: boolean;
  onChange?: (state: number, id: string) => void;
}

const TriStateSwitch = ({
  height,
  width,
  handleDiameter,
  state,
  id,
  className,
  disabled = false,
  onChange,
}: Props) => {
  const handleWidth = handleDiameter ?? height - 2;
  const handleHeight = Math.min(handleWidth, height - 2);

  const offset = Math.max(0, (height - handleHeight) / 2);

  const pos3 = width - handleWidth - offset;
  const pos2 = Math.max(width / 3, width / 2 - handleWidth / 2);
  const pos1 = Math.max(0, (height - handleWidth) / 2) + offset;

  const getPositionFromState = useCallback(
    (state: number) => {
      switch (state) {
        case 1:
          return pos1;
        case 2:
          return pos2;
        case 3:
          return pos3;
        default:
          return pos1;
      }
    },
    [pos1, pos2, pos3]
  );

  const getStateFromPosition = (position: number): number => {
    if (position < width / 6) {
      return 1;
    }
    if (position < width / 2) {
      return 2;
    }
    return 3;
  };

  const inverseLerp = (a: number, b: number, t: number) => {
    const lerp = (t - a) / (b - a);
    return Math.min(Math.max(0, lerp), 1);
  };

  const { inputElement, focusElement } = useFocus();

  const [switchState, switchStateRef, setSwitchState] = useReferredState({
    position: 0,
    startX: 0,
    lastDragAt: 0,
    lastKeyUpAt: 0,
    hasOutline: false,
    isDragging: false,
  });

  useEffect(() => {
    setSwitchState({
      ...switchStateRef.current,
      position: getPositionFromState(state),
    });
  }, [state, setSwitchState, switchStateRef, getPositionFromState]);

  const onDragStart = (clientX: number) => {
    focusElement();

    setSwitchState({
      ...switchStateRef.current,
      startX: clientX,
      hasOutline: true,
    });
  };

  const onDrag = (clientX: any) => {
    const { isDragging, startX, position } = switchStateRef.current;
    const startPosition = getPositionFromState(state);
    const mousePosition = startPosition + clientX - startX;

    if (!isDragging && clientX !== startX) {
      setSwitchState({
        ...switchStateRef.current,
        isDragging: true,
      });
    }

    const newPosition = Math.min(
      getPositionFromState(3),
      Math.max(getPositionFromState(1), mousePosition)
    );

    if (newPosition !== position) {
      setSwitchState({
        ...switchStateRef.current,
        position: newPosition,
      });
    }
  };

  const onDragStop = (event: any) => {
    const endPosition = switchStateRef.current.position;

    const previousPosition = getPositionFromState(state);
    setSwitchState({
      ...switchStateRef.current,
      position: previousPosition,
    });

    const newState = getStateFromPosition(endPosition);
    const hasDraggedToNewState = newState !== state;
    if (hasDraggedToNewState) {
      onSwitchChange(newState);
    }

    setSwitchState({
      ...switchStateRef.current,
      isDragging: false,
      hasOutline: false,
      lastDragAt: Date.now(),
    });
  };

  const onMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();

    if (typeof event.button === 'number' && event.button !== 0) {
      return;
    }

    onDragStart(event.clientX);

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  };

  const onMouseMove = (event: MouseEvent) => {
    event.preventDefault();

    onDrag(event.clientX);
  };

  const onMouseUp = (event: MouseEvent) => {
    onDragStop(event);

    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);
  };

  const onTouchStart = (event: React.TouchEvent) => {
    onDragStart(event.touches[0].clientX);
  };

  const onTouchMove = (event: React.TouchEvent) => {
    onDrag(event.touches[0].clientX);
  };

  const onTouchEnd = (event: React.TouchEvent) => {
    event.preventDefault();

    onDragStop(event);
  };

  const onSwitchChange = (newState: number) => {
    onChange && onChange(newState, id);
  };

  const rootStyle = {
    opacity: disabled ? 0.5 : 1,
  };

  const backgroundStyle = {
    height,
    width,
    position: 'relative',
    background: '#232221',
    borderRadius: '3.5px',
    transition: 'background 0.25s',
  } as React.CSSProperties;

  const handleStyle = {
    height: handleHeight,
    width: handleWidth,
    background: '#5c5c5c',
    display: 'inline-block',
    cursor: disabled ? 'default' : 'pointer',
    position: 'absolute',
    transform: `translateX(${switchState.position}px)`,
    top: Math.max(0, (height - Math.min(handleWidth, height - 2)) / 2),
    outline: 0,
    boxShadow: switchState.hasOutline
      ? '0 0 2px 3px #3bf'
      : '0 0 1px 2px rgb(0 0 0 / 10%)',
    border: 0,
    transition: switchState.isDragging ? null : 'transform 0.25s',
    zIndex: 2,
    borderRadius: '2px',
  } as React.CSSProperties;

  const baseIconStyle = {
    height: height,
    width: width / 3,
    padding: '5%',
    position: 'absolute',
    color: 'white',
    top: 0,
    cursor: disabled ? 'default' : 'pointer',
    zIndex: 3,
    transition: switchState.isDragging ? null : 'opacity 0.25s',
  };

  const scheduleIconStyle = {
    ...baseIconStyle,
    left: offset,
    pointerEvents: state === 1 ? 'none' : 'auto',
    opacity:
      inverseLerp(
        getPositionFromState(2),
        getPositionFromState(1),
        switchState.position
      ) + 0.25,
  } as React.CSSProperties;

  const lightOnIconStyle = {
    ...baseIconStyle,
    left: width / 3,
    pointerEvents: state === 2 ? 'none' : 'auto',
    opacity:
      inverseLerp(
        Math.abs(getPositionFromState(2) - getPositionFromState(1)),
        0,
        Math.abs(getPositionFromState(2) - switchState.position)
      ) + 0.25,
  } as React.CSSProperties;

  const lightOffIconStyle = {
    ...baseIconStyle,
    right: offset,
    pointerEvents: state === 3 ? 'none' : 'auto',
    opacity:
      inverseLerp(
        getPositionFromState(2),
        getPositionFromState(3),
        switchState.position
      ) + 0.25,
  } as React.CSSProperties;

  return (
    <div style={rootStyle} className={[style.rootClass, className].join(' ')}>
      <div
        className={style.switchBg}
        style={backgroundStyle}
        onMouseDown={e => e.preventDefault()}
      >
        <FontAwesomeIcon
          icon={faClock}
          style={scheduleIconStyle}
          onClick={() => (disabled ? undefined : onSwitchChange(1))}
        />
        <FontAwesomeIcon
          icon={faLightbulb}
          style={lightOnIconStyle}
          onClick={() => (disabled ? undefined : onSwitchChange(2))}
        />
        <FontAwesomeIcon
          icon={faTimes}
          style={lightOffIconStyle}
          onClick={() => (disabled ? undefined : onSwitchChange(3))}
        />
      </div>
      <div
        className={style.switchHandle}
        style={handleStyle}
        onClick={e => e.preventDefault()}
        onMouseDown={disabled ? undefined : onMouseDown}
        onTouchStart={disabled ? undefined : onTouchStart}
        onTouchMove={disabled ? undefined : onTouchMove}
        onTouchEnd={disabled ? undefined : onTouchEnd}
        ref={inputElement}
      ></div>
    </div>
  );
};

export default TriStateSwitch;
