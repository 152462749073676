import React from 'react';

import UnknownValueOverlay from '../UnknownValueOverlay';

import style from './style.module.scss';

interface Props {
  waterIsFlowing?: boolean;
  className?: string;
  unknownIconClassName?: string;
}

export default ({ waterIsFlowing, className, unknownIconClassName }: Props) => {
  const hasUnknownValue =
    waterIsFlowing === null || waterIsFlowing === undefined;

  return (
    <UnknownValueOverlay
      hasUnknownValue={hasUnknownValue}
      className={[
        style.container,
        waterIsFlowing ? style.waterIsFlowing : null,
        hasUnknownValue ? style.hasUnknownValue : null,
        className,
      ].join(' ')}
      iconClassName={[style.unknownIcon, unknownIconClassName].join(' ')}
    >
      <svg
        className={style.icon}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enableBackground="new 0 0 100 100"
      >
        <g>
          <path d="M14,45.4c2.1-16.4,15-29.4,31.4-31.4V5C24.1,7.1,7.1,24.1,5,45.4H14z"></path>
          {/* <path d="M54.6,14c16.4,2.1,29.4,15,31.4,31.4h9C92.9,24.1,75.9,7.1,54.6,5V14z"></path> */}
          {/* <path d="M45.4,86c-16.4-2.1-29.4-15-31.4-31.4H5C7.1,75.9,24.1,92.9,45.4,95V86z"></path> */}
          <path d="M86,54.6c-2.1,16.4-15,29.4-31.4,31.4v9C75.9,92.9,92.9,75.9,95,54.6H86z"></path>
        </g>
      </svg>
    </UnknownValueOverlay>
  );
};
