import React, { useState, FC } from 'react';
import { useRouteMatch } from 'react-router';
import { Button, Input, InputGroup, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import AsyncSelect from 'react-select/async';
import DatePicker from 'react-datepicker';
import * as H from 'history';

import Loading from '../../../../Loading';
import PageError from '../../../../PageError';

import {
  getInputValueAnd,
  useWaitForDelay,
} from '../../../../../modules/form-helpers';
import { AUDIT_LOG_QUERY } from '../../../gql/getAuditLog';
import { USERS_QUERY } from '../../../gql/getUsers';
import { ActivityItem } from './ActivityItem';
import { MatchParams, QueryInput, User } from '../../../types/index';

import 'react-datepicker/dist/react-datepicker.css';
import style from './style.module.scss';

const SEARCH_DELAY_MS = 500;

interface Props {
  className: string;
  location?: H.Location;
}

const ActivityList: FC<Props> = ({ className }: Props): JSX.Element => {
  const zoneMatch = useRouteMatch<MatchParams>({
    path: '/facility/:facilityId/zone/:zoneName',
  });
  const stackMatch = useRouteMatch<MatchParams>({
    path: '/facility/:facilityId/zone/:zoneName/stack/:stackName',
  });

  const locationString = () => {
    if (stackMatch) {
      const zoneName = stackMatch.params.zoneName;
      const stackName = stackMatch.params.stackName;
      return `Zone ${zoneName} / Stack ${stackName}`;
    }
    if (zoneMatch) {
      const zoneName = zoneMatch.params.zoneName;
      return `Zone ${zoneName}`;
    }
    return null;
  };

  const waitForSearchDelay = useWaitForDelay<string>(SEARCH_DELAY_MS);

  const [search, setSearch] = useState('');
  const [beforeDate, setBeforeDate] = useState(new Date());
  const [afterDate, setAfterDate] = useState(new Date());
  const [useBeforeDate, setUseBeforeDate] = useState(false);
  const [useAfterDate, setUseAfterDate] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [includeThisLocationOnly, setIncludeThisLocationOnly] = useState(false);
  const [queryInput, setQueryInput] = useState<QueryInput>({
    userId: null,
    upperBoundTimestampUtc: null,
    lowerBoundTimestampUtc: null,
    zoneName: null,
    stackName: null,
    includeSystemEvents: false,
    eventTypeSearch: null,
  });

  const client = useApolloClient();
  const loadUserOptions = async (inputValue: string): Promise<any[]> => {
    const { data } = await client.query({
      query: USERS_QUERY,
      variables: {
        input: {
          search: inputValue,
          sortOrder: 'LAST_NAME_ASCENDING',
        },
      },
    });
    const availableUsers = data?.settings?.userManagement?.users?.edges;
    const userOptions = availableUsers?.map(
      ({ node: user }: { node: User }) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.userId,
      })
    );

    return userOptions;
  };

  const { loading, error, data, refetch, fetchMore } = useQuery(
    AUDIT_LOG_QUERY,
    {
      variables: { input: queryInput, after: '' },
    }
  );

  const { auditLog } = data || {};
  const serverTimeOffset =
    data && moment(data.currentTimestamp).diff(new Date());

  const currentServerDate = () =>
    serverTimeOffset
      ? moment().add(serverTimeOffset, 'milliseconds')
      : moment();

  const updateSearch = (value: string) => {
    setSearch(value);

    if (value.trim() !== (queryInput.eventTypeSearch || '').trim()) {
      waitForSearchDelay(refetchWithSearch)(value);
    }
  };

  const refetchWithSearch = (value: string) => {
    refetchWithUpdatedQueryInput({
      key: 'eventTypeSearch',
      value: value,
    });
  };

  const refetchWithToggleIncludeSystemEvents = () =>
    refetchWithUpdatedQueryInput({
      key: 'includeSystemEvents',
      value: !queryInput.includeSystemEvents,
    });

  const refetchWithToggleBeforeDate = () => {
    setUseBeforeDate(!useBeforeDate);
    refetchWithBeforeDate(!useBeforeDate ? beforeDate : null);
  };

  const refetchWithToggleAfterDate = () => {
    setUseAfterDate(!useAfterDate);
    refetchWithAfterDate(!useAfterDate ? afterDate : null);
  };

  const refetchWithBeforeDate = (beforeDate?: Date | null) =>
    refetchWithUpdatedQueryInput({
      key: 'upperBoundTimestampUtc',
      value:
        beforeDate !== null
          ? moment(beforeDate)
              .add(1, 'days')
              .startOf('day')
              .utc()
              .toISOString()
          : null,
    });

  const refetchWithAfterDate = (afterDate?: Date | null) =>
    refetchWithUpdatedQueryInput({
      key: 'lowerBoundTimestampUtc',
      value:
        afterDate !== null
          ? moment(afterDate)
              .add(-1, 'days')
              .endOf('day')
              .utc()
              .toISOString()
          : null,
    });

  const refetchWithToggleShowLocationEventsOnly = () => {
    setIncludeThisLocationOnly(!includeThisLocationOnly);
    if (!includeThisLocationOnly) {
      if (stackMatch) {
        const newQueryInput = {
          ...queryInput,
          zoneName: stackMatch.params.zoneName,
          stackName: stackMatch.params.stackName,
        };
        refetch({ input: newQueryInput, after: '' });
        setQueryInput(newQueryInput);
      } else if (zoneMatch) {
        const newQueryInput = {
          ...queryInput,
          zoneName: zoneMatch.params.zoneName,
          stackName: null,
        };
        refetch({ input: newQueryInput, after: '' });
        setQueryInput(newQueryInput);
      }
    } else {
      const newQueryInput = {
        ...queryInput,
        zoneName: null,
        stackName: null,
      };
      refetch({ input: newQueryInput, after: '' });
      setQueryInput(newQueryInput);
    }
  };

  const refetchWithUpdatedQueryInput = <Key extends keyof QueryInput>({
    key,
    value,
  }: {
    key: Key | null;
    value: QueryInput[Key];
  }) => {
    if (key === null) return;

    const newQueryInput = {
      ...queryInput,
    };
    newQueryInput[key] = value;

    setQueryInput(newQueryInput);
    refetch({ input: newQueryInput, after: '' });
  };

  const selectStyles = (height = 'calc(1.5em + .75rem + 2px)') => ({
    control: (provided: any) => ({
      ...provided,
      width: '100%',
      height: height,
      minHeight: height,
      marginBottom: '0.5em',
      flexWrap: 'no-wrap',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      height: height,
      minHeight: height,
      paddingLeft: '1em',
    }),
    menu: (provided: any) => ({
      ...provided,
      marginTop: 0,
      color: '#2b2b2b',
    }),
    option: (provided: any) => ({
      ...provided,
      height: height,
      minHeight: height,
      display: 'flex',
      alignItems: 'center',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      height: height,
      minHeight: height,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: '#495057',
      margin: 0,
    }),
  });

  const isRefreshing = loading && !loadingMore;
  const hasError = !loading && error;
  const hasAuditLog = !isRefreshing && !error && !!auditLog;
  const hasNextPage = hasAuditLog && !!auditLog.pageInfo.hasNextPage;

  return (
    <div
      className={`
      ${style.container} ${className}`}
    >
      <div className={style.heading}>
        <h6 className={style.title}>
          <FontAwesomeIcon icon={faBell} fixedWidth={true} /> Activity
        </h6>
        <h6 className={style.refresh}>
          <FontAwesomeIcon
            icon={faSyncAlt}
            fixedWidth={true}
            onClick={() => refetch({ input: queryInput, after: '' })}
          />
        </h6>
      </div>

      <div className={style.filterContainer}>
        <span className={style.sectionTitle}>User</span>
        <AsyncSelect
          isClearable
          isSearchable
          cacheOptions
          defaultOptions
          placeholder="Search for a user..."
          className={style.userSelect}
          styles={selectStyles()}
          loadOptions={loadUserOptions}
          onChange={e => {
            refetchWithUpdatedQueryInput({
              key: 'userId',
              value: e?.value,
            });
          }}
        />

        <span className={style.sectionTitle}>Action</span>
        <InputGroup className={style.searchInput}>
          <Input
            type="search"
            value={search}
            onChange={getInputValueAnd(updateSearch)}
            placeholder="Search by event type..."
          />
        </InputGroup>

        <span className={style.sectionTitle}>Date</span>
        <div className={style.dateInputsContainer}>
          <div className={style.dateContainer}>
            <div className={style.checkboxContainer}>
              <Input
                id="useAfterDate"
                addon
                type="checkbox"
                checked={useAfterDate}
                onChange={refetchWithToggleAfterDate}
              />

              <Label for="useAfterDate">On Or After</Label>
            </div>
            <DatePicker
              selected={afterDate}
              onChange={(date: Date) => {
                setAfterDate(date);
                refetchWithAfterDate(date);
              }}
              className={style.dateInput}
              disabled={!useAfterDate}
            />
          </div>

          <div className={style.dateContainer}>
            <div className={style.checkboxContainer}>
              <Input
                id="useBeforeDate"
                addon
                type="checkbox"
                checked={useBeforeDate}
                onChange={refetchWithToggleBeforeDate}
              />

              <Label for="useBeforeDate">On Or Before</Label>
            </div>
            <DatePicker
              selected={beforeDate}
              onChange={(date: Date) => {
                setBeforeDate(date);
                refetchWithBeforeDate(date);
              }}
              className={style.dateInput}
              disabled={!useBeforeDate}
            />
          </div>
        </div>

        <span className={style.sectionTitle}>Location</span>
        {locationString() && (
          <div className={style.checkboxContainer}>
            <Input
              id="includeThisLocationOnly"
              addon
              type="checkbox"
              checked={includeThisLocationOnly}
              onChange={refetchWithToggleShowLocationEventsOnly}
            />
            <Label for="includeThisLocationOnly">
              Show{' '}
              <span
                className={style.locationName}
              >{`${locationString()}`}</span>{' '}
              Events Only
            </Label>
          </div>
        )}

        <div className={style.checkboxContainer}>
          <Input
            id="includeSystemEvents"
            addon
            type="checkbox"
            checked={queryInput.includeSystemEvents}
            onChange={refetchWithToggleIncludeSystemEvents}
          />

          <Label for="includeSystemEvents">Include System Events</Label>
        </div>
      </div>

      {isRefreshing && <Loading className={style.loadingIcon} />}
      {hasError && <PageError error={error!} showIcon={false} />}
      {hasAuditLog &&
        auditLog.edges.map((edge: any, index: number) => {
          return (
            <ActivityItem
              key={`${edge.timestamp}_${index}`}
              activityItem={edge.node}
              currentServerDate={currentServerDate}
            />
          );
        })}
      {hasNextPage && (
        <div className={style.activityItem}>
          <Button
            className={style.moreButton}
            disabled={loadingMore}
            onClick={() => {
              setLoadingMore(true);
              fetchMore({
                variables: {
                  input: queryInput,
                  after: auditLog.pageInfo.endCursor,
                },
                updateQuery: (previousResult: any, { fetchMoreResult }) => {
                  setLoadingMore(false);
                  const newEdges = fetchMoreResult.auditLog.edges;
                  const pageInfo = fetchMoreResult.auditLog.pageInfo;

                  return newEdges.length
                    ? {
                        currentTimestamp: fetchMoreResult.currentTimestamp,
                        auditLog: {
                          __typename: previousResult.auditLog.__typename,
                          edges: [
                            ...previousResult.auditLog.edges,
                            ...newEdges,
                          ],
                          pageInfo,
                        },
                      }
                    : previousResult;
                },
              });
            }}
          >
            {loadingMore ? 'Loading more...' : 'More'}
          </Button>
        </div>
      )}
    </div>
  );
};

export { ActivityList };
