import React from 'react';
import moment from 'moment';

import HarvestScheduledImage from '../../../../../../assets/images/HarvestScheduled.svg';
import HarvestImage from '../../../../../../assets/images/HarvestActive.svg';
import CommunicationError from '../../../../../../assets/images/CommunicationError.svg';

import style from './style.module.scss';

interface HarvestSchedule {
  localTimezone: string;
  pausedForHarvestResumeTime?: string;
}

interface PausedForHarvestIndicatorProps {
  harvestSchedule?: HarvestSchedule;
}

const PausedForHarvestIndicator = ({
  harvestSchedule,
}: PausedForHarvestIndicatorProps) => {
  const image = harvestSchedule?.pausedForHarvestResumeTime
    ? HarvestScheduledImage
    : HarvestImage;

  const infoText = harvestSchedule?.pausedForHarvestResumeTime
    ? `Paused until ${moment
        .tz(
          harvestSchedule.pausedForHarvestResumeTime,
          harvestSchedule.localTimezone
        )
        .calendar({
          sameDay: 'LT',
          nextDay: '[tomorrow] [at] LT',
        })} ${moment.tz(harvestSchedule.localTimezone).zoneAbbr()}`
    : 'Stopped for stack harvest';

  return (
    <div className={style.statusIndicatorContainer}>
      <div
        className={style.infoText}
        dangerouslySetInnerHTML={{
          __html: infoText,
        }}
      />

      <img
        src={image}
        className={style.statusIndicator}
        alt="Paused For Harvest"
      />
    </div>
  );
};

const WaitingForOzoneIndicator = () => (
  <div className={style.statusIndicatorContainer}>
    <div
      className={style.infoText}
      dangerouslySetInnerHTML={{
        __html: 'Waiting for ozone',
      }}
    />

    <img
      src={CommunicationError}
      className={style.statusIndicator}
      alt="Ozone Communication Error"
    />
  </div>
);

interface Props {
  zoneMode: string;
  harvestSchedule?: HarvestSchedule;
}

const ZoneStatus = ({ zoneMode, harvestSchedule }: Props) => {
  switch (zoneMode) {
    case 'PAUSED_FOR_HARVEST':
      return <PausedForHarvestIndicator harvestSchedule={harvestSchedule} />;
    case 'WAITING_ON_OZONE':
      return <WaitingForOzoneIndicator />;
    default:
      return null;
  }
};

export default ZoneStatus;
