import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  zoneName: string;
  stackName: string;
  stackId: string;
  onCompleted?: () => void;
}

interface Result {
  openStackAttachedCollectorTankPumpTimeoutResetPrompt: () => void;
  StackAttachedCollectorTankPumpTimeoutResetPrompt: typeof StackAttachedCollectorTankPumpTimeoutResetPrompt;
  stackAttachedCollectorTankPumpTimeoutResetPromptProps: Props;
}

export const RESET_STACK_ATTACHED_COLLECTOR_TANK_PUMP_TIMEOUT = gql`
  mutation resetStackAttachedCollectorTankPumpTimeout(
    $input: ResetStackAttachedCollectorTankPumpTimeoutInputType!
  ) {
    resetStackAttachedCollectorTankPumpTimeout(input: $input) {
      stack {
        stackId
        collectorTank {
          pumpTimeoutDetected
        }
      }
    }
  }
`;

interface Props extends Input {
  stackAttachedCollectorTankPumpTimeoutResetPromptIsOpen: boolean;
  closeStackAttachedCollectorTankPumpTimeoutResetPrompt: () => void;
}

const StackAttachedCollectorTankPumpTimeoutResetPrompt = ({
  zoneName,
  stackName,
  stackId,
  stackAttachedCollectorTankPumpTimeoutResetPromptIsOpen,
  closeStackAttachedCollectorTankPumpTimeoutResetPrompt,
  onCompleted,
}: Props) => {
  const [
    resetStackAttachedCollectorTankPumpTimeout,
    { error, loading: saving },
  ] = useMutation(RESET_STACK_ATTACHED_COLLECTOR_TANK_PUMP_TIMEOUT, {
    onCompleted: () => {
      closeStackAttachedCollectorTankPumpTimeoutResetPrompt();
      onCompleted && onCompleted();
    },
  });

  return (
    <PromptModal
      title={`Reset Return Tank Pump Halted Alert for Zone ${zoneName} / Stack ${stackName}`}
      danger={true}
      isOpen={stackAttachedCollectorTankPumpTimeoutResetPromptIsOpen}
      saving={saving}
      error={error}
      onSave={() => {
        resetStackAttachedCollectorTankPumpTimeout({
          variables: {
            input: {
              stackId,
            },
          },
        });
      }}
      onComplete={closeStackAttachedCollectorTankPumpTimeoutResetPrompt}
    >
      <p>
        The return tank pump was halted while attempting to prevent a tank
        overflow at Zone {`${zoneName} / Stack ${stackName}`}.
      </p>

      <p>
        You should only reset the alert if you have physically verified the pump
        is functioning correctly.
      </p>

      <p>{`Are you sure you want to reset the return tank pump halted alert for Zone ${zoneName} / Stack ${stackName}?`}</p>
    </PromptModal>
  );
};

const useStackAttachedCollectorTankPumpTimeoutResetPrompt: (
  input: Input
) => Result = ({ zoneName, stackName, stackId, onCompleted }) => {
  const [
    stackAttachedCollectorTankPumpTimeoutResetPromptIsOpen,
    setStackAttachedCollectorTankPumpTimeoutResetPromptIsOpen,
  ] = useState(false);

  return {
    openStackAttachedCollectorTankPumpTimeoutResetPrompt: useCallback(
      () => setStackAttachedCollectorTankPumpTimeoutResetPromptIsOpen(true),
      [setStackAttachedCollectorTankPumpTimeoutResetPromptIsOpen]
    ),
    StackAttachedCollectorTankPumpTimeoutResetPrompt,
    stackAttachedCollectorTankPumpTimeoutResetPromptProps: {
      zoneName,
      stackName,
      stackId,
      stackAttachedCollectorTankPumpTimeoutResetPromptIsOpen,
      onCompleted,
      closeStackAttachedCollectorTankPumpTimeoutResetPrompt: useCallback(
        () => setStackAttachedCollectorTankPumpTimeoutResetPromptIsOpen(false),
        [setStackAttachedCollectorTankPumpTimeoutResetPromptIsOpen]
      ),
    },
  };
};

export default useStackAttachedCollectorTankPumpTimeoutResetPrompt;
