import React from 'react';

import style from './style.module.scss';

interface Props {
  className?: string;
}

export default ({ className }: Props) => {
  return (
    <svg
      className={[style.svg, className].join(' ')}
      style={{ fill: 'currentColor' }}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      enableBackground="new 0 0 50 50"
    >
      <g>
        <path d="M5.2,19.3c0.3,0,0.4,0,0.8,0.1c0.4-0.7,1.1-1.2,1.9-1.4c0.2,0,0.4-0.1,0.6-0.1c1.1,0,2,0.6,2.5,1.5   c0.3-0.1,0.4-0.1,0.8-0.1v-5.2H5.2V19.3z"></path>
        <path d="M11.8,31.6c-0.3,0-0.5,0-0.7-0.1c-0.5,0.8-1.4,1.4-2.4,1.4H8.5c-0.8,0-1.5-0.3-2.1-0.9c-0.2-0.2-0.3-0.4-0.4-0.6   c-0.2,0.1-0.5,0.1-0.7,0.1c0,0-0.1,0-0.1,0V37h6.7v-5.4H11.8z"></path>
        <path d="M7.3,47h2.3c1.2,0,2.2-1,2.2-2.2V44H5.2v0.8C5.2,46.1,6.1,47,7.3,47z"></path>
        <path d="M25,17.9c1.1,0,2,0.6,2.5,1.5c0.2-0.1,0.3-0.1,0.8-0.1v-5.2h-6.7v5.2c0.2,0,0.4,0,0.8,0.1C23,18.5,24,17.9,25,17.9z"></path>
        <path d="M28.2,31.6c-0.3,0-0.5,0-0.7-0.1c-0.5,0.8-1.4,1.4-2.4,1.4H25c-0.8,0-1.5-0.3-2.1-0.9c-0.2-0.2-0.3-0.4-0.5-0.6   c-0.2,0.1-0.5,0.1-0.7,0.1c0,0-0.1,0-0.1,0V37h6.7v-5.4H28.2z"></path>
        <path d="M23.9,47h2.3c1.2,0,2.2-1,2.2-2.2V44h-6.7v0.8C21.7,46.1,22.6,47,23.9,47z"></path>
        <path d="M38.2,19.3c0.1,0,0.4,0,0.8,0.1c0.5-0.9,1.5-1.4,2.5-1.4c1.1,0,2,0.6,2.5,1.5c0.2-0.1,0.7-0.1,0.9-0.1v-5.2h-6.7V19.3z"></path>
        <path d="M44.7,31.6c-0.3,0-0.5,0-0.7-0.1c-1.2,1.9-3.9,1.9-5,0c-0.2,0.1-0.5,0.1-0.7,0.1h-0.1V37h6.7v-5.4   C44.8,31.6,44.8,31.6,44.7,31.6z"></path>
        <path d="M40.4,47h2.3c1.2,0,2.2-1,2.2-2.2V44h-6.7v0.8C38.2,46.1,39.2,47,40.4,47z"></path>
        <g>
          <path d="M46.1,24.5h-0.3c-0.7,0-1.1-0.9-0.6-1.5l0.2-0.2c0.4-0.4,0.3-1.2-0.2-1.5c-0.3-0.2-0.8-0.1-1.1,0.2l-0.2,0.2    c-0.5,0.5-1.5,0.1-1.5-0.6v-0.3c0-0.6-0.5-1-1.1-0.9c-0.4,0.1-0.7,0.5-0.7,0.9v0.2c0,0.7-0.9,1.1-1.5,0.6l-0.2-0.2    c-0.2-0.2-0.5-0.3-0.7-0.3c-0.2,0-0.4,0.1-0.6,0.3c-0.4,0.4-0.4,0.9,0,1.3l0.2,0.2c0.5,0.5,0.1,1.5-0.6,1.5h-0.3    c-0.6,0-1,0.5-0.9,1.1c0.1,0.4,0.5,0.7,1,0.7h0.2c0.7,0,1.1,0.9,0.6,1.5L37.6,28c-0.4,0.4-0.4,1.1,0.1,1.4    c0.3,0.2,0.8,0.2,1.2-0.1l0.2-0.2c0.5-0.5,1.5-0.1,1.5,0.6V30c0,0.5,0.4,0.9,1,0.9c0.5,0,0.9-0.5,0.9-1v-0.2    c0-0.7,0.9-1.1,1.5-0.6l0.2,0.2c0.4,0.4,1,0.3,1.3,0c0.4-0.4,0.4-0.9,0-1.3l-0.2-0.2c-0.2-0.2-0.3-0.6-0.2-0.9v0    c0.1-0.3,0.4-0.5,0.8-0.5H46c0.5,0,1-0.4,1-0.9C47,25,46.6,24.5,46.1,24.5z M41.5,28.4c-1.6,0-3-1.3-3-3c0-1.6,1.4-3,3-3    c1.7,0,3,1.4,3,3C44.5,27.1,43.1,28.4,41.5,28.4z"></path>
          <circle cx="41.5" cy="25.4" r="1"></circle>
        </g>
        <g>
          <path d="M29.6,24.5h-0.3c-0.7,0-1.1-0.9-0.6-1.5l0.2-0.2c0.4-0.4,0.3-1.2-0.2-1.5c-0.3-0.2-0.8-0.1-1.1,0.2l-0.2,0.2    c-0.5,0.5-1.5,0.1-1.5-0.6v-0.3c0-0.6-0.5-1-1.1-0.9c-0.4,0.1-0.7,0.5-0.7,0.9v0.2c0,0.7-0.9,1.1-1.5,0.6l-0.2-0.2    c-0.2-0.2-0.5-0.3-0.7-0.3c-0.2,0-0.4,0.1-0.6,0.3c-0.4,0.4-0.4,0.9,0,1.3l0.2,0.2c0.5,0.5,0.1,1.5-0.6,1.5h-0.3    c-0.6,0-1,0.5-0.9,1.1c0.1,0.4,0.5,0.7,1,0.7h0.2c0.7,0,1.1,0.9,0.6,1.5L21.1,28c-0.4,0.4-0.4,1.1,0.1,1.4    c0.3,0.2,0.8,0.2,1.2-0.1l0.2-0.2c0.5-0.5,1.5-0.1,1.5,0.6V30c0,0.5,0.4,0.9,1,0.9c0.5,0,0.9-0.5,0.9-1v-0.2    c0-0.7,0.9-1.1,1.5-0.6l0.2,0.2c0.4,0.4,1,0.3,1.3,0c0.4-0.4,0.4-0.9,0-1.3l-0.2-0.2c-0.2-0.2-0.3-0.6-0.2-0.9v0    c0.1-0.3,0.4-0.5,0.8-0.5h0.2c0.5,0,1-0.4,1-0.9C30.5,25,30.1,24.5,29.6,24.5z M25,28.4c-1.6,0-3-1.3-3-3c0-1.6,1.4-3,3-3    c1.7,0,3,1.4,3,3C28,27.1,26.7,28.4,25,28.4z"></path>
          <circle cx="25" cy="25.4" r="1"></circle>
        </g>
        <g>
          <path d="M13.1,24.5h-0.3c-0.7,0-1.1-0.9-0.6-1.5l0.2-0.2c0.4-0.4,0.3-1.2-0.2-1.5c-0.3-0.2-0.8-0.1-1.1,0.2l-0.2,0.2    c-0.5,0.5-1.5,0.1-1.5-0.6v-0.3c0-0.6-0.5-1-1.1-0.9c-0.4,0.1-0.7,0.5-0.7,0.9v0.2c0,0.7-0.9,1.1-1.5,0.6L6,21.6    c-0.2-0.2-0.5-0.3-0.7-0.3c-0.2,0-0.4,0.1-0.6,0.3c-0.4,0.4-0.4,0.9,0,1.3l0.2,0.2c0.5,0.5,0.1,1.5-0.6,1.5H4    c-0.6,0-1,0.5-0.9,1.1c0.1,0.4,0.5,0.7,1,0.7h0.2c0.7,0,1.1,0.9,0.6,1.5L4.7,28c-0.4,0.4-0.4,1.1,0.1,1.4c0.3,0.2,0.8,0.2,1.2-0.1    l0.2-0.2c0.5-0.5,1.5-0.1,1.5,0.6V30c0,0.5,0.4,0.9,1,0.9c0.5,0,0.9-0.5,0.9-1v-0.2c0-0.7,0.9-1.1,1.5-0.6l0.2,0.2    c0.4,0.4,1,0.3,1.3,0c0.4-0.4,0.4-0.9,0-1.3l-0.2-0.2c-0.2-0.2-0.3-0.6-0.2-0.9v0c0.1-0.3,0.4-0.5,0.8-0.5h0.2c0.5,0,1-0.4,1-0.9    C14.1,25,13.6,24.5,13.1,24.5z M8.5,28.4c-1.6,0-3-1.3-3-3c0-1.6,1.4-3,3-3c1.7,0,3,1.4,3,3C11.5,27.1,10.2,28.4,8.5,28.4z"></path>
          <circle cx="8.5" cy="25.4" r="1"></circle>
        </g>
        <rect x="1" y="3" width="2.5" height="10.8"></rect>
        <rect x="46.5" y="3" width="2.5" height="10.8"></rect>
        <rect x="5.5" y="4.6" width="39" height="7.5"></rect>
        <rect x="36.8" y="39" width="9.4" height="3.1"></rect>
        <rect x="20.3" y="39" width="9.4" height="3.1"></rect>
        <rect x="3.8" y="39" width="9.4" height="3.1"></rect>
      </g>
    </svg>
  );
};
