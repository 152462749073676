import React from 'react';
import gql from 'graphql-tag';

import useStackAttachedCollectorTankFlushPrompt from '../../hooks/useStackAttachedCollectorTankFlushPrompt';
import useStackAttachedCollectorTankPumpModePrompts from '../../hooks/useStackAttachedCollectorTankPumpModePrompts';

import useZoneAttachedCollectorTankFlushPrompt from '../../hooks/useZoneAttachedCollectorTankFlushPrompt';
import useZoneAttachedCollectorTankPumpModePrompts from '../../hooks/useZoneAttachedCollectorTankPumpModePrompts';
import useZoneAttachedCollectorTankInvalidWaterSensorStateResetPrompt from '../../hooks/useZoneAttachedCollectorTankInvalidWaterSensorStateResetPrompt';

import PumpButton from '../PumpButton';
import Tank from '../Tank';
import NamedTank from '../NamedTank';
import Switch from '../Switch';
import InvalidWaterSensorStateIcon from '../InvalidWaterSensorStateIcon';

import style from './style.module.scss';

const ZoneAttachedCollectorTankResetInvalidStateButton = ({
  collectorTank,
  ...props
}) => {
  const {
    resetInvalidWaterLevelSensorStateCommand,
    invalidTankLevelSwitches,
  } = collectorTank;

  const { zoneId, name: zoneName } = collectorTank.zone;

  const {
    openZoneAttachedCollectorTankInvalidWaterSensorStateResetPrompt,
    ZoneAttachedCollectorTankInvalidWaterSensorStateResetPrompt,
    zoneAttachedCollectorTankInvalidWaterSensorStateResetPromptProps,
  } = useZoneAttachedCollectorTankInvalidWaterSensorStateResetPrompt({
    zoneName: zoneName,
    zoneId: zoneId,
    invalidStates: {
      HH: invalidTankLevelSwitches?.hh,
      H: invalidTankLevelSwitches?.h,
      LL: invalidTankLevelSwitches?.ll,
    },
  });

  return (
    <>
      <ZoneAttachedCollectorTankInvalidWaterSensorStateResetPrompt
        {...zoneAttachedCollectorTankInvalidWaterSensorStateResetPromptProps}
      />

      <InvalidWaterSensorStateIcon
        className={style.invalidWaterSensorStateIcon}
        onClick={
          resetInvalidWaterLevelSensorStateCommand.canExecute
            ? openZoneAttachedCollectorTankInvalidWaterSensorStateResetPrompt
            : undefined
        }
        style={{
          cursor: resetInvalidWaterLevelSensorStateCommand.canExecute
            ? 'pointer'
            : undefined,
        }}
      />
    </>
  );
};

const StackAttachedCollectorTankPumpButton = ({
  zoneName,
  collectorTank,
  ...props
}) => {
  const { stack, pumpMode, pumpState, flushCommand } = collectorTank;

  const {
    openStackAttachedCollectorTankFlushPrompt,
    StackAttachedCollectorTankFlushPrompt,
    stackAttachedCollectorTankFlushPromptProps,
  } = useStackAttachedCollectorTankFlushPrompt({
    zoneName,
    stackName: stack.name,
    stackId: stack.stackId,
  });

  return (
    <>
      <StackAttachedCollectorTankFlushPrompt
        {...stackAttachedCollectorTankFlushPromptProps}
      />

      <PumpButton
        {...props}
        pumpState={pumpState}
        manualMode={pumpMode !== 'AUTOMATIC'}
        disabled={!flushCommand.canExecute}
        onClick={openStackAttachedCollectorTankFlushPrompt}
      />
    </>
  );
};

const ZoneAttachedCollectorTankPumpButton = ({ collectorTank, ...props }) => {
  const { zone, pumpMode, pumpState, flushCommand } = collectorTank;

  const {
    openZoneAttachedCollectorTankFlushPrompt,
    ZoneAttachedCollectorTankFlushPrompt,
    zoneAttachedCollectorTankFlushPromptProps,
  } = useZoneAttachedCollectorTankFlushPrompt({
    zoneName: zone.name,
    zoneId: zone.zoneId,
  });

  return (
    <>
      <ZoneAttachedCollectorTankFlushPrompt
        {...zoneAttachedCollectorTankFlushPromptProps}
      />

      <PumpButton
        {...props}
        pumpState={pumpState}
        manualMode={pumpMode !== 'AUTOMATIC'}
        disabled={!flushCommand.canExecute}
        onClick={openZoneAttachedCollectorTankFlushPrompt}
      />
    </>
  );
};

const StackAttachedCollectorTankPumpModeSwitch = ({
  zoneName,
  collectorTank,
  ...props
}) => {
  const { stack, pumpMode, updatePumpModeCommand } = collectorTank;

  const {
    openStackAttachedCollectorTankPumpAutomaticModePrompt,
    openStackAttachedCollectorTankPumpMaintenanceModePrompt,
    StackAttachedCollectorTankPumpModePrompts,
    stackAttachedCollectorTankPumpModePromptsProps,
  } = useStackAttachedCollectorTankPumpModePrompts({
    zoneName,
    stackName: stack.name,
    stackId: stack.stackId,
  });

  return (
    <>
      <StackAttachedCollectorTankPumpModePrompts
        {...stackAttachedCollectorTankPumpModePromptsProps}
      />

      <Switch
        height={25}
        width={52}
        {...props}
        id="pump-mode-switch"
        disabled={!updatePumpModeCommand.canExecute}
        checked={pumpMode === 'AUTOMATIC'}
        onChange={
          pumpMode === 'AUTOMATIC'
            ? openStackAttachedCollectorTankPumpMaintenanceModePrompt
            : openStackAttachedCollectorTankPumpAutomaticModePrompt
        }
      />
    </>
  );
};

const ZoneAttachedCollectorTankPumpModeSwitch = ({
  collectorTank,
  ...props
}) => {
  const { zone, pumpMode, updatePumpModeCommand } = collectorTank;

  const {
    openZoneAttachedCollectorTankPumpAutomaticModePrompt,
    openZoneAttachedCollectorTankPumpMaintenanceModePrompt,
    ZoneAttachedCollectorTankPumpModePrompts,
    zoneAttachedCollectorTankPumpModePromptsProps,
  } = useZoneAttachedCollectorTankPumpModePrompts({
    zoneName: zone.name,
    zoneId: zone.zoneId,
  });

  return (
    <>
      <ZoneAttachedCollectorTankPumpModePrompts
        {...zoneAttachedCollectorTankPumpModePromptsProps}
      />

      <Switch
        height={25}
        width={52}
        {...props}
        id="pump-mode-switch"
        disabled={!updatePumpModeCommand.canExecute}
        checked={pumpMode === 'AUTOMATIC'}
        onChange={
          pumpMode === 'AUTOMATIC'
            ? openZoneAttachedCollectorTankPumpMaintenanceModePrompt
            : openZoneAttachedCollectorTankPumpAutomaticModePrompt
        }
      />
    </>
  );
};

const CollectorTank = ({
  className,
  zoneReturnLabelClassName,
  zoneName,
  collectorTank,
  pumpSwitchProps,
  hidePumpCommands = false,
  sensorsOnRight = false,
}) => {
  const {
    waterLevel,
    gallons,
    pumpState,
    pumpMode,
    flushCommand,
    updatePumpModeCommand,
    dryTankDetected,
  } = collectorTank;

  const showPumpButton = !hidePumpCommands && flushCommand.isAuthorized;
  if (dryTankDetected && !!className) {
    className += ' dryTank';
  }

  return (
    <>
      <div className={[style.container, className].join(' ')}>
        {!hidePumpCommands && updatePumpModeCommand.systemStateSatisfied && (
          <div className={style.pumpModeSwitchContainer}>
            <label
              htmlFor="pump-mode-switch"
              className={style.pumpModeSwitchLabel}
            >
              Pump Auto Mode
            </label>

            {collectorTank.stack && (
              <StackAttachedCollectorTankPumpModeSwitch
                {...pumpSwitchProps}
                zoneName={zoneName}
                collectorTank={collectorTank}
              />
            )}

            {collectorTank.zone && (
              <ZoneAttachedCollectorTankPumpModeSwitch
                {...pumpSwitchProps}
                collectorTank={collectorTank}
              />
            )}
          </div>
        )}

        {collectorTank.stack ? (
          <Tank
            className={style.tank}
            waterLevel={waterLevel}
            gallons={gallons}
            pumpState={pumpState}
            hidePump={showPumpButton}
            sensorsOnRight={sensorsOnRight}
            dryTankDetected={dryTankDetected}
          >
            {showPumpButton && (
              <StackAttachedCollectorTankPumpButton
                className={[
                  style.flushTankButton,
                  pumpMode !== 'AUTOMATIC' ? style.manual : style.automatic,
                  pumpState === 'ON' ? style.on : style.off,
                ].join(' ')}
                zoneName={zoneName}
                collectorTank={collectorTank}
              />
            )}
          </Tank>
        ) : (
          <NamedTank
            entityType={
              <span
                className={[
                  style.zoneReturnLabel,
                  zoneReturnLabelClassName,
                ].join(' ')}
              >
                Zone Return
              </span>
            }
            className={style.tank}
            waterLevel={waterLevel}
            gallons={gallons}
            pumpState={pumpState}
            hidePump={showPumpButton}
            levelSensors={[
              collectorTank?.invalidTankLevelSwitches?.hh,
              collectorTank?.invalidTankLevelSwitches?.h,
              collectorTank?.invalidTankLevelSwitches?.ll,
            ].filter(x => x !== undefined && x !== null)}
            sensorsOnRight={sensorsOnRight}
            dryTankDetected={dryTankDetected}
          >
            {collectorTank.invalidWaterLevelSensorStateDetected && (
              <ZoneAttachedCollectorTankResetInvalidStateButton
                collectorTank={collectorTank}
              />
            )}
            {showPumpButton && (
              <ZoneAttachedCollectorTankPumpButton
                className={[
                  style.flushTankButton,
                  pumpMode !== 'AUTOMATIC' ? style.manual : style.automatic,
                  pumpState === 'ON' ? style.on : style.off,
                ].join(' ')}
                zone={collectorTank.zone}
                collectorTank={collectorTank}
              />
            )}
          </NamedTank>
        )}
      </div>
    </>
  );
};

CollectorTank.fragment = gql`
  fragment CollectorTank on CollectorTankInterface {
    waterLevel
    gallons
    pumpState
    pumpMode
    flushCommand {
      isAuthorized
      canExecute
    }
    updatePumpModeCommand {
      systemStateSatisfied
      canExecute
    }

    ... on StackAttachedCollectorTankType {
      stack {
        stackId
        name
      }
      dryTankDetected
    }

    ... on ZoneAttachedCollectorTankType {
      zone {
        zoneId
        name
      }
      dryTankDetected
      invalidWaterLevelSensorStateDetected
      invalidTankLevelSwitches {
        hh
        h
        l
        ll
      }
      resetInvalidWaterLevelSensorStateCommand {
        isAuthorized
        canExecute
      }
    }
  }
`;

export default CollectorTank;
