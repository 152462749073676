import React, { useState, useEffect } from 'react';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import { SideMenu } from './components/SideMenu';
import style from './style.module.scss';

export default ({ heading, subHeading, children, autoScaleContent = true }) => {
  var [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);

  useEffect(() => {
    window.document.body.classList.add(
      autoScaleContent ? style.autoScale : style.traditional
    );

    return () => {
      window.document.body.classList.remove(style.autoScale);
      window.document.body.classList.remove(style.traditional);
    };
  });

  useEffect(() => {
    if (sideMenuIsOpen) {
      window.document.body.classList.add(style.noscroll);
    } else {
      window.document.body.classList.remove(style.noscroll);
    }

    return () => {
      window.document.body.classList.remove(style.noscroll);
    };
  });

  return (
    <div className={style.container}>
      <SideMenu
        className={[style.sideMenu, sideMenuIsOpen ? style.isOpen : null].join(
          ' '
        )}
        isOpen={sideMenuIsOpen}
        onMenuButtonClick={() => setSideMenuIsOpen(false)}
      />
      <NavBar
        className={style.navbar}
        subHeading={subHeading}
        heading={heading}
        onMenuButtonClick={() => setSideMenuIsOpen(true)}
      />
      <div className={style.contentFooterContainer}>
        <div
          className={[
            style.content,
            autoScaleContent ? style.autoScale : style.traditional,
          ].join(' ')}
        >
          {children}
        </div>
        <Footer className={style.footer} />
      </div>
    </div>
  );
};
