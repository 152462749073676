import gql from 'graphql-tag';
import { FRAGMENT_OZONE_SYSTEM } from './fragmentOzoneSystem';

export const SUBSCRIPTION_OZONE_SYSTEM = gql`
  subscription ozoneSystemUpdated($ozoneSystemId: ID!) {
    ozoneSystemUpdated(ozoneSystemId: $ozoneSystemId) {
      ozoneSystem {
        ...CalibrateOzoneSystemTankForm
      }
    }
  }
  ${FRAGMENT_OZONE_SYSTEM}
`;
