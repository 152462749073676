import React, { useEffect } from 'react';
import { Query } from 'react-apollo';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import gql from 'graphql-tag';
import defaultFacilityId from '../../modules/defaultFacilityId';
import { equalsIgnoringCase } from '../../modules/string';

import { useQuery, useSubscription } from '@apollo/react-hooks';
import PageLayout from '../../components/PageLayout';
import FacilityHeading from '../../components/PageLayout/FacilityHeading';
import PageLoadingContainer from '../../components/PageLoadingContainer';
import PageNotFound from '../../components/PageNotFound';
import FacilityNavigation from './components/FacilityNavigation';
import Zone from './components/Zone';
import PumpRoom from './components/PumpRoom';

import style from './style.module.scss';

const facilityFragment = gql`
  fragment FacilityDetail on FacilityType {
    facilityId
    ...FacilityHeading
    ...FacilityNavigation
    zones {
      name
    }
  }
  ${FacilityHeading.fragment}
  ${FacilityNavigation.fragment}
`;

const FACILITY_QUERY = gql`
  query facility($facilityId: ID!) {
    facilities {
      ...FacilityHeading
    }

    facility(facilityId: $facilityId) {
      ...FacilityDetail
    }
  }
  ${facilityFragment}
`;

const FACILITY_SUBSCRIPTION = gql`
  subscription facilityUpdated($facilityId: ID!) {
    facilityUpdated(facilityId: $facilityId) {
      facility {
        ...FacilityDetail
      }
    }
  }
  ${facilityFragment}
`;

const PumpRoomPage = ({ facility, zones }) => {
  const { facilityId } = facility;
  return (
    <>
      <div className={style.navigation}>
        <FacilityNavigation
          facility={facility}
          zones={zones}
          currentSelection={{
            type: FacilityNavigation.SelectionType.PUMP_ROOM,
          }}
        />
      </div>

      <PumpRoom facilityId={facilityId} className={style.detailContainer} />
    </>
  );
};

const ZonePage = ({ facility, zones, zoneName }) => {
  const { facilityId } = facility;
  const zone = zones.find(x => equalsIgnoringCase(x.name, zoneName));

  return (
    <>
      <div className={style.navigation}>
        <FacilityNavigation
          facility={facility}
          zones={zones}
          currentSelection={
            zone && {
              type: FacilityNavigation.SelectionType.ZONE,
              zone,
            }
          }
        />
      </div>

      {zone ? (
        <Zone
          className={style.detailContainer}
          facilityId={facilityId}
          zoneName={zoneName}
          zoneId={zone.zoneId}
        />
      ) : (
        <PageNotFound
          className={style.detailContainer}
          resourceTypeName={`Zone ${zoneName}`}
        />
      )}
    </>
  );
};

const PageContent = ({ facilityId }) => {
  const result = useQuery(FACILITY_QUERY, {
    variables: {
      facilityId: facilityId,
    },
  });

  useSubscription(FACILITY_SUBSCRIPTION, {
    variables: {
      facilityId: facilityId,
    },
  });

  const { data } = result;
  const { facility, facilities } = data ? data : {};

  useEffect(() => {
    if (facility) {
      defaultFacilityId.set(facility.facilityId);
    }
  }, [facility]);

  return (
    <PageLayout
      heading={
        <FacilityHeading facility={facility} facilities={facilities || []} />
      }
    >
      <PageLoadingContainer
        resourceTypeName="Facility"
        result={result}
        resourceExists={!!facility}
        render={() => (
          <div className={style.container}>
            <Switch>
              <Route
                exact
                path="/facility/:facilityId/zone/:zoneName"
                render={({
                  match: {
                    params: { zoneName },
                  },
                }) => (
                  <ZonePage
                    facility={facility}
                    zones={facility.zones}
                    zoneName={zoneName}
                  />
                )}
              />

              <PumpRoomPage facility={facility} zones={facility.zones} />
            </Switch>
          </div>
        )}
      />
    </PageLayout>
  );
};

export default () => {
  const match = useRouteMatch({ path: '/facility/:facilityId' });

  return match ? (
    <PageContent facilityId={match.params.facilityId} />
  ) : (
    <Query
      query={gql`
        {
          facilities {
            facilityId
          }
        }
      `}
    >
      {result => {
        const { data } = result;
        const facilities = data?.facilities || [];
        const facilityId = facilities
          .map(x => x.facilityId)
          .includes(defaultFacilityId.get())
          ? defaultFacilityId.get()
          : facilities[0]?.facilityId;

        return (
          <PageLayout>
            <PageLoadingContainer
              resourceTypeName="Facilities"
              result={result}
              resourceExists={facilities.length}
              render={() => {
                return <Redirect to={`/facility/${facilityId}`} />;
              }}
            />
          </PageLayout>
        );
      }}
    </Query>
  );
};
