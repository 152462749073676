import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const HYCUBE_API_VERSION_QUERY = gql`
  query {
    hyCubeApiVersion
  }
`;

interface IProps {
  className: string;
}

export default ({ className }: IProps) => {
  const { loading, error, data } = useQuery(HYCUBE_API_VERSION_QUERY);

  if (error) return `Error: ${error.message}`;

  if (loading) {
    return <p className={className}>Loading version ...</p>;
  } else {
    return <p className={className}>API-Version: {data.hyCubeApiVersion}</p>;
  }
};
