import jwt from 'jsonwebtoken';
import history from '../modules/history';
import { graphQLHttpUrl } from '../config';
import { EventEmitter } from 'eventemitter3';

export class InvalidTokenError extends Error {}

const eventEmitter = new EventEmitter();

const TOKEN_LOCAL_STORAGE_KEY = 'hycubeos.accessToken';
const REFRESH_TOKEN_LOCAL_STORAGE_KEY = 'hycubeos.refreshToken';
const LEGACY_LOCAL_STORAGE_KEYS = [
  'token',
  'kalera.token',
  'hycubeos.token',
  'refreshToken',
  'TokenExpiry',
];

const getAccessToken = () => localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY);

const setAccessToken = accessToken => {
  LEGACY_LOCAL_STORAGE_KEYS.forEach(x => localStorage.removeItem(x));

  if (!accessToken) {
    localStorage.removeItem(TOKEN_LOCAL_STORAGE_KEY);
    eventEmitter.emit('setAccessToken');
    return;
  }

  if (!jwt.decode(accessToken)) {
    throw new InvalidTokenError();
  }

  localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, accessToken);
  eventEmitter.emit('setAccessToken');
};

const getRefreshToken = () =>
  localStorage.getItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY);

const setRefreshToken = refreshToken => {
  refreshToken
    ? localStorage.setItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY, refreshToken)
    : localStorage.removeItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY);
};

const on = (eventName, callback) => {
  const handler = eventEmitter.on(eventName, callback);
  return () => handler.off(eventName);
};

const onSetAccessToken = callback => on('setAccessToken', callback);

/**
 * @deprecated use interchangeLogoff with default moniker instead.
 */
const logoff = () => {
  setAccessToken(null);
  setRefreshToken(null);
  history.push('/login', { from: window.location.pathname });
};

const interchangeLogoff = (interchanges = []) => {
  //Wipe system authentication
  setAccessToken(null);
  setRefreshToken(null);

  //Have interchange provider handle logoff flow
  for (let i = 0; i < interchanges.length; i++) {
    const provider = interchanges[i];

    if (provider.predicate) {
      provider.bailPolicy();
      break;
    }
  }
};

const fetchNewAccessToken = async () => {
  let accessToken = getAccessToken();
  let refreshToken = getRefreshToken();
  if (!accessToken || !refreshToken) {
    return null;
  }

  const refreshAccessTokenResponseJson = await fetch(graphQLHttpUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer: ${accessToken}`,
    },
    body: JSON.stringify({
      query: `
        mutation refreshAccessToken($refreshToken:String!){
            refreshAccessToken(refreshToken:$refreshToken){
                accessToken
                refreshToken
            }
        }`,
      variables: {
        refreshToken,
      },
    }),
  }).then(x => x.json());

  if (
    !refreshAccessTokenResponseJson ||
    refreshAccessTokenResponseJson.errors
  ) {
    return null;
  }

  accessToken =
    refreshAccessTokenResponseJson.data.refreshAccessToken.accessToken;

  refreshToken =
    refreshAccessTokenResponseJson.data.refreshAccessToken.refreshToken;

  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
  return accessToken;
};

const setTokens = ({ accessToken, refreshToken }) => {
  setRefreshToken(refreshToken);
  setAccessToken(accessToken);
};

export default {
  setTokens,
  getAccessToken,
  onSetAccessToken,
  fetchNewAccessToken,
  logoff,
  interchangeLogoff,
};
