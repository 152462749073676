import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  userFirstName: string;
  userLastName: string;
  userId: string;
  onCompleted?: () => void;
}

interface Result {
  openUserRestorePrompt: () => void;
  UserRestorePrompt: typeof UserRestorePrompt;
  userRestorePromptProps: Props;
}

const RESTORE_DELETED_USER = gql`
  mutation restoreDeletedUser($input: RestoreDeletedUserInputType!) {
    restoreDeletedUser(input: $input) {
      user {
        userId
        deleted
      }
    }
  }
`;

interface Props extends Input {
  userRestorePromptIsOpen: boolean;
  closeUserRestorePrompt: () => void;
}

const UserRestorePrompt = ({
  userFirstName,
  userLastName,
  userId,
  userRestorePromptIsOpen,
  closeUserRestorePrompt,
  onCompleted,
}: Props) => {
  const [userRestore, { error, loading: saving }] = useMutation(
    RESTORE_DELETED_USER,
    {
      onCompleted: () => {
        closeUserRestorePrompt();
        onCompleted && onCompleted();
      },
    }
  );

  return (
    <PromptModal
      title={`Restore ${userFirstName} ${userLastName}?`}
      isOpen={userRestorePromptIsOpen}
      saving={saving}
      error={error}
      onSave={() => {
        userRestore({
          variables: {
            input: { userId },
          },
        });
      }}
      onComplete={closeUserRestorePrompt}
    >
      <p>
        {`Restoring ${userFirstName} ${userLastName} will allow them to login. `}
      </p>

      <p>{`Restore ${userFirstName} ${userLastName}?`}</p>
    </PromptModal>
  );
};

const useUserRestorePrompt: (input: Input) => Result = ({
  userFirstName,
  userLastName,
  userId,
  onCompleted,
}) => {
  const [userRestorePromptIsOpen, setUserRestorePromptIsOpen] = useState(false);

  return {
    openUserRestorePrompt: useCallback(() => setUserRestorePromptIsOpen(true), [
      setUserRestorePromptIsOpen,
    ]),
    UserRestorePrompt,
    userRestorePromptProps: {
      userFirstName,
      userLastName,
      userId,
      userRestorePromptIsOpen,
      onCompleted,
      closeUserRestorePrompt: useCallback(
        () => setUserRestorePromptIsOpen(false),
        [setUserRestorePromptIsOpen]
      ),
    },
  };
};

export default useUserRestorePrompt;
