import React from 'react';
import gql from 'graphql-tag';

import useReverseOsmosisModePrompts from '../../../../../../hooks/useReverseOsmosisModePrompts';

import ReverseOsmosisSkidIcon from '../../../../../../components/ReverseOsmosisSkidIcon';
import WaterFlowSensor from '../../../../../../components/WaterFlowSensor';
import Pipe from '../../../../../../components/Pipe';
import Switch from '../../../../../../components/Switch';
import Stopped from '../../../../../../assets/images/stopped.svg';

import style from './style.module.scss';
import { useQuery } from 'react-apollo';
import useResetSafetyStopPrompt from '../../../../../../hooks/useResetSafetyStopPrompt';

export const GET_IS_SAFETY_STOP = gql`
  query isSafetyStop {
    isSafetyStop: reverseOsmosisPumpIsSafetyStop
  }
`;

const ReverseOsmosisSystem = ({ className, facility }) => {
  const { facilityId } = facility;

  const { loading, error, data } = useQuery(GET_IS_SAFETY_STOP, {
    pollInterval: 5000,
  });

  const {
    openReverseOsmosisAutomaticModePrompt,
    openReverseOsmosisMaintenanceModePrompt,
    ReverseOsmosisModePrompts,
    reverseOsmosisModePromptsProps,
  } = useReverseOsmosisModePrompts({ facilityId });

  const {
    openStackLeakResetPrompt,
    StackLeakResetPrompt,
    stackLeakResetPromptProps,
  } = useResetSafetyStopPrompt({ facilityId, onCompleted: () => {} });

  const pumpRoom = facility.pumpRoom || {};
  const reverseOsmosisSystem = pumpRoom.reverseOsmosisSystem || {};
  const { updateReverseOsmosisModeCommand } = reverseOsmosisSystem;

  return (
    <div className={className}>
      <ReverseOsmosisModePrompts {...reverseOsmosisModePromptsProps} />
      <StackLeakResetPrompt {...stackLeakResetPromptProps} />

      <div className={style.container}>
        <div
          className={[
            style.reverseOsmosisSystemContainer,
            updateReverseOsmosisModeCommand ? null : style.perZoneValveMode,
          ].join(' ')}
        >
          <div className={style.switchContainer}>
            {updateReverseOsmosisModeCommand && (
              <div>
                <label htmlFor="reverse-osmosis-mode-switch">
                  Reverse Osmosis <br /> Auto Mode
                </label>

                <Switch
                  id="reverse-osmosis-mode-switch"
                  checked={reverseOsmosisSystem.mode === 'AUTOMATIC'}
                  disabled={!updateReverseOsmosisModeCommand.canExecute}
                  onChange={
                    reverseOsmosisSystem.mode === 'AUTOMATIC'
                      ? openReverseOsmosisMaintenanceModePrompt
                      : openReverseOsmosisAutomaticModePrompt
                  }
                />
              </div>
            )}
          </div>

          <div className={style.skidContainer}>
            {data != null && data.isSafetyStop ? (
              <div className={style.waterFlowSensorStopped}>
                <img
                  src={Stopped}
                  className="img-fluid"
                  style={{ cursor: 'pointer' }}
                  onClick={openStackLeakResetPrompt}
                />
              </div>
            ) : (
              <WaterFlowSensor
                className={style.waterFlowSensor}
                waterIsFlowing={reverseOsmosisSystem.isWaterFlowingFromSkid}
              />
            )}

            <Pipe
              className={style.skidOutPipe}
              waterIsFlowing={reverseOsmosisSystem.isWaterFlowingFromSkid}
            />

            <ReverseOsmosisSkidIcon className={style.skid} />
          </div>
        </div>
      </div>
    </div>
  );
};

ReverseOsmosisSystem.fragment = gql`
  fragment ReverseOsmosisSystem on FacilityType {
    facilityId
    pumpRoom {
      reverseOsmosisSystem {
        mode
        updateReverseOsmosisModeCommand {
          canExecute
        }
        isWaterFlowingFromSkid
      }
    }
  }
`;

export default ReverseOsmosisSystem;
