import gql from 'graphql-tag';
import { FRAGMENT_ZONE_SYSTEM } from './fragmentZoneSystem';

export const GET_ZONE = gql`
  query zone($zoneId: ID!) {
    zone(zoneId: $zoneId) {
      ...CalibrateZoneTankForm
    }
  }
  ${FRAGMENT_ZONE_SYSTEM}
`;
