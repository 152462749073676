import React, { FC } from 'react';

import style from './style.module.scss';

interface Props {
  activityItem: any;
}

const User: FC<Props> = ({ activityItem }: Props): JSX.Element => {
  const { user } = activityItem;
  const {
    isSystemUser,
    firstName: userFirstName,
    lastName: userLastName,
    email: userEmail,
  } = user;

  return isSystemUser ? (
    <span>'The system'</span>
  ) : (
    <a className={style.userName} href={`mailto:${userEmail}`}>
      {userFirstName && userLastName
        ? `${userFirstName} ${userLastName}`
        : { userEmail }}
    </a>
  );
};

export { User };
