import React from 'react';
import gql from 'graphql-tag';

import style from './style.module.scss';

import NamedTank from '../../../../../../components/NamedTank';

interface IProps {
  className: string;
  zoneDistributionTank: ITank;
}

interface ITank {
  name: string;
  waterLevel: 'EMPTY' | 'LOW' | 'NOMINAL' | 'FULL' | 'OVERFLOWING';
  pumpState: 'OFF' | 'ON';
  gallons: string;
}

const ZoneDistributionTank = ({ className, zoneDistributionTank }: IProps) => {
  const { name, waterLevel, pumpState, gallons } = zoneDistributionTank;

  return (
    <div className={[style.container, className].join(' ')}>
      <NamedTank
        entityType="Supply"
        entityName={name}
        waterLevel={waterLevel}
        pumpState={pumpState}
        gallons={gallons}
      />
    </div>
  );
};

ZoneDistributionTank.fragment = gql`
  fragment ZoneDistributionTank on ZoneDistributionTankType {
    zoneDistributionTankId
    name
    pumpState
    waterLevel
    gallons
  }
`;

export default ZoneDistributionTank;
