import React, { useState, useEffect, useMemo } from 'react';
import gql from 'graphql-tag';
import { scaleLinear } from 'd3-scale';
import { sortBy } from 'lodash';
import moment from 'moment';
import 'moment-duration-format';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faClock,
  faBan,
  faMinusSquare,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';

import useZoneNutrientMixerConfigurePumpsForm from '../../../../../../hooks/useZoneNutrientMixerConfigurePumpsForm';
import useNutrientMixerPrimePumpPrompt from '../../../../../../hooks/useNutrientMixerPrimePumpPrompt';

import { NutrientMixerMeasurementColors } from '../../../../../../modules/nutrient-mixer';

import Pump from '../../../../../../components/Pump';
import PumpButton from '../../../../../../components/PumpButton';
import Pipe from '../../../../../../components/Pipe';
import Valve from '../../../../../../components/Valve';
import ValveButton from '../../../../../../components/ValveButton';

import style from './style.module.scss';

const getQueueItemColor = type => {
  switch (type) {
    case 'LOWER_PH':
    case 'RAISE_PH':
      return NutrientMixerMeasurementColors.ph;
    case 'RAISE_EC':
      return NutrientMixerMeasurementColors.ec;
    default:
      return undefined;
  }
};

const getQueueLaneTitle = type => {
  switch (type) {
    case 'LOWER_PH':
      return '↓ pH';
    case 'RAISE_PH':
      return '↑ pH';
    case 'RAISE_EC':
      return '↑ EC';
    case 'TIMED_DOSE':
      return <FontAwesomeIcon icon={faClock} fixedWidth />;
    case 'DISABLED':
      return <FontAwesomeIcon icon={faBan} fixedWidth />;
    default:
      return <FontAwesomeIcon icon={faQuestionCircle} fixedWidth />;
  }
};

const getValveState = pumpState => {
  switch (pumpState) {
    case 'ON':
      return 'OPEN';
    case 'OFF':
      return 'CLOSED';
    default:
      return null;
  }
};

const NutrientMixerPumpButton = ({
  zoneName,
  pumpName,
  nutrientMixerPumpId,
  pumpState,
  isAcidResistant,
  ...props
}) => {
  const {
    openNutrientMixerPrimePumpPrompt,
    NutrientMixerPrimePumpPrompt,
    nutrientMixerPrimePumpPromptProps,
  } = useNutrientMixerPrimePumpPrompt({
    zoneName,
    pumpName,
    nutrientMixerPumpId,
  });

  return (
    <>
      <NutrientMixerPrimePumpPrompt {...nutrientMixerPrimePumpPromptProps} />

      {isAcidResistant ? (
        <PumpButton
          pumpState={pumpState}
          onClick={openNutrientMixerPrimePumpPrompt}
          {...props}
        />
      ) : (
        <ValveButton
          valveState={getValveState(pumpState)}
          onClick={openNutrientMixerPrimePumpPrompt}
          {...props}
        />
      )}
    </>
  );
};

const NutrientMixerPump = ({ pumpState, isAcidResistant, ...props }) =>
  isAcidResistant ? (
    <Pump
      pumpState={pumpState}
      unknownIconClassName={style.pumpUnknownIcon}
      {...props}
    />
  ) : (
    <Valve
      valveState={getValveState(pumpState)}
      unknownIconClassName={style.pumpUnknownIcon}
      {...props}
    />
  );

const NutrientMixerPumps = ({
  zoneId,
  zoneName,
  nutrientMixer,
  className = null,
}) => {
  const [timeOffset, setTimeOffset] = useState(Number.NaN);

  const {
    openZoneNutrientMixerConfigurePumpsForm,
    ZoneNutrientMixerConfigurePumpsForm,
    zoneNutrientMixerConfigurePumpsFormProps,
  } = useZoneNutrientMixerConfigurePumpsForm({ zoneId, zoneName });

  const {
    balancerWaterFlowDetected,
    updateNutrientMixerPumpConfigurationCommand,
  } = nutrientMixer;

  const entireQueue = sortBy(
    nutrientMixer.pumps.flatMap(pump => pump.queue),
    x => x.startTimestamp
  );

  const domain = useMemo(() => {
    const domain = entireQueue.length
      ? [
          moment(entireQueue[entireQueue.length - 1].finishTimestamp).unix(),
          moment(entireQueue[0].startTimestamp).unix(),
        ]
      : [0, 0];

    return domain;
  }, [entireQueue]);

  const scale = scaleLinear()
    .domain(domain)
    .range([0, 100]);

  if (Number.isNaN(timeOffset)) {
    setTimeOffset(domain[1] - moment().unix());
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeOffset(domain[1] - moment().unix());
    }, 1000);

    return () => clearTimeout(timer);
  }, [domain, nutrientMixer]);

  return (
    <div className={[style.container, className].join(' ')}>
      <ZoneNutrientMixerConfigurePumpsForm
        {...zoneNutrientMixerConfigurePumpsFormProps}
      />

      <div className={style.queueContainer}>
        {nutrientMixer.pumps.map(pump => (
          <div key={pump.nutrientMixerPumpId} className={style.queueLane}>
            <div className={style.queueLaneIcon}>
              {pump.isMutuallyExclusive && (
                <FontAwesomeIcon icon={faMinusSquare} fixedWidth />
              )}
            </div>
            <div className={style.queueLaneTitle}>
              {getQueueLaneTitle(pump.role.type)}
            </div>

            <div className={style.queueItemsContainer}>
              {pump.queue.map(queueItem => {
                const topPercent = scale(
                  moment(queueItem.pumpStopTimestamp).unix() + timeOffset
                );

                const heightPercent =
                  scale(
                    moment(queueItem.pumpStartTimestamp).unix() + timeOffset
                  ) - topPercent;

                const timeLeftToStart = moment.duration(
                  moment().unix() - moment(queueItem.pumpStartTimestamp).unix(),
                  'seconds'
                );

                const timeLeftToFinish = moment.duration(
                  moment().unix() - moment(queueItem.pumpStopTimestamp).unix(),
                  'seconds'
                );

                return (
                  <div key={`${queueItem.finishTimestamp}-queue-item`}>
                    <div
                      className={style.queueItem}
                      style={{
                        top: `${topPercent}%`,
                        height: `${heightPercent}%`,
                        '--queue-item-color': getQueueItemColor(pump.role.type),
                      }}
                    ></div>

                    <div
                      className={style.queueItemDisplay}
                      style={{
                        top: `calc(${topPercent}% + var(--top-offset))`,
                        height: `calc(${heightPercent}% + var(--height-offset))`,
                      }}
                    >
                      <div className={style.timeLeftToFinish}>
                        {timeLeftToFinish.asSeconds() < 0
                          ? timeLeftToFinish.abs().format()
                          : ''}
                      </div>

                      <div className={style.timeLeftToStart}>
                        {timeLeftToStart.asSeconds() < 0
                          ? timeLeftToStart.abs().format()
                          : ''}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div
              className={[
                style.pumpContainer,
                pump.role.type === 'DISABLED' ? style.disabled : null,
              ].join(' ')}
            >
              {pump.primeNutrientMixerPumpCommand?.canExecute ? (
                <NutrientMixerPumpButton
                  zoneName={zoneName}
                  pumpName={pump.name}
                  nutrientMixerPumpId={pump.nutrientMixerPumpId}
                  pumpState={pump.state || 'OFF'}
                  isAcidResistant={pump.isAcidResistant ?? false}
                />
              ) : (
                <NutrientMixerPump
                  pumpState={pump.state}
                  isAcidResistant={pump.isAcidResistant ?? false}
                />
              )}
              <div className={style.pumpName}>{pump.name}</div>
              <Pipe
                className={style.pumpOutPipeVertical}
                waterIsFlowing={pump.state === 'ON'}
              />
            </div>
          </div>
        ))}
      </div>

      <Pipe
        className={style.balancerMainPipeHorizontal}
        waterIsFlowing={balancerWaterFlowDetected}
      />

      {updateNutrientMixerPumpConfigurationCommand.canExecute && (
        <Button
          className="rounded"
          onClick={openZoneNutrientMixerConfigurePumpsForm}
        >
          <FontAwesomeIcon className="btn-icon" icon={faCog} fixedWidth /> Pumps
        </Button>
      )}
    </div>
  );
};

NutrientMixerPumps.fragment = gql`
  fragment NutrientMixerPumps on NutrientMixerType {
    balancerWaterFlowDetected
    updateNutrientMixerPumpConfigurationCommand {
      canExecute
    }
    pumps {
      nutrientMixerPumpId
      name
      state
      isAcidResistant
      isMutuallyExclusive
      primeNutrientMixerPumpCommand {
        canExecute
      }
      role {
        type
      }
      currentDose {
        startTimestamp
        pumpStartTimestamp
        pumpStopTimestamp
        finishTimestamp
        state
        reason
      }
      queue {
        startTimestamp
        pumpStartTimestamp
        pumpStopTimestamp
        finishTimestamp
        state
        reason
      }
    }
  }
`;

export default NutrientMixerPumps;
