import React from 'react';
import moment from 'moment';
import {
  Area,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';

import { AxisDomain } from 'recharts/types/util/types';

import style from './style.module.scss';

interface Props<TValues> extends React.HTMLAttributes<HTMLElement> {
  aggregatedValues: Array<AggregatedValuesRecord<TValues>>;
  aggregatedValueSelector: (values: TValues) => string | undefined;
  lowCriticalValue?: string;
  lowValue?: string;
  highValue?: string;
  highCriticalValue?: string;
  lineColor?: string;
}

interface AggregatedValuesRecord<TValues> {
  timestamp: string | number | Date;
  average?: TValues;
  minimum?: TValues;
  maximum?: TValues;
}

const getData = <TValues,>(
  aggregatedValuesRecords: Array<AggregatedValuesRecord<TValues>>,
  aggregatedValueSelector: (values: TValues) => string | undefined
) => {
  return aggregatedValuesRecords.map((aggregatedValuesRecord, index) => {
    const avg =
      aggregatedValuesRecord.average === undefined ||
      aggregatedValuesRecord.average === null
        ? undefined
        : aggregatedValueSelector(aggregatedValuesRecord.average);

    const min =
      aggregatedValuesRecord.minimum === undefined ||
      aggregatedValuesRecord.minimum === null
        ? undefined
        : aggregatedValueSelector(aggregatedValuesRecord.minimum);

    const max =
      aggregatedValuesRecord.maximum === undefined ||
      aggregatedValuesRecord.maximum === null
        ? undefined
        : aggregatedValueSelector(aggregatedValuesRecord.maximum);

    return {
      timestamp: aggregatedValuesRecord.timestamp,
      index,
      avg: avg === undefined ? {} : avg,
      minMax: [min === undefined ? {} : min, max === undefined ? {} : max],
    };
  });
};

export default <TValues,>({
  aggregatedValues,
  aggregatedValueSelector,
  lowCriticalValue,
  lowValue,
  highValue,
  highCriticalValue,
  lineColor = '#544c9f',
  className,
}: Props<TValues>) => {
  const lowCriticalValueNumber =
    lowCriticalValue === undefined ? NaN : Number.parseFloat(lowCriticalValue);

  const lowValueNumber =
    lowValue === undefined ? NaN : Number.parseFloat(lowValue);

  const highValueNumber =
    highValue === undefined ? NaN : Number.parseFloat(highValue);

  const highCriticalValueNumber =
    highCriticalValue === undefined
      ? NaN
      : Number.parseFloat(highCriticalValue);

  const rangeBottom = Math.min(
    Number.isNaN(lowCriticalValueNumber)
      ? lowValueNumber
      : lowCriticalValueNumber,

    Number.isNaN(lowValueNumber) ? lowCriticalValueNumber : lowValueNumber
  );

  const rangeTop = Math.max(
    Number.isNaN(highValueNumber) ? highCriticalValueNumber : highValueNumber,

    Number.isNaN(highCriticalValueNumber)
      ? highValueNumber
      : highCriticalValueNumber
  );

  const range = rangeTop - rangeBottom;
  const domainMinMaxPadding = range * 0.2;

  const data = getData(aggregatedValues, aggregatedValueSelector);

  const ticks = [
    Number.isNaN(lowValueNumber) || Number.isNaN(highValueNumber)
      ? []
      : [lowValueNumber, highValueNumber],

    Number.isNaN(lowCriticalValueNumber) ||
    Number.isNaN(highCriticalValueNumber)
      ? []
      : [lowCriticalValueNumber, highCriticalValueNumber],
  ]
    .flatMap(x => x)
    .sort();

  const tickFormatter = (index: number) => {
    const timestamp = data[index].timestamp;
    const time = moment(timestamp);
    const hour = time.format('h');
    return hour === '12' ? time.format('h a') : hour;
  };

  const domain: AxisDomain = [
    Number.isNaN(lowValueNumber)
      ? 'auto'
      : (dataMin: number) =>
          Number.isFinite(dataMin) && dataMin < rangeBottom
            ? dataMin - domainMinMaxPadding
            : rangeBottom - domainMinMaxPadding,

    Number.isNaN(highValueNumber)
      ? 'auto'
      : (dataMax: number) =>
          Number.isFinite(dataMax) && dataMax > rangeTop
            ? dataMax + domainMinMaxPadding
            : rangeTop + domainMinMaxPadding,
  ];

  return (
    <div className={[style.container, className].join(' ')}>
      <ResponsiveContainer>
        <ComposedChart data={data}>
          <XAxis
            height={25}
            type="number"
            dataKey="index"
            allowDecimals={false}
            tickFormatter={tickFormatter}
            tickLine={true}
            tick={{ fontSize: 11 }}
            interval={0}
            tickCount={data.length}
          />

          <YAxis
            width={27}
            tickLine={true}
            type="number"
            orientation="right"
            ticks={ticks}
            domain={domain}
            tick={{ fontSize: 11 }}
            interval={0}
          />

          <Area
            className={style.area}
            type="monotone"
            stroke="none"
            fill={lineColor}
            dataKey="minMax"
            dot={{
              r: 2,
              fill: lineColor,
              strokeWidth: 0,
              stroke: lineColor,
            }}
            isAnimationActive={false}
          />

          <Line
            type="monotone"
            dataKey="avg"
            stroke={lineColor}
            strokeWidth="4"
            layout="horizontal"
            dot={{
              r: 2,
              fill: lineColor,
              strokeWidth: 0,
              stroke: lineColor,
            }}
            isAnimationActive={false}
          />

          <ReferenceLine
            y={lowCriticalValue}
            stroke="#dc3545"
            strokeDasharray="3 3"
          />

          <ReferenceLine y={lowValue} stroke="#ff9800" strokeDasharray="3 3" />

          <ReferenceLine y={highValue} stroke="#ff9800" strokeDasharray="3 3" />

          <ReferenceLine
            y={highCriticalValue}
            stroke="#dc3545"
            strokeDasharray="3 3"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
