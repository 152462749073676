import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { useQuery } from 'react-apollo';
import { Link, Redirect, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';

import history from '../../../../modules/history';
import defaultFacilityId from '../../../../modules/defaultFacilityId';

import useNutrientMixerPumpConfigureAcidResistacePrompts from '../../../../hooks/useNutrientMixerPumpConfigureAcidResistacePrompts';
import useNutrientMixerPumpConfigureMutexPrompts from '../../../../hooks/useNutrientMixerPumpConfigureMutexPrompts';

import useCalibrateZoneTankForm from '../../../../hooks/useCalibrateHooks/useCalibrateZoneTankForm';
import useCalibrateZoneCollectorTankForm from '../../../../hooks/useCalibrateHooks/useCalibrateZoneCollectorTankForm';
import useCalibrateOzoneSystemTankForm from '../../../../hooks/useCalibrateHooks/useCalibrateOzoneSystemTankForm';

import PageLoadingContainer from '../../../../components/PageLoadingContainer';
import FacilitySelect from '../../../../components/FacilitySelect';
import ZoneButton from '../../../../components/ZoneButton';

import style from './style.module.scss';

const FACILITY_QUERY = gql`
  query facilityConfigPageQuery($facilityId: ID!) {
    settings {
      facilityConfiguration {
        facility(facilityId: $facilityId) {
          facilityId
          zones {
            zoneId
            name
          }
          pumpRoom {
            ozoneSystems {
              ozoneSystemId
              name
              tank {
                autoCalibrateOzoneSystemTankPressureSensorCommand {
                  canExecute
                }
              }
            }
          }
        }
      }
    }
    isLegacy
  }
`;

const ZONE_QUERY = gql`
  query facilityConfigPageZoneQuery($facilityId: ID!, $zoneName: String!) {
    settings {
      facilityConfiguration {
        facility(facilityId: $facilityId) {
          facilityId
          zone(zoneName: $zoneName) {
            zoneId
            name
            nutrientMixer {
              pumps {
                nutrientMixerPumpId
                name
                isAcidResistant
                isMutuallyExclusive
              }
            }
            tank {
              autoCalibrateZoneTankPressureSensorCommand {
                canExecute
              }
            }
            collectorTank {
              autoCalibrateZoneAttachedCollectorTankPressureSensorCommand {
                canExecute
              }
            }
          }
        }
      }
    }
  }
`;

const IsMutuallyExclusiveButton = ({
  zoneName,
  nutrientMixerPumpName,
  nutrientMixerPumpId,
  ...props
}) => {
  const {
    openNutrientMixerPumpDisableMutexPrompt,
    NutrientMixerPumpConfigureMutexPrompts,
    nutrientMixerPumpConfigureMutexPromptProps,
  } = useNutrientMixerPumpConfigureMutexPrompts({
    zoneName,
    nutrientMixerPumpName,
    nutrientMixerPumpId,
  });

  return (
    <>
      <NutrientMixerPumpConfigureMutexPrompts
        {...nutrientMixerPumpConfigureMutexPromptProps}
      />
      <button onClick={openNutrientMixerPumpDisableMutexPrompt} {...props}>
        <FontAwesomeIcon icon={faCheck} fixedWidth />{' '}
      </button>
    </>
  );
};

const IsNotMutuallyExclusiveButton = ({
  zoneName,
  nutrientMixerPumpName,
  nutrientMixerPumpId,
  ...props
}) => {
  const {
    openNutrientMixerPumpEnableMutexPrompt,
    NutrientMixerPumpConfigureMutexPrompts,
    nutrientMixerPumpConfigureMutexPromptProps,
  } = useNutrientMixerPumpConfigureMutexPrompts({
    zoneName,
    nutrientMixerPumpName,
    nutrientMixerPumpId,
  });

  return (
    <>
      <NutrientMixerPumpConfigureMutexPrompts
        {...nutrientMixerPumpConfigureMutexPromptProps}
      />
      <button onClick={openNutrientMixerPumpEnableMutexPrompt} {...props}>
        <FontAwesomeIcon icon={faTimes} fixedWidth />{' '}
      </button>
    </>
  );
};

const IsAcidResistantButton = ({
  zoneName,
  nutrientMixerPumpName,
  nutrientMixerPumpId,
  ...props
}) => {
  const {
    openNutrientMixerPumpDisableAcidResistancePrompt,
    NutrientMixerPumpConfigureAcidResistancePrompts,
    nutrientMixerPumpConfigureAcidResistancePromptsProps,
  } = useNutrientMixerPumpConfigureAcidResistacePrompts({
    zoneName,
    nutrientMixerPumpName,
    nutrientMixerPumpId,
  });
  return (
    <>
      <NutrientMixerPumpConfigureAcidResistancePrompts
        {...nutrientMixerPumpConfigureAcidResistancePromptsProps}
      />

      <button
        onClick={openNutrientMixerPumpDisableAcidResistancePrompt}
        {...props}
      >
        <FontAwesomeIcon icon={faCheck} fixedWidth />{' '}
      </button>
    </>
  );
};

const IsNotAcidResistantButton = ({
  zoneName,
  nutrientMixerPumpName,
  nutrientMixerPumpId,
  ...props
}) => {
  const {
    openNutrientMixerPumpEnableAcidResistancePrompt,
    NutrientMixerPumpConfigureAcidResistancePrompts,
    nutrientMixerPumpConfigureAcidResistancePromptsProps,
  } = useNutrientMixerPumpConfigureAcidResistacePrompts({
    zoneName,
    nutrientMixerPumpName,
    nutrientMixerPumpId,
  });
  return (
    <>
      <NutrientMixerPumpConfigureAcidResistancePrompts
        {...nutrientMixerPumpConfigureAcidResistancePromptsProps}
      />

      <button
        onClick={openNutrientMixerPumpEnableAcidResistancePrompt}
        {...props}
      >
        <FontAwesomeIcon icon={faTimes} fixedWidth />{' '}
      </button>
    </>
  );
};

const ZoneContent = ({ facilityId, zoneName, isLegacy }) => {
  const result = useQuery(ZONE_QUERY, { variables: { facilityId, zoneName } });
  const { settings } = result.data || {};
  const { facilityConfiguration } = settings || {};
  const zone = facilityConfiguration?.facility.zone;
  const zoneId = zone?.zoneId;

  const canExecuteCalibrateZoneTankPressureCommand =
    zone?.tank?.autoCalibrateZoneTankPressureSensorCommand?.canExecute ?? false;

  const canExecuteCalibrateZoneCollectorTankPressureCommand =
    zone?.collectorTank
      ?.autoCalibrateZoneAttachedCollectorTankPressureSensorCommand
      ?.canExecute ?? false;

  const {
    openCalibrateZoneTankForm,
    CalibrateZoneTankForm,
    calibrateZoneTankFormProps,
  } = useCalibrateZoneTankForm({ zoneId, zoneName, isLegacy });

  const {
    openCalibrateZoneCollectorTankForm,
    CalibrateZoneCollectorTankForm,
    calibrateZoneCollectorTankFormProps,
  } = useCalibrateZoneCollectorTankForm({ zoneId, zoneName, isLegacy });

  return (
    <>
      <CalibrateZoneTankForm {...calibrateZoneTankFormProps} />
      <CalibrateZoneCollectorTankForm
        {...calibrateZoneCollectorTankFormProps}
      />
      <PageLoadingContainer
        resourceTypeName="Zone"
        result={result}
        resourceExists={!!zone}
        render={() => (
          <div className={style.zoneContent}>
            <div className={style.zoneConfigurationPanel}>
              <h5>
                <Link to={`/facility/${facilityId}/zone/${zoneName}`}>
                  Nutrient Mixer Pumps
                </Link>
              </h5>

              <div className={style.pumps}>
                <div className={style.pumpContainer}>
                  <div
                    className={[style.indicator, style.placeholder].join(' ')}
                  ></div>

                  <div className={style.pumpSettingLabel}>
                    Acid
                    <br />
                    Resistant
                  </div>
                </div>

                {zone.nutrientMixer.pumps.map(
                  ({ nutrientMixerPumpId, name, isAcidResistant }) => (
                    <div
                      key={nutrientMixerPumpId}
                      className={style.pumpContainer}
                    >
                      <div className={[style.indicator, style.pump].join(' ')}>
                        {name}
                      </div>

                      {isAcidResistant ? (
                        <IsAcidResistantButton
                          className={[
                            style.indicator,
                            style.activePumpSetting,
                          ].join(' ')}
                          zoneName={zone.name}
                          nutrientMixerPumpName={name}
                          nutrientMixerPumpId={nutrientMixerPumpId}
                        />
                      ) : (
                        <IsNotAcidResistantButton
                          className={[
                            style.indicator,
                            style.inactivePumpSetting,
                          ].join(' ')}
                          zoneName={zone.name}
                          nutrientMixerPumpName={name}
                          nutrientMixerPumpId={nutrientMixerPumpId}
                        />
                      )}
                    </div>
                  )
                )}

                <div className={style.pumpContainer}>
                  <div
                    className={[style.indicator, style.placeholder].join(' ')}
                  ></div>
                </div>
              </div>
            </div>

            <div className={style.pumps}>
              <div className={style.pumpContainer}>
                <div
                  className={[style.indicator, style.placeholder].join(' ')}
                ></div>

                <div className={style.pumpSettingLabel}>
                  Mutual
                  <br />
                  Exclusion
                </div>
              </div>

              {zone.nutrientMixer.pumps.map(
                ({ nutrientMixerPumpId, name, isMutuallyExclusive }) => (
                  <div
                    key={nutrientMixerPumpId}
                    className={style.pumpContainer}
                  >
                    <div className={[style.indicator, style.pump].join(' ')}>
                      {name}
                    </div>

                    {isMutuallyExclusive ? (
                      <IsMutuallyExclusiveButton
                        className={[
                          style.indicator,
                          style.activePumpSetting,
                        ].join(' ')}
                        zoneName={zone.name}
                        nutrientMixerPumpName={name}
                        nutrientMixerPumpId={nutrientMixerPumpId}
                      />
                    ) : (
                      <IsNotMutuallyExclusiveButton
                        className={[
                          style.indicator,
                          style.inactivePumpSetting,
                        ].join(' ')}
                        zoneName={zone.name}
                        nutrientMixerPumpName={name}
                        nutrientMixerPumpId={nutrientMixerPumpId}
                      />
                    )}
                  </div>
                )
              )}

              <div className={style.pumpContainer}>
                <div
                  className={[style.indicator, style.placeholder].join(' ')}
                ></div>
              </div>
            </div>

            <div className={style.zoneConfigurationPanel}>
              <h5>
                <Link to={`/facility/${facilityId}/zone/${zoneName}`}>
                  Zone Tank Calibration
                </Link>
              </h5>
              <Button className="rounded" onClick={openCalibrateZoneTankForm}>
                {`Calibrate Distribution Tank`}
              </Button>
              {canExecuteCalibrateZoneCollectorTankPressureCommand && (
                <Button
                  className="rounded"
                  onClick={openCalibrateZoneCollectorTankForm}
                >
                  {`Calibrate Return Tank`}
                </Button>
              )}
            </div>
          </div>
        )}
      />
    </>
  );
};

const CalibrateOzoneSystemTankButton = ({ ozoneSystemId, ozoneSystemName }) => {
  const {
    openCalibrateOzoneSystemTankForm,
    CalibrateOzoneSystemTankForm,
    calibrateOzoneSystemTankFormProps,
  } = useCalibrateOzoneSystemTankForm({ ozoneSystemId, ozoneSystemName });

  return (
    <>
      <CalibrateOzoneSystemTankForm {...calibrateOzoneSystemTankFormProps} />

      <Button className="rounded" onClick={openCalibrateOzoneSystemTankForm}>
        {`Calibrate Ozone Tank ${ozoneSystemName}`}
      </Button>
    </>
  );
};

const PageContent = ({ facilityId, facilities }) => {
  const match = useRouteMatch({
    path: '/settings/facility/:facilityId/zone/:zoneName',
  });

  const result = useQuery(FACILITY_QUERY, { variables: { facilityId } });

  var { settings, isLegacy } = result.data || {};
  var { facilityConfiguration } = settings || {};
  var facility = facilityConfiguration?.facility;

  if (facility && !match && !result.loading && facility.zones.length) {
    return (
      <Redirect
        to={`/settings/facility/${facilityId}/zone/${facility.zones[0].name}`}
      />
    );
  }

  const zoneName = match?.params.zoneName;

  const ozoneSystems = facility?.pumpRoom?.ozoneSystems;
  const canExecuteCalibrateOzoneSystemTankCommand = ozoneSystems?.some(
    x => x.tank?.autoCalibrateOzoneSystemTankPressureSensorCommand?.canExecute
  );

  const showOzoneSystemConfigurationPanel = canExecuteCalibrateOzoneSystemTankCommand;

  return (
    <div className={style.container}>
      <FacilitySelect
        facilities={facilities}
        value={facilityId}
        onChange={e => history.push(`/settings/facility/${e.target.value}`)}
      />

      <PageLoadingContainer
        resourceTypeName="Facility"
        result={result}
        resourceExists={!!facility}
        render={() => (
          <>
            <div className={style.zoneNavigation}>
              {facility.zones.map(({ zoneId, name }) => (
                <ZoneButton
                  className={style.zoneButton}
                  key={zoneId}
                  tag={Link}
                  to={`/settings/facility/${facilityId}/zone/${name}`}
                  zoneName={name}
                  active={name === zoneName}
                />
              ))}
            </div>

            <ZoneContent
              facilityId={facilityId}
              zoneName={zoneName}
              isLegacy={isLegacy}
            />

            {showOzoneSystemConfigurationPanel && (
              <div className={style.ozoneSystemConfigurationPanel}>
                <h5>Ozone System Tank Calibration</h5>
                {canExecuteCalibrateOzoneSystemTankCommand &&
                  ozoneSystems.map((ozoneSystem, index) => (
                    <CalibrateOzoneSystemTankButton
                      ozoneSystemId={ozoneSystem.ozoneSystemId}
                      ozoneSystemName={ozoneSystem.name}
                      key={index}
                    />
                  ))}
              </div>
            )}
          </>
        )}
      />
    </div>
  );
};

export default () => {
  const match = useRouteMatch({ path: '/settings/facility/:facilityId' });

  return (
    <Query
      query={gql`
        {
          settings {
            facilityConfiguration {
              facilities {
                facilityId
                name
              }
            }
          }
        }
      `}
    >
      {result => {
        var { settings } = result.data || {};
        var { facilityConfiguration } = settings || {};
        var facilities = facilityConfiguration?.facilities || [];

        var requestedFacilityId = match?.params.facilityId;
        const validFacilityIds = facilities.map(x => x.facilityId);

        return (
          <PageLoadingContainer
            resourceTypeName="Facility Config"
            result={result}
            resourceExists={
              facilities.length &&
              (requestedFacilityId === undefined ||
                validFacilityIds.includes(requestedFacilityId))
            }
            render={() => {
              if (requestedFacilityId === undefined) {
                const facilityId = validFacilityIds.includes(
                  defaultFacilityId.get()
                )
                  ? defaultFacilityId.get()
                  : validFacilityIds[0];

                return <Redirect to={`/settings/facility/${facilityId}`} />;
              }

              return (
                <PageContent
                  facilityId={requestedFacilityId}
                  facilities={facilities}
                />
              );
            }}
          />
        );
      }}
    </Query>
  );
};
