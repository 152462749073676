import React, { Dispatch, FC, SetStateAction } from 'react';
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Input as FormInput,
} from 'reactstrap';
import { getInputValueAnd, updateState } from '../../modules/form-helpers';

interface Props {
  className: string;
  sensorHeight: any;
  pressureSensorHeight: any;
  isDirtyState: [boolean, Dispatch<SetStateAction<boolean>>];
  setAutoCalibrationPoints: (height: any) => void;
  label: string;
  id: string;
}

const CalibrationForm: FC<Props> = ({
  className,
  sensorHeight,
  pressureSensorHeight,
  isDirtyState,
  setAutoCalibrationPoints,
  label,
  id,
}: Props): JSX.Element => {
  return (
    <FormGroup>
      <Label for={id}>{label}</Label>
      <InputGroup>
        <FormInput
          id={id}
          type="number"
          required
          className={className}
          invalid={!!sensorHeight}
          value={pressureSensorHeight}
          onChange={getInputValueAnd(
            updateState({
              valueState: [
                pressureSensorHeight,
                (value: string | undefined) => {
                  const height =
                    value === undefined ? Number.NaN : Number.parseFloat(value);
                  !Number.isNaN(height) && setAutoCalibrationPoints(height);
                },
              ],
              isDirtyState,
            })
          )}
        />
        <InputGroupAddon addonType="append">
          <InputGroupText>in</InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    </FormGroup>
  );
};

export default CalibrationForm;
