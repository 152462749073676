import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { Alert } from 'reactstrap';

import style from './style.module.scss';

interface Props extends React.HtmlHTMLAttributes<HTMLElement> {
  success?: boolean;
}

export default ({ children, success = false, className }: Props) => {
  const color = success ? 'success' : 'danger';
  const icon = success ? faCheck : faExclamationTriangle;

  return (
    <Alert
      className={[
        style.alert,
        success ? style.success : style.error,
        className,
      ].join(' ')}
      color={color}
      fade={false}
    >
      <FontAwesomeIcon
        className={style.icon}
        icon={icon}
        fixedWidth={true}
        size={'lg'}
      />
      <div className={style.text}>{children}</div>
    </Alert>
  );
};
