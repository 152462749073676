import React, { FC, useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery, useSubscription } from 'react-apollo';
import useModalVisible from '../../useModalVisible';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import { isEmpty } from 'lodash';

import { SaveModal } from '../../../components/SaveModal';
import ModalTitle from '../../../components/ModalTitle';
import ModalLoadingContainer from '../../../components/ModalLoadingContainer';
import Switch from '../../../components/Switch';

import { getValidationErrors } from '../../../modules/errors';

import style from '../style.module.scss';
import AutoCalibrationForm from '../../../components/AutoCalibrationForm';
import ManualCalibrationForm from '../../../components/ManualCalibrationForm';

import {
  createAutoCalibrateTankInput,
  createAutoCalibrationPointsFromData,
  createBlankAutoCalibrationPoints,
} from '../../utility/createCalibrationPoints';
import { Input } from '../../types/ZoneSystemName';
import { GET_ZONE } from '../../gql/getZone';
import { SUBSCRIPTION_ZONE } from '../../gql/subscribeZone';
import { ADD_AUTO_CALIBRATE_ZONE_TANK_PRESSURE_SENSOR } from '../../gql/addAutoCalibrateZoneTankPressureSensor';
import { ADD_MANUAL_CALIBRATE_ZONE_TANK_PRESSURE_SENSOR } from '../../gql/addManualCalibrateZoneTankPressureSensor';

interface Result {
  openCalibrateZoneTankForm: () => void;
  CalibrateZoneTankForm: typeof CalibrateZoneTankForm;
  calibrateZoneTankFormProps: Props;
}

interface Props extends Input {
  calibrateZoneTankFormIsOpen: boolean;
  closeCalibrateZoneTankForm: () => void;
}

const CalibrateZoneTankForm: FC<Props> = ({
  zoneName,
  zoneId,
  calibrateZoneTankFormIsOpen,
  closeCalibrateZoneTankForm,
  isLegacy,
  onCompleted,
}: Props): JSX.Element => {
  const [visible, modalVisibleProps] = useModalVisible();

  const result = useQuery(GET_ZONE, {
    variables: { zoneId },
    skip: !visible,
  });

  useSubscription(SUBSCRIPTION_ZONE, {
    variables: { zoneId },
    skip: !visible,
  });

  const [
    autoCalibrateZoneTank,
    { error: autoCalibrateSaveError, loading: autoCalibrateSaving },
  ] = useMutation(ADD_AUTO_CALIBRATE_ZONE_TANK_PRESSURE_SENSOR, {
    onCompleted: () => {
      onCompleted && onCompleted();
    },
  });

  const [
    manualCalibrateZoneTank,
    { error: manualCalibrateSaveError, loading: manualCalibrateSaving },
  ] = useMutation(ADD_MANUAL_CALIBRATE_ZONE_TANK_PRESSURE_SENSOR, {
    onCompleted: () => {
      onCompleted && onCompleted();
    },
  });

  const { refetch } = result;
  const [wasOpen, setWasOpen] = useState(false);
  const [saveAttempted, setSaveAttempted] = useState(false);

  const isDirtyState = useState(false);
  const [isDirty, setIsDirty] = isDirtyState;

  const [autoCalibrate, setAutoCalibrate] = useState(false);
  const [manualCalibrationPoints, setManualCalibrationPoints] = useState(
    new Array<number>()
  );
  const [autoCalibrationPoints, setAutoCalibrationPoints] = useState(
    createBlankAutoCalibrationPoints()
  );
  const [currentWaterDepth, setCurrentWaterDepth] = useState(0);

  useEffect(() => {
    const reopened = calibrateZoneTankFormIsOpen && !wasOpen;
    if (reopened) {
      refetch();
    }
    setWasOpen(calibrateZoneTankFormIsOpen);
  }, [calibrateZoneTankFormIsOpen, refetch, wasOpen, setWasOpen]);

  const { data, loading } = result;
  const zone = data?.zone || null;
  const tank = zone?.tank || null;

  useEffect(() => {
    if (tank) {
      setAutoCalibrate(tank.autoCalibrated ?? false);
      if (tank.autoCalibrated) {
        setAutoCalibrationPoints(
          createAutoCalibrationPointsFromData(tank.calibrationPoints)
        );
        setAutoCalibrate(true);
      } else {
        setManualCalibrationPoints(
          tank.calibrationPoints
            .map((value: number) => {
              return value.toFixed(2);
            })
            .reverse()
        );
        setAutoCalibrate(false);
      }
    }
  }, [tank]);

  const addCalibrationPoints = async (currentWaterDepth: number) => {
    tank.calibrationPoints.push(currentWaterDepth);
    await manualCalibrateZoneTank({
      variables: {
        input: {
          zoneId: zoneId,
          currentWaterDepth: currentWaterDepth,
          clearPreviousOffsets: false,
        },
      },
    });

    setManualCalibrationPoints(
      tank.calibrationPoints
        .map((value: number | string) => {
          return parseFloat(value.toString()).toFixed(2);
        })
        .reverse()
    );
  };

  const removeCalibrationPoints = async () => {
    setManualCalibrationPoints((tank.calibrationPoints = []));

    await manualCalibrateZoneTank({
      variables: {
        input: {
          zoneId: zoneId,
          currentWaterDepth: -1,
          clearPreviousOffsets: true,
        },
      },
    });
  };

  const saving = autoCalibrateSaving || manualCalibrateSaving;
  const saveError = autoCalibrateSaveError || manualCalibrateSaveError;

  const autoCalibrateValidationErrors = {
    ...(isEmpty(autoCalibrationPoints.lLPressureSensorHeight) && {
      lLSensorHeight: 'LL Sensor height is required',
    }),
    ...(isEmpty(autoCalibrationPoints.lPressureSensorHeight) && {
      lSensorHeight: 'L Sensor height is required',
    }),
    ...(isEmpty(autoCalibrationPoints.hPressureSensorHeight) && {
      hSensorHeight: 'H Sensor height is required',
    }),
    ...(isEmpty(autoCalibrationPoints.hHPressureSensorHeight) && {
      hHSensorHeight: 'HH Sensor height is required',
    }),
    ...(parseFloat(autoCalibrationPoints.lLPressureSensorHeight ?? '') >
      parseFloat(autoCalibrationPoints.lPressureSensorHeight ?? '') && {
      lLSensorHeight: 'LL Sensor height cannot be greater than L Sensor height',
    }),
    ...(parseFloat(autoCalibrationPoints.lPressureSensorHeight ?? '') >
      parseFloat(autoCalibrationPoints.hPressureSensorHeight ?? '') && {
      lSensorHeight: 'L Sensor height cannot be greater than H Sensor height',
    }),
    ...(parseFloat(autoCalibrationPoints.hPressureSensorHeight ?? '') >
      parseFloat(autoCalibrationPoints.hHPressureSensorHeight ?? '') && {
      hSensorHeight: 'H Sensor height cannot be greater than HH Sensor height',
    }),
    ...(!isDirty &&
      saveAttempted &&
      saveError &&
      getValidationErrors(saveError)),
  };

  const clearPreviousOffsets = false;

  const manualCalibrateValidationErrors = {
    ...(manualCalibrationPoints.length > 3 &&
      clearPreviousOffsets === false && {
        clearOldCalibrationData:
          'Calibration data must be cleared before adding more points',
      }),
  };

  const hasValidationErrors =
    (autoCalibrate && !isEmpty(autoCalibrateValidationErrors)) ||
    (!autoCalibrate && !isEmpty(manualCalibrateValidationErrors));

  const isFormValid = !loading && !hasValidationErrors;

  return (
    <SaveModal
      className={style.modal}
      scrollable
      title={
        <ModalTitle
          icon={<FontAwesomeIcon icon={faCog} />}
          title={`Calibration Zone ${zoneName} Tank`}
        />
      }
      isOpen={calibrateZoneTankFormIsOpen}
      isFormValid={isFormValid}
      saving={saving}
      error={saveError}
      hideSaveButton={true && !autoCalibrate}
      showCloseButton={true}
      onComplete={() => {
        setAutoCalibrationPoints(createBlankAutoCalibrationPoints());
        setManualCalibrationPoints(new Array<number>());
        setCurrentWaterDepth(0);
        closeCalibrateZoneTankForm();
      }}
      onSave={async () => {
        setAutoCalibrationPoints(createBlankAutoCalibrationPoints());
        setManualCalibrationPoints(new Array<number>());
        setCurrentWaterDepth(0);
        setIsDirty(false);
        setSaveAttempted(true);
        if (autoCalibrate) {
          autoCalibrateZoneTank({
            variables: {
              input: createAutoCalibrateTankInput(
                zoneId,
                'zoneId',
                autoCalibrationPoints
              ),
            },
          });
        }
      }}
      {...modalVisibleProps}
    >
      <ModalLoadingContainer
        className={style.calibrationContainer}
        resourceTypeName="Zone"
        result={result}
        resourceExists={!!zone}
      >
        {tank && !isLegacy && (
          <>
            <p className={style.calibrationModeLabel}>Auto-Calibration</p>
            <Switch
              id="calibrationModeSwitch"
              className={style.calibrationModeSwitch}
              onChange={(checked: boolean) => setAutoCalibrate(checked)}
              checked={autoCalibrate}
              disabled={false}
            />
          </>
        )}
        {isLegacy && <div className="pt-4"></div>}
        {tank &&
          (autoCalibrate ? (
            <>
              <AutoCalibrationForm
                className={style}
                autoCalibrationPoints={autoCalibrationPoints}
                autoCalibrateValidationErrors={autoCalibrateValidationErrors}
                isDirtyState={isDirtyState}
                setAutoCalibrationPoints={setAutoCalibrationPoints}
              />
            </>
          ) : (
            <>
              <ManualCalibrationForm
                className={style}
                manualCalibrationPoints={manualCalibrationPoints}
                isDirtyState={isDirtyState}
                addCalibrationPoints={addCalibrationPoints}
                currentWaterDepth={currentWaterDepth}
                setCurrentWaterDepth={setCurrentWaterDepth}
                removeCalibrationPoints={removeCalibrationPoints}
              />
            </>
          ))}
      </ModalLoadingContainer>
    </SaveModal>
  );
};

const useCalibrateZoneTankForm: (input: Input) => Result = ({
  zoneName,
  zoneId,
  onCompleted,
  isLegacy,
}) => {
  const [
    calibrateZoneTankFormIsOpen,
    setCalibrateZoneTankFormIsOpen,
  ] = useState(false);

  return {
    openCalibrateZoneTankForm: useCallback(
      () => setCalibrateZoneTankFormIsOpen(true),
      [setCalibrateZoneTankFormIsOpen]
    ),
    CalibrateZoneTankForm,
    calibrateZoneTankFormProps: {
      zoneName,
      zoneId,
      calibrateZoneTankFormIsOpen,
      onCompleted,
      isLegacy,
      closeCalibrateZoneTankForm: useCallback(
        () => setCalibrateZoneTankFormIsOpen(false),
        [setCalibrateZoneTankFormIsOpen]
      ),
    },
  };
};

export default useCalibrateZoneTankForm;
