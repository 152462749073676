import React from 'react';
import gql from 'graphql-tag';
import { Button } from 'reactstrap';

import useStackHarvestScheduleForm from '../../../../../../hooks/useStackHarvestScheduleForm';
import useStackValveTestPrompt from '../../../../../../hooks/useStackValveTestPrompt';
import useStackCleanInPlaceForm from '../../../../../../hooks/useStackCleanInPlaceForm';

import HarvestScheduleIcon from '../../../../../../components/HarvestScheduleIcon';
import ValveTestIcon from '../../../../../../components/ValveTestIcon';
import CleanInPlaceIcon from '../../../../../../components/CleanInPlaceIcon';

import style from './style.module.scss';

const StackCommands = ({ zoneName, stack, className }) => {
  const {
    openScheduleHarvestForm,
    StackHarvestScheduleForm,
    stackHarvestScheduleFormProps,
  } = useStackHarvestScheduleForm({
    zoneName: zoneName,
    stackName: stack.name,
    stackId: stack.stackId,
    enablePostHarvestActions: stack.collectorTank.zone != null,
  });

  const {
    openStackValveTestPrompt,
    StackValveTestPrompt,
    stackValveTestPromptProps,
  } = useStackValveTestPrompt({
    zoneName: zoneName,
    stackName: stack.name,
    stackId: stack.stackId,
  });

  const {
    openStackCleanInPlaceForm,
    StackCleanInPlaceForm,
    stackCleanInPlaceFormProps,
  } = useStackCleanInPlaceForm({
    zoneName: zoneName,
    stackName: stack.name,
    stackId: stack.stackId,
  });

  const {
    updateStackHarvestScheduleTimeCommand,
    harvestSchedule,
    performStackValveTestCommand,
    performStackCleanInPlaceCommand,
  } = stack;

  return (
    <div className={[style.container, className].join(' ')}>
      <StackHarvestScheduleForm {...stackHarvestScheduleFormProps} />

      <StackValveTestPrompt {...stackValveTestPromptProps} />

      <StackCleanInPlaceForm {...stackCleanInPlaceFormProps} />

      {!harvestSchedule && updateStackHarvestScheduleTimeCommand.canExecute && (
        <Button onClick={openScheduleHarvestForm}>
          <HarvestScheduleIcon />

          <span>Schedule Harvest</span>
        </Button>
      )}

      {performStackValveTestCommand.canExecute && (
        <Button onClick={openStackValveTestPrompt}>
          <ValveTestIcon />

          <span>Test Valves</span>
        </Button>
      )}

      {performStackCleanInPlaceCommand.canExecute && (
        <Button onClick={openStackCleanInPlaceForm}>
          <CleanInPlaceIcon />

          <span>Clean In Place</span>
        </Button>
      )}
    </div>
  );
};

StackCommands.fragment = gql`
  fragment StackCommands on StackType {
    name
    stackId
    harvestSchedule {
      __typename
    }
    updateStackHarvestScheduleTimeCommand {
      canExecute
    }
    performStackValveTestCommand {
      canExecute
    }
    performStackCleanInPlaceCommand {
      canExecute
    }
  }
`;

export default StackCommands;
