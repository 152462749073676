import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import { Col, Form, FormGroup, Label, Input, Button, Row } from 'reactstrap';

import useProfileChangePasswordForm from '../../../../hooks/useProfileChangePasswordForm';

import PageLoadingContainer from '../../../../components/PageLoadingContainer';

import style from './style.module.scss';

const PROFILE_QUERY = gql`
  query {
    settings {
      profile {
        firstName
        lastName
        email
        role {
          name
        }
        facilities {
          facilityId
          name
        }
      }
    }
  }
`;

export default () => {
  var result = useQuery(PROFILE_QUERY);

  var {
    openProfileChangePasswordForm,
    ProfileChangePasswordForm,
    profileChangePasswordFormProps,
  } = useProfileChangePasswordForm({});

  var { settings } = result.data || {};
  var { profile } = settings || {};

  return (
    <PageLoadingContainer
      resourceTypeName="Profile"
      result={result}
      resourceExists={!!profile}
      render={() => {
        return (
          <div className={style.container}>
            <ProfileChangePasswordForm {...profileChangePasswordFormProps} />

            <Form className={style.form}>
              <Row form>
                <Col sm={6}>
                  <FormGroup>
                    <Label for="firstName">First Name</Label>
                    <Input
                      id="firstName"
                      value={profile.firstName}
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup>
                    <Label for="lastName">Last Name</Label>
                    <Input
                      id="lastName"
                      value={profile.lastName}
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col sm={6}>
                  <FormGroup>
                    <Label for="email">Email Address / Username</Label>
                    <Input id="email" value={profile.email} readOnly={true} />
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup>
                    <Label for="role">Role</Label>
                    <Input
                      id="role"
                      value={profile.role?.name}
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>

            <div className={style.buttonContainer}>
              <Button onClick={openProfileChangePasswordForm}>
                Change Password
              </Button>
            </div>

            <Form className={style.form}>
              <Row form>
                <Col sm={12}>
                  <FormGroup>
                    <Label for="facilities">Facilities</Label>
                    <div
                      id="facilities"
                      readOnly
                      className={[
                        'form-control',
                        style.facilitiesContainer,
                      ].join(' ')}
                    >
                      {profile.facilities?.map(({ facilityId, name }) => (
                        <Link key={facilityId} to={`/facility/${facilityId}`}>
                          {name}
                        </Link>
                      ))}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    />
  );
};
