import React from 'react';

import UnknownValueOverlay from '../UnknownValueOverlay';

import style from './style.module.scss';

export default props => {
  const { pumpState, className, unknownIconClassName } = props;
  const hasUnknownValue = pumpState !== 'ON' && pumpState !== 'OFF';
  return (
    <UnknownValueOverlay
      hasUnknownValue={hasUnknownValue}
      className={[
        style.container,
        pumpState === 'ON' ? style.pumpOn : null,
        hasUnknownValue ? style.hasUnknownValue : null,
        className,
      ].join(' ')}
      iconClassName={[style.unknownIcon, unknownIconClassName].join(' ')}
    >
      <svg
        className={style.pumpIcon}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        preserveAspectRatio="xMidYMid"
        viewBox="0 0 100 100"
      >
        <g transform="rotate(165.527 50 50)">
          <path d="m 38.361745,98.178937 6.90939,-27.134112 -17.021748,10.112362 10.112358,17.02175" />
          <path
            d="M 41.828693,80.944916 C 56.05587,84.695506 70.951758,77.874886 77.406584,64.655258 83.861328,51.435797 80.077186,35.499525 68.371491,26.58644 56.665796,17.673354 40.297079,18.264602 29.270844,28.003185 18.244468,37.741892 15.631892,53.915413 23.031797,66.632366 l -7.580934,4.409999 C 5.9468044,54.709363 9.3022633,33.936923 23.463974,21.429028 37.625504,8.9212916 58.648645,8.1619228 73.682838,19.609432 88.717032,31.056942 93.57719,51.524671 85.287048,68.503068 76.996801,85.48168 57.865287,94.241734 39.592631,89.424669 l 2.236062,-8.479753"
            style={{
              stroke: 'none',
              strokeWidth: 0.644893,
              strokeMiterlimit: 4,
              strokeDasharray: 'none',
              fillOpacity: 1,
            }}
          />
        </g>
      </svg>
    </UnknownValueOverlay>
  );
};
