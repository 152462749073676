import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  roleName: string;
  roleId: string;
  onCompleted?: () => void;
}

interface Result {
  openRoleRestorePrompt: () => void;
  RoleRestorePrompt: typeof RoleRestorePrompt;
  roleRestorePromptProps: Props;
}

const RESTORE_DELETED_ROLE = gql`
  mutation restoreDeletedRole($input: RestoreDeletedRoleInputType!) {
    restoreDeletedRole(input: $input) {
      role {
        roleId
        deleted
      }
    }
  }
`;

interface Props extends Input {
  roleRestorePromptIsOpen: boolean;
  closeRoleRestorePrompt: () => void;
}

const RoleRestorePrompt = ({
  roleName,
  roleId,
  roleRestorePromptIsOpen,
  closeRoleRestorePrompt,
  onCompleted,
}: Props) => {
  const [restoreRole, { error, loading: saving }] = useMutation(
    RESTORE_DELETED_ROLE,
    {
      onCompleted: () => {
        closeRoleRestorePrompt();
        onCompleted && onCompleted();
      },
    }
  );

  return (
    <PromptModal
      title={`Restore role ${roleName}?`}
      isOpen={roleRestorePromptIsOpen}
      saving={saving}
      error={error}
      onSave={() => {
        restoreRole({
          variables: {
            input: {
              roleId,
            },
          },
        });
      }}
      onComplete={closeRoleRestorePrompt}
    >
      <p>
        {`Restoring role ${roleName} will allow users to be assigned this role, and grant its permissions to any users who have it.`}
      </p>

      <p>{`Restore role ${roleName}?`}</p>
    </PromptModal>
  );
};

const useRoleRestorePrompt: (input: Input) => Result = ({
  roleName,
  roleId,
  onCompleted,
}) => {
  const [roleRestorePromptIsOpen, setRoleRestorePromptIsOpen] = useState(false);

  return {
    openRoleRestorePrompt: useCallback(() => setRoleRestorePromptIsOpen(true), [
      setRoleRestorePromptIsOpen,
    ]),
    RoleRestorePrompt,
    roleRestorePromptProps: {
      roleName,
      roleId,
      roleRestorePromptIsOpen,
      onCompleted,
      closeRoleRestorePrompt: useCallback(
        () => setRoleRestorePromptIsOpen(false),
        [setRoleRestorePromptIsOpen]
      ),
    },
  };
};

export default useRoleRestorePrompt;
