import React, { useState, useCallback, useEffect } from 'react';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

import Pump from '../../components/Pump';

import style from './style.module.scss';
import Loading from '../../components/Loading';
import ResultAlert from '../../components/ResultAlert';
import ErrorMessages from '../../components/ErrorMessages';
import ModalCloseButton from '../../components/ModalCloseButton';

interface Input {
  zoneName: string;
  pumpName: string;
  nutrientMixerPumpId: string;
  onCompleted?: () => void;
}

interface Result {
  openNutrientMixerPrimePumpPrompt: () => void;
  NutrientMixerPrimePumpPrompt: typeof NutrientMixerPrimePumpPrompt;
  nutrientMixerPrimePumpPromptProps: Props;
}

const PRIME_NUTRIENT_MIXER_PUMP = gql`
  mutation primeNutrientMixerPump($input: PrimeNutrientMixerPumpInputType!) {
    primeNutrientMixerPump(input: $input) {
      nutrientMixerPump {
        nutrientMixerPumpId
        state
      }
    }
  }
`;

interface Props extends Input {
  nutrientMixerPrimePumpPromptIsOpen: boolean;
  closeNutrientMixerPrimePumpPrompt: () => void;
}

enum Duration {
  TenSeconds = 'TEN_SECONDS',
  ThirtySeconds = 'THIRTY_SECONDS',
}

const NutrientMixerPrimePumpPrompt = ({
  zoneName,
  pumpName,
  nutrientMixerPumpId,
  nutrientMixerPrimePumpPromptIsOpen,
  closeNutrientMixerPrimePumpPrompt,
  onCompleted,
}: Props) => {
  const [
    primeNutrientMixerPump,
    { error, loading: sendingCommand },
  ] = useMutation(PRIME_NUTRIENT_MIXER_PUMP);

  const [wasOpen, setWasOpen] = useState(false);
  const [commandError, setCommandError] = useState<ApolloError | undefined>(
    undefined
  );

  useEffect(() => {
    if (sendingCommand) {
      setCommandError(undefined);
      return;
    }

    if (error) {
      setCommandError(error);
    }
  }, [sendingCommand, error]);

  useEffect(() => {
    const reopened = nutrientMixerPrimePumpPromptIsOpen && !wasOpen;
    if (reopened) {
      setCommandError(undefined);
    }

    setWasOpen(nutrientMixerPrimePumpPromptIsOpen);
  }, [nutrientMixerPrimePumpPromptIsOpen, wasOpen]);

  return (
    <Modal
      className={style.modal}
      isOpen={nutrientMixerPrimePumpPromptIsOpen}
      toggle={closeNutrientMixerPrimePumpPrompt}
    >
      <ModalCloseButton onClick={closeNutrientMixerPrimePumpPrompt} />

      <ModalHeader>
        <div className={style.titleContainer}>
          <div>
            <Pump className={style.icon} pumpState="OFF" />
          </div>

          {`Prime Nutrient Mixer Pump ${pumpName}`}
        </div>
      </ModalHeader>

      <ModalBody
        className={[
          style.modalBody,
          sendingCommand ? style.sendingCommand : undefined,
        ].join(' ')}
      >
        <p className={style.disclaimer}>
          <FontAwesomeIcon
            className={style.icon}
            icon={faExclamationTriangle}
            fixedWidth={true}
          />
          <span>
            You should physically monitor the pumps while priming them.
          </span>
        </p>

        <div className={style.buttonContainer}>
          {sendingCommand && <Loading />}

          <Button
            onClick={() =>
              primeNutrientMixerPump({
                variables: {
                  input: {
                    nutrientMixerPumpId,
                    duration: Duration.TenSeconds,
                  },
                },
              })
            }
          >
            <FontAwesomeIcon icon={faClock} fixedWidth={true} /> 10 Seconds
          </Button>

          <Button
            onClick={() =>
              primeNutrientMixerPump({
                variables: {
                  input: {
                    nutrientMixerPumpId,
                    duration: Duration.ThirtySeconds,
                  },
                },
              })
            }
          >
            <FontAwesomeIcon icon={faClock} fixedWidth={true} /> 30 Seconds
          </Button>
        </div>

        {commandError && (
          <ResultAlert className={style.errorMessage} success={false}>
            <ErrorMessages error={commandError!} />
          </ResultAlert>
        )}
      </ModalBody>
    </Modal>
  );
};

const useNutrientMixerPrimePumpPrompt: (input: Input) => Result = ({
  zoneName,
  pumpName,
  nutrientMixerPumpId,
  onCompleted,
}) => {
  const [
    nutrientMixerPrimePumpPromptIsOpen,
    setNutrientMixerPrimePumpPromptIsOpen,
  ] = useState(false);

  return {
    openNutrientMixerPrimePumpPrompt: useCallback(
      () => setNutrientMixerPrimePumpPromptIsOpen(true),
      [setNutrientMixerPrimePumpPromptIsOpen]
    ),
    NutrientMixerPrimePumpPrompt,
    nutrientMixerPrimePumpPromptProps: {
      zoneName,
      pumpName,
      nutrientMixerPumpId,
      nutrientMixerPrimePumpPromptIsOpen,
      onCompleted,
      closeNutrientMixerPrimePumpPrompt: useCallback(
        () => setNutrientMixerPrimePumpPromptIsOpen(false),
        [setNutrientMixerPrimePumpPromptIsOpen]
      ),
    },
  };
};

export default useNutrientMixerPrimePumpPrompt;
