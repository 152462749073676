import { ApolloClient } from 'apollo-client';
import { from, split } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';

import authentication from '../modules/authentication';

import { InMemoryCache } from 'apollo-cache-inmemory';
import { ProgressiveFragmentMatcher } from 'apollo-progressive-fragment-matcher';
import { setContext } from 'apollo-link-context';
import { graphQLHttpUrl, graphQLWebSocketUrl } from '../config';

const httpLink = createHttpLink({
  uri: graphQLHttpUrl,
  transportBatching: true,
  fetch: async (uri, options) => {
    const responseText = await fetch(uri, options).then(x => x.text());
    const responseJson = JSON.parse(responseText);

    if (
      !responseJson ||
      !responseJson.errors ||
      !responseJson.errors.some(
        x =>
          x.extensions &&
          (x.extensions.code === 'AUTH_NOT_AUTHENTICATED' ||
            x.extensions.code === 'NOT_AUTHENTICATED') // backward compatibility
      )
    ) {
      return {
        ok: true,
        text: async () => responseText,
      };
    }

    const accessToken = await authentication.fetchNewAccessToken();
    if (accessToken) {
      options.headers.authorization = `Bearer ${accessToken}`;
      return await fetch(uri, options);
    }

    authentication.logoff();
    return;
  },
});

const wsLink = new WebSocketLink({
  uri: graphQLWebSocketUrl,
  options: {
    reconnect: true,
    connectionParams: () => {
      const accessToken = authentication.getAccessToken();
      return {
        authorization: accessToken ? `Bearer ${accessToken}` : null,
      };
    },
  },
});

authentication.onSetAccessToken(() => wsLink.subscriptionClient.close(false));

const authLink = setContext((_, { headers }) => {
  const accessToken = authentication.getAccessToken();
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  };
});

const fragmentMatcher = new ProgressiveFragmentMatcher();

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  from([fragmentMatcher.link(), authLink, httpLink])
);

export default new ApolloClient({
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
    query: {
      fetchPolicy: 'network-only',
    },
  },
  cache: new InMemoryCache({
    fragmentMatcher,
    dataIdFromObject: result => {
      const id =
        result.id ||
        (result.__typename &&
          result[
            `${result.__typename.replace(
              /^(\w+?)Type$/,
              (_, p1) => `${p1[0].toLowerCase()}${p1.slice(1)}`
            )}Id`
          ]);

      if (id && result.__typename) {
        // eslint-disable-line no-underscore-dangle
        return result.__typename + id; // eslint-disable-line no-underscore-dangle
      }
      return id || null;
    },
  }),
  shouldBatch: true,
  initialState: window.__APOLLO_STATE__, // eslint-disable-line no-underscore-dangle
  ssrForceFetchDelay: 100,
});
