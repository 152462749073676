import React from 'react';
import { arc, DefaultArcObject } from 'd3-shape';
import { scaleLinear } from 'd3-scale';
import { isUndefined } from 'lodash';

import style from './style.module.scss';

import UnknownValueOverlay from '../UnknownValueOverlay';

const gaugeStartAngle = -Math.PI / 1.5;
const gaugeEndAngle = Math.PI / 1.5;

const defaultArcProps: DefaultArcObject = {
  innerRadius: 0.7,
  outerRadius: 0.8,
  startAngle: gaugeStartAngle,
  endAngle: gaugeEndAngle,
};

const degreesPerRadian = 180 / Math.PI;
const arcPaddingDegrees = 1;
const arcPaddingRadians = arcPaddingDegrees * (Math.PI / 180);
const arcCornerRadius = 0.025;

const compressOriginalRangeToPercent = 0.7;
const expandRangePercent = 1 / compressOriginalRangeToPercent;

interface Props extends React.HTMLAttributes<HTMLElement> {
  label: string;
  color: string;
  unit?: string;
  value?: string;
  lowCritical?: string;
  low?: string;
  high?: string;
  highCritical?: string;
}

interface ParsedValue {
  number: number;
  display: string;
}

const parseValue = (value?: string): ParsedValue | null => {
  const valueNumber = isUndefined(value) ? NaN : parseFloat(value);
  return isNaN(valueNumber)
    ? null
    : {
        number: valueNumber,
        display: value!,
      };
};

const createArc = (() => {
  const arcFactory = arc().cornerRadius(arcCornerRadius);
  return (datum: DefaultArcObject) => arcFactory(datum) || undefined;
})();

const getExpandedDomain = (low: number, high: number) => {
  const range = high - low;
  const expandedRangeOffset = (expandRangePercent * range - range) / 2;
  return [low - expandedRangeOffset, high + expandedRangeOffset];
};

const textOuterRingPath = createArc({
  innerRadius: 0.0,
  outerRadius: 0.85,
  startAngle: Math.PI,
  endAngle: Math.PI * 3,
});

enum RangeArcValuePosition {
  StartAngle,
  EndAngle,
}

interface RangeArcValue {
  number: number;
  display: string;
  position: RangeArcValuePosition;
}

interface RangeArcProps extends React.HTMLAttributes<SVGPathElement> {
  value?: RangeArcValue;
  startAngleRadians: number | undefined;
  endAngleRadians: number | undefined;
}

const RangeArc = ({
  value,
  startAngleRadians,
  endAngleRadians,
  ...props
}: RangeArcProps) => {
  return (
    <>
      <path
        {...props}
        d={createArc({
          ...defaultArcProps,
          startAngle: startAngleRadians ?? 0 + arcPaddingRadians,
          endAngle: endAngleRadians ?? 0 - arcPaddingRadians,
        })}
      />

      {value && (
        <text
          textAnchor="middle"
          transform={`rotate(${((value.position ===
          RangeArcValuePosition.StartAngle
            ? startAngleRadians ?? 0
            : endAngleRadians ?? 0) *
            180) /
            Math.PI})`}
        >
          <textPath xlinkHref="#gaugeTextCircle" startOffset="50%">
            {value?.display}
          </textPath>
        </text>
      )}
    </>
  );
};

export default ({
  label,
  color,
  unit,
  value,
  lowCritical,
  low,
  high,
  highCritical,
  className,
  ...props
}: Props) => {
  const valueParsed = parseValue(value);
  const lowCriticalParsed = parseValue(lowCritical);
  const lowParsed = parseValue(low);
  const highParsed = parseValue(high);
  const highCriticalParsed = parseValue(highCritical);

  const lowerBound = lowCriticalParsed || lowParsed;
  const upperBound = highCriticalParsed || highParsed;

  const domain =
    lowerBound &&
    upperBound &&
    lowerBound.number < upperBound.number &&
    getExpandedDomain(lowerBound.number, upperBound.number);

  const angleScale =
    domain &&
    scaleLinear()
      .domain(domain)
      .range([gaugeStartAngle, gaugeEndAngle])
      .clamp(true);

  return (
    <UnknownValueOverlay
      hasUnknownValue={valueParsed === null}
      iconClassName={style.unknownValueIcon}
      className={[style.container, className].join(' ')}
      {...props}
      style={{
        ['--metric-color' as any]: color,
      }}
    >
      <div className={style.gauge}>
        <div className={style.label}>
          <div>{label}</div>

          <div className={style.metricColor}></div>

          <div className={style.unit}>{unit}</div>
        </div>

        <svg xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="-1 -1 2 1.7">
          <defs>
            <path id="gaugeTextCircle" d={textOuterRingPath} />
          </defs>

          {(domain && angleScale && (
            <>
              {lowCriticalParsed && (
                <RangeArc
                  className={style.dangerRange}
                  value={{
                    ...lowCriticalParsed,
                    position: RangeArcValuePosition.EndAngle,
                  }}
                  startAngleRadians={angleScale(domain[0])}
                  endAngleRadians={angleScale(lowCriticalParsed.number)}
                />
              )}

              {lowParsed && (
                <RangeArc
                  className={style.warningRange}
                  value={{
                    ...lowParsed,
                    position: RangeArcValuePosition.EndAngle,
                  }}
                  startAngleRadians={angleScale(
                    lowCriticalParsed !== null
                      ? lowCriticalParsed.number
                      : domain[0]
                  )}
                  endAngleRadians={angleScale(lowParsed.number)}
                />
              )}

              <RangeArc
                className={style.normalRange}
                startAngleRadians={angleScale(
                  lowParsed !== null
                    ? lowParsed.number
                    : lowCriticalParsed !== null
                    ? lowCriticalParsed.number
                    : domain[0]
                )}
                endAngleRadians={angleScale(
                  highParsed !== null
                    ? highParsed.number
                    : highCriticalParsed !== null
                    ? highCriticalParsed.number
                    : domain[1]
                )}
              />

              {highParsed && (
                <RangeArc
                  className={style.warningRange}
                  value={{
                    ...highParsed,
                    position: RangeArcValuePosition.StartAngle,
                  }}
                  startAngleRadians={angleScale(highParsed.number)}
                  endAngleRadians={angleScale(
                    highCriticalParsed !== null
                      ? highCriticalParsed.number
                      : domain[1]
                  )}
                />
              )}

              {highCriticalParsed && (
                <RangeArc
                  className={style.dangerRange}
                  value={{
                    ...highCriticalParsed,
                    position: RangeArcValuePosition.StartAngle,
                  }}
                  startAngleRadians={angleScale(highCriticalParsed.number)}
                  endAngleRadians={angleScale(domain[1])}
                />
              )}
            </>
          )) || (
            <path
              className={style.unknownRange}
              d={createArc(defaultArcProps)}
            />
          )}

          {valueParsed && angleScale && (
            <path
              className={style.needle}
              style={{
                ['--needle-angle-init' as any]: `${gaugeStartAngle *
                  degreesPerRadian +
                  90}deg`,
                ['--needle-angle' as any]: `${(angleScale(valueParsed.number) ??
                  0) *
                  degreesPerRadian +
                  90}deg`,
              }}
              d="m 0.03717328,-0.03820197 c -0.0128105,-0.01281044 -0.0304546,-0.01784289 -0.0470649,-0.0150974 L -0.82113207,0 -0.00989162,0.05329936 c 0.0166102,0.0027455 0.0342544,-0.0022869 0.0470649,-0.01509741 0.0210976,-0.02109766 0.0210976,-0.0553062 0,-0.07640392 z"
            ></path>
          )}
        </svg>
        <div className={style.valueContainer}>
          <div
            className={[style.value, value ? null : style.unknown].join(' ')}
          >
            {value ? value : '?'}
          </div>
        </div>
      </div>
    </UnknownValueOverlay>
  );
};
