import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

import Switch from '../Switch';

import style from './style.module.scss';

interface Permission {
  permissionId: string;
  description: string;
  name: string;
}

interface PermissionSelection {
  permissionId: string;
  selected: boolean;
}

interface Props {
  id?: string;
  className?: string;
  enabledPermissionIds: Array<string>;
  allPermissions: Array<Permission>;
  onPermissionIdSelectionChange?: (selection: PermissionSelection) => void;
}

const PermissionSetting = ({
  id,
  title,
  description,
  enabled,
  onChange,
}: {
  id: string;
  title: string;
  description: string;
  enabled: boolean;
  onChange: (checked: boolean) => void;
}) => (
  <div className={style.permissionContainer} key={id}>
    <div className={style.switchContainer}>
      <div className={style.switchLabel}>
        {`${id}. ${title}`}
        <FontAwesomeIcon
          id={`permission${id}`}
          icon={faQuestionCircle}
          className={style.helpTextIcon}
          fixedWidth
        />
        <UncontrolledPopover
          placement="top"
          trigger="focus"
          target={`permission${id}`}
        >
          <PopoverBody>{description}</PopoverBody>
        </UncontrolledPopover>
      </div>

      <Switch
        id={id as string}
        className={style.permissionSwitch}
        checked={enabled}
        offColor="#444444"
        onChange={onChange}
        disabled={false}
      />
    </div>
  </div>
);

const FormPermissionsSelector = ({
  id,
  className,
  enabledPermissionIds,
  allPermissions,
  onPermissionIdSelectionChange,
}: Props) => (
  <div id={id} className={[style.container, className].join(' ')}>
    {allPermissions?.map(({ permissionId, name, description }) => (
      <PermissionSetting
        id={permissionId}
        title={name}
        description={description}
        enabled={enabledPermissionIds.includes(permissionId)}
        onChange={(checked: boolean) =>
          onPermissionIdSelectionChange &&
          onPermissionIdSelectionChange({
            permissionId,
            selected: checked,
          })
        }
      />
    ))}
  </div>
);

export default FormPermissionsSelector;
