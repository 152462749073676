import React from 'react';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import {
  AttentionLevels,
  getStackAttentionLevel,
} from '../../../../modules/attention-levels';

import StackStatus from '../../../../components/StackStatus';

import style from './style.module.scss';

const getAttentionStatusClass = attentionLevel => {
  switch (attentionLevel) {
    case AttentionLevels.Critical:
      return style.needsAttentionCritical;
    case AttentionLevels.Low:
      return style.needsAttentionLow;
    default:
      return null;
  }
};

const Stacks = ({
  facilityId,
  zoneName,
  stacks,
  isMicrogreen,
  subzones,
  onSubzoneSelected,
  hasPerStackCollectorTanks,
  scheduleMicrogreenStackCallback,
}) => {
  const subzoneSelectionCallback = e => {
    return () => onSubzoneSelected(e);
  };

  return (
    <div className={style.container}>
      {stacks.map((stack, id) => {
        const { stackId, name } = stack;
        const attentionLevel = getStackAttentionLevel(stack);
        return (
          <div
            className={[
              style.stack,
              hasPerStackCollectorTanks ? style.perStackCollectorTanks : null,
              getAttentionStatusClass(attentionLevel),
            ].join(' ')}
            key={id}
          >
            <Link
              to={`/facility/${facilityId}/zone/${zoneName}/stack/${name}`}
              key={stackId}
            >
              <div className={style.stackTitleContainer}>
                <div className={style.stackTitle}>{name}</div>
              </div>
            </Link>
            <StackStatus
              link={`/facility/${facilityId}/zone/${zoneName}/stack/${name}`}
              className={style.stackStatus}
              zoneName={zoneName}
              stack={stack}
              hideCollectorTank={
                !hasPerStackCollectorTanks || isMicrogreen || stack.isMicrogreen
              }
              hideCommands
              isMicrogreen={isMicrogreen || stack.isMicrogreen}
              subzones={subzones}
              subzoneSelectionCallback={subzoneSelectionCallback}
              scheduleMicrogreenStack={scheduleMicrogreenStackCallback}
            />
          </div>
        );
      })}
    </div>
  );
};

Stacks.fragment = gql`
  fragment Stacks on StackType {
    name
    stackId
    ...StackStatus
    ...GetStackAttentionLevel
  }
  ${StackStatus.fragment}
  ${getStackAttentionLevel.fragment}
`;

export default Stacks;
