import React, { useCallback, useEffect, useState } from 'react';
import gql from 'graphql-tag';
import useModalVisible from '../useModalVisible';
import { useMutation, useQuery } from 'react-apollo';
import { isEmpty } from 'lodash';
import { getValidationErrors } from '../../modules/errors';
import { SaveModal } from '../../components/SaveModal';

import style from './style.module.scss';
import {
  Col,
  FormFeedback,
  FormGroup,
  Input as FormInput,
  Label,
  Row,
} from 'reactstrap';
import ModalTitle from '../../components/ModalTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import ModalLoadingContainer from '../../components/ModalLoadingContainer';
import { getInputValueAnd, updateState } from '../../modules/form-helpers';
import FormPermissionsSelector from '../../components/FormPermissionsSelector';

interface Input {
  onCompleted?: () => void;
}

interface Result {
  openRoleNewForm: () => void;
  RoleNewForm: typeof RoleNewForm;
  roleNewFormProps: Props;
}

const CREATE_ROLE = gql`
  mutation createRole($input: CreateRoleInputType!) {
    createRole(input: $input) {
      role {
        roleId
      }
    }
  }
`;

const SETTINGS_QUERY = gql`
  query {
    settings {
      roleManagement {
        permissions {
          permissionId
          name
          description
        }
      }
    }
  }
`;

interface Props extends Input {
  roleNewFormIsOpen: boolean;
  closeRoleNewForm: () => void;
}

const createBlankRoleData = () => ({
  roleName: '',
  permissionIds: [] as Array<string>,
});

const RoleNewForm = ({
  roleNewFormIsOpen,
  closeRoleNewForm,
  onCompleted,
}: Props) => {
  const [visible, modalVisibleProps] = useModalVisible();

  const loadingResult = useQuery(SETTINGS_QUERY, {
    skip: !visible,
  });
  const { data, loading, refetch } = loadingResult;

  const [
    createRole,
    { error: saveError, loading: saving, data: successData },
  ] = useMutation(CREATE_ROLE, {
    onCompleted: () => {
      closeRoleNewForm();
      onCompleted && onCompleted();
    },
  });

  const [role, setRole] = useState(createBlankRoleData());
  const [saveAttempted, setSaveAttempted] = useState(false);
  const [wasOpen, setWasOpen] = useState(false);

  useEffect(() => {
    const reopened = roleNewFormIsOpen && !wasOpen;

    if (reopened) {
      setRole(createBlankRoleData());
      refetch();
    }

    setWasOpen(roleNewFormIsOpen);
  }, [roleNewFormIsOpen, refetch, wasOpen, setWasOpen]);

  const allPermissions = data?.settings?.roleManagement?.permissions;

  const isDirtyState = useState(false);
  const [isDirty, setIsDirty] = isDirtyState;

  const validationErrors = {
    ...(isEmpty(role.roleName?.trim()) && {
      roleName: 'Role title is required',
    }),
    ...(!isDirty &&
      saveAttempted &&
      saveError &&
      getValidationErrors(saveError)),
  };

  const isFormValid = !loading && isEmpty(validationErrors);

  return (
    <SaveModal
      scrollable
      className={style.modal}
      title={
        <ModalTitle
          icon={<FontAwesomeIcon icon={faUserPlus} />}
          title="New Role"
        />
      }
      isOpen={roleNewFormIsOpen}
      isFormValid={isFormValid}
      saving={saving}
      error={saveError}
      isDirty={isDirty}
      onComplete={closeRoleNewForm}
      saveButtonContent="Create Role"
      successContent={
        successData && (
          <>
            <p>Success!</p>
            <p>Role has been created.</p>
          </>
        )
      }
      onSave={() => {
        setIsDirty(false);
        setSaveAttempted(true);
        createRole({
          variables: {
            input: role,
          },
        });
      }}
      {...modalVisibleProps}
    >
      <ModalLoadingContainer
        className={style.modalContentContainer}
        resourceTypeName="Roles"
        resourceExists={!!role}
        result={loadingResult}
      >
        <>
          <Row form>
            <Col sm={6}>
              <FormGroup>
                <Label for="roleName">Role Title</Label>
                <FormInput
                  id="roleName"
                  type="text"
                  required
                  value={role.roleName}
                  onChange={getInputValueAnd(
                    updateState({
                      valueState: [
                        role.roleName,
                        (value: string) =>
                          setRole({ ...role, roleName: value }),
                      ],
                      isDirtyState,
                    })
                  )}
                  invalid={!!validationErrors.roleName}
                />

                <FormFeedback invalid={validationErrors.roleName}>
                  {validationErrors.roleName}
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col sm={12}>
              <FormGroup>
                <Label for="permissions">Permissions</Label>
                <FormPermissionsSelector
                  id="permissions"
                  enabledPermissionIds={role.permissionIds}
                  allPermissions={allPermissions}
                  onPermissionIdSelectionChange={({ permissionId, selected }) =>
                    updateState({
                      valueState: [
                        role.permissionIds.includes(permissionId),
                        (value: boolean) =>
                          setRole({
                            ...role,
                            permissionIds: value
                              ? [...role.permissionIds, permissionId]
                              : role.permissionIds.filter(
                                  x => x !== permissionId
                                ),
                          }),
                      ],
                      isDirtyState,
                    })(selected)
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      </ModalLoadingContainer>
    </SaveModal>
  );
};

const useRoleNewForm: (input: Input) => Result = ({ onCompleted }) => {
  const [roleNewFormIsOpen, setRoleNewFormIsOpen] = useState(false);

  return {
    openRoleNewForm: useCallback(() => setRoleNewFormIsOpen(true), [
      setRoleNewFormIsOpen,
    ]),
    RoleNewForm,
    roleNewFormProps: {
      roleNewFormIsOpen,
      onCompleted,
      closeRoleNewForm: useCallback(() => setRoleNewFormIsOpen(false), [
        setRoleNewFormIsOpen,
      ]),
    },
  };
};

export default useRoleNewForm;
