/*
  A form and form hook for providing a user with the means to read and modify stack schedules.
 */

import React, { useState, useCallback, useEffect } from 'react';
import gql from 'graphql-tag';
import { useLazyQuery, useMutation, useQuery } from 'react-apollo';

import { SaveModal, SaveModalProps } from '../../components/SaveModal';
import ScheduleLine from './components/ScheduleLine';

/* Definitions */

export interface LevelSchedule {
  rowIndex: number;
  days: number;
  timesPerDay: number;
  duration: number;
}

interface Result {
  openModifyScheduleStackPrompt: (e: number) => void;
  ModifyScheduleStackPrompt: typeof ModifyStackSchedulePrompt;
  modifyScheduleStackPromptProps: Props;
}

interface Input {
  facilityId: number;
  zoneId: number;
  onCompleted?: () => void;
}

interface Props extends Input {
  stackId: number;
  modifyStackSchedulePromptIsOpen: boolean;
  closeModifyScheduleStackPrompt: () => void;
}

/* Global Methods */

//Constructor for LevelSchedule.
export const newStackSchedule: (i: number) => LevelSchedule = i => {
  return {
    duration: 2, //Minutes
    timesPerDay: 999,
    days: 1,
    rowIndex: i,
  };
};

export const allocStackSchedule: () => LevelSchedule = () => {
  return {
    duration: 0, //Minutes
    timesPerDay: 0,
    days: 0,
    rowIndex: -1,
  };
};

//Clone constructor for LevelSchedule.
export const buildStackSchedule: (i: number, data: any) => LevelSchedule = (
  i,
  data
) => {
  return {
    duration: data.durationSeconds === 0 ? 0 : data.durationSeconds / 60,
    timesPerDay: data.timesPerDay,
    days: data.everyNDays,
    rowIndex: i,
  };
};

//An interface for sorting LevelSchedule.
export const stackScheduleComparable: (
  a: LevelSchedule,
  b: LevelSchedule
) => number = (a, b) => a.rowIndex - b.rowIndex;

//An interface for LevelSchedule comparisons.
export const isEqual: (self: LevelSchedule, other: LevelSchedule) => boolean = (
  self,
  other
) => {
  return (
    Number(self.rowIndex) === Number(other.rowIndex) &&
    Number(self.days) === Number(other.days) &&
    Number(self.timesPerDay) === Number(other.timesPerDay) &&
    Number(self.duration / 60) === Number(other.duration)
  );
};

/* GQL Queries */

const GET_STACK_SCHEDULES = gql`
  query readSchedules($stackId: ID!) {
    stackIrrigationSchedules(stackId: $stackId) {
      stackScheduleId
      stackId
      durationSeconds
      rowIndex
      everyNDays
      timesPerDay
    }
    max: maxLevelsInStack(stackId: $stackId)
  }
`;

const SET_STACK_SCHEDULES = gql`
  mutation writeSchedules($irrigationLevels: levelIrrigationParameters!) {
    scheduleStack(input: $irrigationLevels) {
      __typename
    }
  }
`;

/* React Components */

const ModifyStackSchedulePrompt = ({
  stackId,
  facilityId,
  zoneId,
  modifyStackSchedulePromptIsOpen,
  closeModifyScheduleStackPrompt,
  onCompleted,
}: Props) => {
  //GQL hooks.
  const [
    setStackSchedules,
    { error: setStackSchedulesError, loading: setStackSchedulesLoading },
  ] = useMutation(SET_STACK_SCHEDULES);

  const existingSchedulesStateHook = useQuery(GET_STACK_SCHEDULES, {
    variables: { stackId },
  });

  //Local schedules state and effect for dialog toggle.
  const [schedules, setSchedules] = useState<LevelSchedule[]>([]);
  const [batchMarshal, setBatchMarshal] = useState<LevelSchedule>(
    allocStackSchedule()
  );

  useEffect(() => {
    if (!modifyStackSchedulePromptIsOpen) return;
    setSchedules([]);
    existingSchedulesStateHook.refetch({ stackId });
  }, [modifyStackSchedulePromptIsOpen]);

  useEffect(() => {}, [batchMarshal]);

  useEffect(() => {
    if (
      existingSchedulesStateHook.error ||
      existingSchedulesStateHook.data == null
    )
      return;
    let next: LevelSchedule[] = [];

    const serverState =
      existingSchedulesStateHook.data.stackIrrigationSchedules;
    if (serverState.length > 0) {
      next = serverState.map((i: any, index: number) =>
        buildStackSchedule(index, i)
      );
    }

    next.sort(stackScheduleComparable);
    setSchedules(() => [...next]);
  }, [existingSchedulesStateHook.data]);

  //Update schedule callback.
  const updateSchedule = (newState: LevelSchedule) => {
    //Stop React bouncing.
    if (stackId == 0) {
      return;
    }

    //Match new state to current state by index.
    for (let i = 0; i < schedules.length; i++) {
      if (schedules[i].rowIndex != newState.rowIndex) continue;

      //Update by index, sort, and update current state.
      schedules[i] = newState;
      schedules.sort(stackScheduleComparable);
      setSchedules([...schedules]);

      //Short circut once the index match is made.
      break;
    }
  };

  //Update all schedules callback
  const updateAllSchedules = (newState: LevelSchedule) => {
    //Stop React bouncing.
    if (stackId == 0) {
      return;
    }

    //Match new state to current state by index.
    for (let i = 0; i < schedules.length; i++) {
      if (Number(newState.days) > 0) schedules[i].days = Number(newState.days);
      if (Number(newState.duration) > 0)
        schedules[i].duration = Number(newState.duration);
      if (Number(newState.timesPerDay) > 0)
        schedules[i].timesPerDay = Number(newState.timesPerDay);
    }

    schedules.sort(stackScheduleComparable);
    setBatchMarshal(newState);
    setSchedules(schedules);
  };

  const saveStackSchedules = () => {
    //Stop React bouncing.
    if (stackId == 0) {
      return;
    }

    //Find server state candidates for local state and check for modifications.
    const isModified = (i: LevelSchedule) => {
      let isNotModified = false;
      existingSchedulesStateHook.data.stackIrrigationSchedules
        .filter((j: LevelSchedule) => j.rowIndex === i.rowIndex)
        .some((j: LevelSchedule) => isEqual(j, i));

      return !isNotModified;
    };

    //Set schedules where modifications have been made or a server state does not exist.
    setStackSchedules({
      variables: {
        irrigationLevels: {
          zoneId: Number(zoneId),
          stackId: Number(stackId),
          levelIrrigationParameters: schedules
            .filter(i => {
              return isModified(i);
            })
            .map(i => {
              return {
                index: Number(i.rowIndex),
                durationSeconds: Number(i.duration) * 60,
                everyNDays: Number(i.days),
                timesPerDay: Number(i.timesPerDay),
              };
            }),
        },
      },
    }).then(() => existingSchedulesStateHook.refetch({ stackId }));

    //Close and call parent.
    closeModifyScheduleStackPrompt();
    onCompleted && onCompleted();
  };

  return (
    <SaveModal
      title={`Stack Schedule`}
      isOpen={modifyStackSchedulePromptIsOpen}
      saving={false}
      error={undefined}
      onSave={saveStackSchedules}
      onComplete={() => {
        setSchedules([]);
        setBatchMarshal(allocStackSchedule());
        closeModifyScheduleStackPrompt();
      }}
      saveButtonContent="Save"
      isFormValid={true}
      className="my-5"
    >
      {existingSchedulesStateHook.loading && <p>loading</p>}
      <div className="row pb-2">
        <div className="col-12 col-sm-2"></div>
        <div className="col-12 col-sm-3 text-center">Every</div>
        <div className="col-12 col-sm-3 text-center">Times per Day</div>
        <div className="col-12 col-sm-4 text-center">Duration</div>
      </div>
      <ScheduleLine
        schedule={batchMarshal}
        onChange={updateAllSchedules}
        label="All"
      />
      {schedules != null &&
        schedules.map((context, i) => (
          <div key={i}>
            <ScheduleLine
              schedule={context}
              onChange={updateSchedule}
              label={null}
            />
          </div>
        ))}
    </SaveModal>
  );
};

/* React Hooks */

const useModifyMicrogreensForm: (input: Input) => Result = ({
  facilityId,
  zoneId,
  onCompleted,
}: Input) => {
  const [
    modifyStackSchedulePromptIsOpen,
    setModifyStackSchedulePromptIsOpen,
  ] = useState(false);

  //Create state for required stack parameter to open the form.
  const [stack, setStack] = useState<number>(0);

  return {
    openModifyScheduleStackPrompt: useCallback(
      (e: number) => {
        setStack(e);
        setModifyStackSchedulePromptIsOpen(true);
      },
      [
        setModifyStackSchedulePromptIsOpen,
        modifyStackSchedulePromptIsOpen,
        setStack,
        stack,
      ]
    ),
    ModifyScheduleStackPrompt: ModifyStackSchedulePrompt,
    modifyScheduleStackPromptProps: {
      facilityId,
      stackId: stack,
      zoneId,
      modifyStackSchedulePromptIsOpen: modifyStackSchedulePromptIsOpen,
      onCompleted,
      closeModifyScheduleStackPrompt: useCallback(
        () => setModifyStackSchedulePromptIsOpen(false),
        [modifyStackSchedulePromptIsOpen]
      ),
    },
  };
};

export default useModifyMicrogreensForm;
