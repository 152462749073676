import React from 'react';

import UnknownValueOverlay from '../UnknownValueOverlay';
import Valve from '../Valve';

import style from './style.module.scss';

import { Button } from 'reactstrap';

interface IProps {
  valveState: 'OPEN' | 'CLOSED';
  onClick: () => void;
  className?: string;
  unknownIconClassName?: string;
  inverted?: boolean;
  disabled?: boolean;
  needsAttention?: boolean;
}

export default ({
  valveState,
  onClick,
  className,
  unknownIconClassName,
  inverted = false,
  disabled = false,
  needsAttention = false,
}: IProps) => {
  const open = valveState === 'OPEN';
  const hasUnknownValue = valveState !== 'OPEN' && valveState !== 'CLOSED';
  return (
    <UnknownValueOverlay
      hasUnknownValue={hasUnknownValue}
      className={[
        style.container,
        hasUnknownValue ? style.unknown : null,
        className,
      ].join(' ')}
      iconClassName={[style.unknownValueOverlayIcon, unknownIconClassName].join(
        ' '
      )}
    >
      <Button
        disabled={disabled}
        className={[
          open ? style.open : style.closed,
          needsAttention ? style.needsAttention : null,
        ].join(' ')}
        onClick={onClick}
      >
        <Valve
          className={style.valve}
          unknownIconClassName={style.valveUnknownIcon}
          inverted={inverted}
          valveState={valveState}
          needsAttention={needsAttention}
        />
      </Button>
    </UnknownValueOverlay>
  );
};
