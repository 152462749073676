import React from 'react';
import gql from 'graphql-tag';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinusCircle,
  faPlusCircle,
  faCog,
} from '@fortawesome/free-solid-svg-icons';

import useLevelAddSensorForm from '../../../../hooks/useLevelAddSensorForm';
import useLevelRemoveSensorPrompt from '../../../../hooks/useLevelRemoveSensorPrompt';
import useLevelCalibrateSensorForm from '../../../../hooks/useLevelCalibrateSensorForm';

import style from './style.module.scss';

interface IProps {
  zoneName: string;
  stackName: string;
  level: any;
  className?: string;
}

const LevelCommands = ({ zoneName, stackName, level, className }: IProps) => {
  const { name: levelName, levelId } = level;

  const {
    openLevelAddSensorForm,
    LevelAddSensorForm,
    levelAddSensorFormProps,
  } = useLevelAddSensorForm({
    zoneName,
    stackName,
    levelName,
    levelId,
  });

  const {
    openLevelRemoveSensorPrompt,
    LevelRemoveSensorPrompt,
    levelRemoveSensorPromptProps,
  } = useLevelRemoveSensorPrompt({
    zoneName,
    stackName,
    levelName,
    levelId,
  });

  const {
    openLevelCalibrateSensorForm,
    LevelCalibrateSensorForm,
    levelCalibrateSensorFormProps,
  } = useLevelCalibrateSensorForm({
    stackName,
    levelName,
    levelId,
  });

  const {
    updateLevelBeeSensorConfigurationCommand,
    hasBeeSensor,
    beeSensor,
  } = level;

  const { updateBeeSensorThresholdsCommand } = beeSensor || {};

  return (
    <div className={[style.container, className].join(' ')}>
      <LevelAddSensorForm {...levelAddSensorFormProps} />

      <LevelRemoveSensorPrompt {...levelRemoveSensorPromptProps} />

      <LevelCalibrateSensorForm {...levelCalibrateSensorFormProps} />

      {updateBeeSensorThresholdsCommand?.canExecute && (
        <Button
          onClick={
            hasBeeSensor ? openLevelRemoveSensorPrompt : openLevelAddSensorForm
          }
        >
          <FontAwesomeIcon icon={hasBeeSensor ? faMinusCircle : faPlusCircle} />
          <span>{hasBeeSensor ? 'Remove ' : 'Add '}Sensor</span>
        </Button>
      )}

      {hasBeeSensor && updateLevelBeeSensorConfigurationCommand?.canExecute && (
        <Button onClick={openLevelCalibrateSensorForm}>
          <FontAwesomeIcon icon={faCog} />
          <span>Calibrate Sensor</span>
        </Button>
      )}
    </div>
  );
};

LevelCommands.fragment = gql`
  fragment LevelCommands on LevelType {
    updateLevelBeeSensorConfigurationCommand {
      canExecute
    }
    hasBeeSensor
    beeSensor {
      serialNumber
      updateBeeSensorThresholdsCommand {
        canExecute
      }
    }
  }
`;

export default LevelCommands;
