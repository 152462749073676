import React, { useState, useCallback, useEffect } from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';
import { FormFeedback } from 'reactstrap';
import Select from 'react-select';
import { isEmpty } from 'lodash';

import { getValidationErrors } from '../../modules/errors';
import { updateState } from '../../modules/form-helpers';

import useModalVisible from '../useModalVisible';

import GrowWiseRecipe from '../../components/GrowWiseRecipe';
import ModalLoadingContainer from '../../components/ModalLoadingContainer';
import ModalTitle from '../../components/ModalTitle';
import { SaveModal } from '../../components/SaveModal';
import ColorWheelImage from '../../assets/images/color-wheel.svg';

import style from './style.module.scss';

interface Input {
  zoneName: string;
  stackName: string;
  stackId: string;
  onCompleted?: () => void;
}

interface Result {
  openStackLightRecipeForm: () => void;
  StackLightRecipeForm: typeof StackLightRecipeForm;
  stackLightRecipeFormProps: Props;
}

const UPDATE_STACK_GROWWISE_RECIPE = gql`
  mutation updateStackGrowWiseRecipe(
    $input: UpdateStackGrowWiseRecipeInputType!
  ) {
    updateStackGrowWiseRecipe(input: $input) {
      stack {
        stackId
        growWise {
          currentRecipe {
            ...GrowWiseRecipe
          }
        }
      }
    }
  }
  ${GrowWiseRecipe.fragment}
`;

const STACK_QUERY = gql`
  query stack($stackId: ID!) {
    stack(stackId: $stackId) {
      stackId
      growWise {
        currentRecipe {
          id
        }
        availableRecipes(forceCacheRefresh: true) {
          id
          ...GrowWiseRecipe
        }
      }
    }
  }
  ${GrowWiseRecipe.fragment}
`;

interface Props extends Input {
  stackLightRecipeFormIsOpen: boolean;
  closeStackLightRecipeForm: () => void;
}

const StackLightRecipeForm = ({
  zoneName,
  stackName,
  stackId,
  stackLightRecipeFormIsOpen,
  closeStackLightRecipeForm,
  onCompleted,
}: Props) => {
  const [visible, modalVisibleProps] = useModalVisible();
  const [selectedGrowWiseRecipeId, setSelectedGrowWiseRecipeId] = useState<
    string | undefined
  >(undefined);
  const isDirtyState = useState(false);
  const [isDirty, setIsDirty] = isDirtyState;
  const [saveAttempted, setSaveAttempted] = useState(false);
  const [wasOpen, setWasOpen] = useState(false);

  const loadingResult = useQuery(STACK_QUERY, {
    variables: {
      stackId,
    },
    skip: !visible,
  });

  const { data, loading, refetch } = loadingResult;

  const [
    updateStackGrowWiseRecipe,
    { error: saveError, loading: saving },
  ] = useMutation(UPDATE_STACK_GROWWISE_RECIPE, {
    onCompleted: () => {
      closeStackLightRecipeForm();
      onCompleted && onCompleted();
    },
  });

  const validationErrors: any = {
    ...(!isDirty &&
      saveAttempted &&
      saveError &&
      getValidationErrors(saveError)),
  };

  const isFormValid =
    !loading && !isEmpty(selectedGrowWiseRecipeId) && isEmpty(validationErrors);

  useEffect(() => {
    const reopened = stackLightRecipeFormIsOpen && !wasOpen;

    if (reopened) {
      setSaveAttempted(false);
      refetch();
    }

    setWasOpen(stackLightRecipeFormIsOpen);
  }, [stackLightRecipeFormIsOpen, refetch, wasOpen, setWasOpen]);

  const stack = data?.stack;
  const currentRecipe = stack?.growWise?.currentRecipe;
  const availableRecipes = data?.stack?.growWise?.availableRecipes;
  const options = [
    isEmpty(selectedGrowWiseRecipeId) ? [{ name: '', value: '' }] : [],
    availableRecipes?.map((growWiseRecipe: { id: string }) => ({
      label: GrowWiseRecipe.format(growWiseRecipe),
      value: growWiseRecipe.id,
    })),
  ].flatMap(x => x);

  const selectedOption =
    selectedGrowWiseRecipeId &&
    options.find(x => x?.value === selectedGrowWiseRecipeId);

  useEffect(() => {
    setSelectedGrowWiseRecipeId(currentRecipe?.id);
  }, [currentRecipe]);

  return (
    <SaveModal
      className={style.modal}
      title={
        <ModalTitle
          icon={
            <img
              className={style.titleIcon}
              src={ColorWheelImage}
              alt="GrowWise"
            />
          }
          title={`Set Light GrowWise Recipe for Zone ${zoneName} / Stack ${stackName}`}
        />
      }
      isOpen={stackLightRecipeFormIsOpen}
      isFormValid={isFormValid}
      saving={saving}
      error={saveError}
      isDirty={isDirty}
      onComplete={closeStackLightRecipeForm}
      onSave={() => {
        setIsDirty(false);
        setSaveAttempted(true);
        updateStackGrowWiseRecipe({
          variables: {
            input: {
              stackId,
              growWiseRecipeId: selectedGrowWiseRecipeId,
            },
          },
        });
      }}
      {...modalVisibleProps}
    >
      <ModalLoadingContainer
        className={style.modalContentContainer}
        resourceTypeName="Stack"
        resourceExists={!!stack}
        result={loadingResult}
      >
        <Select
          className={style.select}
          id="recipe"
          required
          value={selectedOption}
          options={options}
          placeholder="Select a recipe..."
          onChange={o =>
            updateState({
              valueState: [
                selectedGrowWiseRecipeId,
                (value: string | undefined) => {
                  return setSelectedGrowWiseRecipeId(value);
                },
              ],
              isDirtyState,
            })(o.value)
          }
        />

        <FormFeedback
          invalid={validationErrors.growWiseRecipeId}
          style={{ display: 'initial' }}
        >
          {validationErrors.growWiseRecipeId}
        </FormFeedback>
      </ModalLoadingContainer>
    </SaveModal>
  );
};

const useStackLightRecipeForm: (input: Input) => Result = ({
  zoneName,
  stackName,
  stackId,
  onCompleted,
}) => {
  const [stackLightRecipeFormIsOpen, setStackLightRecipeFormIsOpen] = useState(
    false
  );

  return {
    openStackLightRecipeForm: useCallback(
      () => setStackLightRecipeFormIsOpen(true),
      [setStackLightRecipeFormIsOpen]
    ),
    StackLightRecipeForm,
    stackLightRecipeFormProps: {
      zoneName,
      stackName,
      stackId,
      stackLightRecipeFormIsOpen,
      onCompleted,
      closeStackLightRecipeForm: useCallback(
        () => setStackLightRecipeFormIsOpen(false),
        [setStackLightRecipeFormIsOpen]
      ),
    },
  };
};

export default useStackLightRecipeForm;
