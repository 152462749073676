import React, { useCallback } from 'react';
import { useEffect, useState } from 'react';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import gql from 'graphql-tag';
import { isEmpty, keysIn, pick, valuesIn } from 'lodash';
import { useMutation, useQuery, useSubscription } from 'react-apollo';
import { Label, Input as FormInput } from 'reactstrap';

import Gauge from '../../components/Gauge';
import ModalLoadingContainer from '../../components/ModalLoadingContainer';
import ModalTitle from '../../components/ModalTitle';
import { SaveModal } from '../../components/SaveModal';

import {
  LevelBeeSensorMeasurements,
  LevelBeeSensorThresholds,
} from '../../modules/bee-sensors';
import { getValidationErrors } from '../../modules/errors';
import { getInputValueAnd, updateState } from '../../modules/form-helpers';

import useModalVisible from '../useModalVisible';

import style from './style.module.scss';

interface Input {
  zoneName: string;
  stackName: string;
  levelName: string;
  levelId: string;
  onCompleted?: () => void;
}

interface Result {
  openLevelBeeSensorConfigureThresholdsForm: () => void;
  LevelBeeSensorConfigureThresholdsForm: typeof LevelBeeSensorConfigureThresholdsForm;
  levelBeeSensorConfigureThresholdsFormProps: Props;
}

const UPDATE_BEE_SENSOR_THRESHOLDS = gql`
  mutation updateBeeSensorThresholds(
    $input: UpdateBeeSensorThresholdsInputType!
  ) {
    updateBeeSensorThresholds(input: $input) {
      level {
        levelId
        beeSensor {
          thresholds {
            ...LevelBeeSensorThresholds
          }
        }
      }
    }
  }
  ${LevelBeeSensorMeasurements.fragments.LevelBeeSensorThresholds}
`;

const levelFragment = gql`
  fragment LevelBeeSensorConfigureThresholdsForm on LevelType {
    levelId
    beeSensor {
      currentValue {
        ...LevelBeeSensorValues
      }
    }
  }
  ${LevelBeeSensorMeasurements.fragments.LevelBeeSensorCurrentValue}
`;

const LEVEL_QUERY = gql`
  query level($levelId: ID!) {
    level(levelId: $levelId) {
      ...LevelBeeSensorConfigureThresholdsForm
      beeSensor {
        thresholds {
          ...LevelBeeSensorThresholds
        }
      }
    }
  }
  ${levelFragment}
  ${LevelBeeSensorMeasurements.fragments.LevelBeeSensorThresholds}
`;

const LEVEL_SUBSCRIPTION = gql`
  subscription levelUpdate($levelId: ID!) {
    levelUpdate(levelId: $levelId) {
      level {
        ...LevelBeeSensorConfigureThresholdsForm
      }
    }
  }
`;

const createBlankThresholds: () => LevelBeeSensorThresholds = () => ({
  tempLowInF: '',
  tempLowAlertInF: '',
  tempHighInF: '',
  tempHighAlertInF: '',

  humidityLowInPercentRh: '',
  humidityLowAlertInPercentRh: '',
  humidityHighInPercentRh: '',
  humidityHighAlertInPercentRh: '',

  pressureLowInHPa: '',
  pressureLowAlertInHPa: '',
  pressureHighInHPa: '',
  pressureHighAlertInHPa: '',

  cO2LowInPpm: '',
  cO2LowAlertInPpm: '',
  cO2HighInPpm: '',
  cO2HighAlertInPpm: '',
});

const createUpdateBeeSensorThresholdsInput = (
  levelId: string,
  thresholds: LevelBeeSensorThresholds
) => {
  const {
    tempLowInF,
    tempLowAlertInF,
    tempHighInF,
    tempHighAlertInF,

    humidityLowInPercentRh,
    humidityLowAlertInPercentRh,
    humidityHighInPercentRh,
    humidityHighAlertInPercentRh,

    pressureLowInHPa,
    pressureLowAlertInHPa,
    pressureHighInHPa,
    pressureHighAlertInHPa,

    cO2LowInPpm,
    cO2LowAlertInPpm,
    cO2HighInPpm,
    cO2HighAlertInPpm,
  } = thresholds;

  return {
    levelId,
    tempLowInF: Number.parseFloat(tempLowInF),
    tempLowAlertInF: Number.parseFloat(tempLowAlertInF),
    tempHighInF: Number.parseFloat(tempHighInF),
    tempHighAlertInF: Number.parseFloat(tempHighAlertInF),

    humidityLowInPercentRh: Number.parseFloat(humidityLowInPercentRh),
    humidityLowAlertInPercentRh: Number.parseFloat(humidityLowAlertInPercentRh),
    humidityHighInPercentRh: Number.parseFloat(humidityHighInPercentRh),
    humidityHighAlertInPercentRh: Number.parseFloat(
      humidityHighAlertInPercentRh
    ),

    pressureLowInHPa: Number.parseFloat(pressureLowInHPa),
    pressureLowAlertInHPa: Number.parseFloat(pressureLowAlertInHPa),
    pressureHighInHPa: Number.parseFloat(pressureHighInHPa),
    pressureHighAlertInHPa: Number.parseFloat(pressureHighAlertInHPa),

    cO2LowInPpm: Number.parseFloat(cO2LowInPpm),
    cO2LowAlertInPpm: Number.parseFloat(cO2LowAlertInPpm),
    cO2HighInPpm: Number.parseFloat(cO2HighInPpm),
    cO2HighAlertInPpm: Number.parseFloat(cO2HighAlertInPpm),
  };
};

interface Props extends Input {
  levelBeeSensorConfigureThresholdsFormIsOpen: boolean;
  closeLevelBeeSensorConfigureThresholdsForm: () => void;
}

const LevelBeeSensorConfigureThresholdsForm = ({
  zoneName,
  stackName,
  levelName,
  levelId,
  levelBeeSensorConfigureThresholdsFormIsOpen,
  closeLevelBeeSensorConfigureThresholdsForm,
  onCompleted,
}: Props) => {
  const [visible, modalVisibleProps] = useModalVisible();

  const result = useQuery(LEVEL_QUERY, {
    variables: { levelId },
    skip: !visible,
  });

  useSubscription(LEVEL_SUBSCRIPTION, {
    variables: { levelId },
    skip: !visible,
  });

  const [
    updateBeeSensorThresholds,
    { error: saveError, loading: saving },
  ] = useMutation(UPDATE_BEE_SENSOR_THRESHOLDS, {
    onCompleted: () => {
      closeLevelBeeSensorConfigureThresholdsForm();
      onCompleted && onCompleted();
    },
  });

  const { refetch } = result;
  const [wasOpen, setWasOpen] = useState(false);
  const [thresholds, setThresholds] = useState(createBlankThresholds());

  useEffect(() => {
    const reopened = levelBeeSensorConfigureThresholdsFormIsOpen && !wasOpen;

    if (reopened) {
      refetch();
    }

    setWasOpen(levelBeeSensorConfigureThresholdsFormIsOpen);
  }, [
    levelBeeSensorConfigureThresholdsFormIsOpen,
    refetch,
    wasOpen,
    setWasOpen,
  ]);

  const { data, loading } = result;
  const level = data?.level || null;
  const beeSensor = level?.beeSensor || null;
  const currentValue = beeSensor?.currentValue || null;

  const isDirtyState = useState(false);
  const [isDirty, setIsDirty] = isDirtyState;
  const [saveAttempted, setSaveAttempted] = useState(false);

  useEffect(() => {
    if (beeSensor) {
      if (beeSensor?.thresholds && valuesIn(thresholds).every(isEmpty)) {
        setThresholds({
          ...(pick(
            beeSensor.thresholds,
            keysIn(thresholds)
          ) as LevelBeeSensorThresholds),
        });
      }
    } else {
      if (!valuesIn(thresholds).every(isEmpty)) {
        setThresholds(createBlankThresholds());
      }
    }
  }, [beeSensor, thresholds]);

  const validationErrors: any = {
    ...LevelBeeSensorMeasurements.all.reduce(
      (acc, measurement) => ({
        ...acc,
        ...(isEmpty(thresholds[measurement.lowAlertKey])
          ? {
              [measurement.lowAlertKey]: `${measurement.label} low alert value must be specified.`,
            }
          : {}),

        ...(Number.parseFloat(thresholds[measurement.lowAlertKey]) >=
        Number.parseFloat(thresholds[measurement.lowKey])
          ? {
              [measurement.lowKey]: `${measurement.label} low alert value cannot be greater than or equal to the low value.`,
              [measurement.lowAlertKey]: `${measurement.label} low alert value cannot be greater than or equal to the low value.`,
            }
          : {}),

        ...(isEmpty(thresholds[measurement.lowKey])
          ? {
              [measurement.lowKey]: `${measurement.label} low value must be specified.`,
            }
          : {}),

        ...(Number.parseFloat(thresholds[measurement.lowKey]) >=
        Number.parseFloat(thresholds[measurement.highKey])
          ? {
              [measurement.lowKey]: `${measurement.label} low value cannot be greater than or equal to the high value.`,
              [measurement.highKey]: `${measurement.label} low value cannot be greater than or equal to the high value.`,
            }
          : {}),

        ...(isEmpty(thresholds[measurement.highKey])
          ? {
              [measurement.highKey]: `${measurement.label} high value must be specified.`,
            }
          : {}),

        ...(Number.parseFloat(thresholds[measurement.highKey]) >=
        Number.parseFloat(thresholds[measurement.highAlertKey])
          ? {
              [measurement.highKey]: `${measurement.label} high value cannot be greater than or equal to the high alert value.`,
              [measurement.highAlertKey]: `${measurement.label} high value cannot be greater than or equal to the high alert value.`,
            }
          : {}),

        ...(isEmpty(thresholds[measurement.highAlertKey])
          ? {
              [measurement.highAlertKey]: `${measurement.label} high alert value must be specified.`,
            }
          : {}),
      }),
      {}
    ),

    ...(!isDirty &&
      saveAttempted &&
      saveError &&
      getValidationErrors(saveError)),
  };

  const isFormValid = !loading && isEmpty(validationErrors);

  return (
    <SaveModal
      className={style.modal}
      scrollable
      title={
        <ModalTitle
          icon={<FontAwesomeIcon icon={faCog} />}
          title={`Configure Stack ${stackName} / Level ${levelName} Sensor Thresholds`}
        />
      }
      isOpen={levelBeeSensorConfigureThresholdsFormIsOpen}
      isFormValid={isFormValid}
      saving={saving}
      error={saveError}
      onComplete={closeLevelBeeSensorConfigureThresholdsForm}
      onSave={() => {
        setIsDirty(false);
        setSaveAttempted(true);
        updateBeeSensorThresholds({
          variables: {
            input: createUpdateBeeSensorThresholdsInput(levelId, thresholds),
          },
        });
      }}
      {...modalVisibleProps}
    >
      <ModalLoadingContainer
        className={style.measurementsContainer}
        resourceTypeName="Level"
        result={result}
        resourceExists={!!level}
      >
        <>
          {LevelBeeSensorMeasurements.all.map(measurement => {
            const lowAlertId = `${measurement.label}-low-alert`;
            const lowId = `${measurement.label}-low`;
            const highId = `${measurement.label}-high`;
            const highAlertId = `${measurement.label}-high-alert`;

            return (
              <div
                className={style.measurementContainer}
                key={measurement.label}
              >
                <Gauge
                  className={style.gauge}
                  label={measurement.label}
                  color={measurement.color}
                  unit={measurement.unit}
                  value={measurement.valueSelector(currentValue)}
                  lowCritical={measurement.lowAlertValueSelector(thresholds)}
                  low={measurement.lowValueSelector(thresholds)}
                  high={measurement.highValueSelector(thresholds)}
                  highCritical={measurement.highAlertValueSelector(thresholds)}
                />

                <div className={style.measurementThresholds}>
                  <div className={style.measurementThreshold}>
                    <Label for={lowAlertId}>Alert Low</Label>

                    <FormInput
                      id={lowAlertId}
                      type="number"
                      value={measurement.lowAlertValueSelector(thresholds)}
                      invalid={!!validationErrors[measurement.lowAlertKey]}
                      onChange={getInputValueAnd(
                        updateState({
                          valueState: [
                            measurement.lowAlertValueSelector(thresholds),
                            value => {
                              const threshold =
                                value === undefined
                                  ? Number.NaN
                                  : Number.parseFloat(value);

                              !Number.isNaN(threshold) &&
                                setThresholds(
                                  measurement.withLowAlertValue(
                                    thresholds,
                                    threshold
                                  )
                                );
                            },
                          ],
                          isDirtyState,
                        })
                      )}
                    />
                  </div>

                  <div className={style.measurementThreshold}>
                    <Label for={lowId}>Low</Label>

                    <FormInput
                      id={lowId}
                      type="number"
                      value={measurement.lowValueSelector(thresholds)}
                      invalid={!!validationErrors[measurement.lowKey]}
                      onChange={getInputValueAnd(
                        updateState({
                          valueState: [
                            measurement.lowValueSelector(thresholds),
                            value => {
                              const threshold =
                                value === undefined
                                  ? Number.NaN
                                  : Number.parseFloat(value);

                              !Number.isNaN(threshold) &&
                                setThresholds(
                                  measurement.withLowValue(
                                    thresholds,
                                    threshold
                                  )
                                );
                            },
                          ],
                          isDirtyState,
                        })
                      )}
                    />
                  </div>

                  <div className={style.measurementThreshold}>
                    <Label for={highId}>High</Label>

                    <FormInput
                      id={highId}
                      type="number"
                      value={measurement.highValueSelector(thresholds)}
                      invalid={!!validationErrors[measurement.highKey]}
                      onChange={getInputValueAnd(
                        updateState({
                          valueState: [
                            measurement.highValueSelector(thresholds),
                            value => {
                              const threshold =
                                value === undefined
                                  ? Number.NaN
                                  : Number.parseFloat(value);

                              !Number.isNaN(threshold) &&
                                setThresholds(
                                  measurement.withHighValue(
                                    thresholds,
                                    threshold
                                  )
                                );
                            },
                          ],
                          isDirtyState,
                        })
                      )}
                    />
                  </div>

                  <div className={style.measurementThreshold}>
                    <Label for={highAlertId}>Alert High</Label>

                    <FormInput
                      id={highAlertId}
                      type="number"
                      value={measurement.highAlertValueSelector(thresholds)}
                      invalid={!!validationErrors[measurement.highAlertKey]}
                      onChange={getInputValueAnd(
                        updateState({
                          valueState: [
                            measurement.highAlertValueSelector(thresholds),
                            value => {
                              const threshold =
                                value === undefined
                                  ? Number.NaN
                                  : Number.parseFloat(value);

                              !Number.isNaN(threshold) &&
                                setThresholds(
                                  measurement.withHighAlertValue(
                                    thresholds,
                                    threshold
                                  )
                                );
                            },
                          ],
                          isDirtyState,
                        })
                      )}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      </ModalLoadingContainer>
    </SaveModal>
  );
};

const useLevelBeeSensorConfigureThresholdsForm: (input: Input) => Result = ({
  zoneName,
  stackName,
  levelName,
  levelId,
  onCompleted,
}) => {
  const [
    levelBeeSensorConfigureThresholdsFormIsOpen,
    setLevelBeeSensorConfigureThresholdsFormIsOpen,
  ] = useState(false);

  return {
    openLevelBeeSensorConfigureThresholdsForm: useCallback(
      () => setLevelBeeSensorConfigureThresholdsFormIsOpen(true),
      [setLevelBeeSensorConfigureThresholdsFormIsOpen]
    ),
    LevelBeeSensorConfigureThresholdsForm,
    levelBeeSensorConfigureThresholdsFormProps: {
      zoneName,
      stackName,
      levelName,
      levelId,
      levelBeeSensorConfigureThresholdsFormIsOpen,
      onCompleted,
      closeLevelBeeSensorConfigureThresholdsForm: useCallback(
        () => setLevelBeeSensorConfigureThresholdsFormIsOpen(false),
        [setLevelBeeSensorConfigureThresholdsFormIsOpen]
      ),
    },
  };
};

export default useLevelBeeSensorConfigureThresholdsForm;
