import { isEmpty } from 'lodash';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import { GraphQLError } from 'graphql/error/GraphQLError';

export const getErrorMessages = (error: ApolloError): Array<string> => {
  if (!error.graphQLErrors || !error.graphQLErrors.length) {
    return [error.toString()];
  }

  return error.graphQLErrors.flatMap((graphQLError: GraphQLError) => {
    if (
      graphQLError.extensions &&
      graphQLError.extensions.data &&
      graphQLError.extensions.data.validationErrors &&
      !isEmpty(graphQLError.extensions.data.validationErrors)
    ) {
      return Object.values(graphQLError.extensions.data.validationErrors);
    }

    return [graphQLError.message];
  });
};

export const getValidationErrors = (
  error: ApolloError | undefined | null
): { [key: string]: any } => {
  return ((error && error.graphQLErrors) || [])
    .map(
      graphQLError =>
        (graphQLError.extensions &&
          graphQLError.extensions.data &&
          graphQLError.extensions.data.validationErrors) ||
        {}
    )
    .reduce(
      (acc, validationErrors) => ({
        ...acc,
        ...validationErrors,
      }),
      {}
    );
};
