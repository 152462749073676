import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faCog } from '@fortawesome/free-solid-svg-icons';
import authentication from '../../../../modules/authentication';
import {
  AuthenticationInterchange,
  homebrewInterchange,
  msalInterchange,
} from '../../../../modules/interchange';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import style from './style.module.scss';

import MenuButton from '../../../MenuButton';
import { ActivityList } from './components/ActivityList';

interface Props {
  className: string;
  isOpen: boolean;
  onMenuButtonClick: () => void;
}

const SideMenu: FC<Props> = ({
  className,
  isOpen,
  onMenuButtonClick,
}: Props): JSX.Element => {
  const location = useLocation();
  const { instance: msalProvider } = useMsal();
  const msalPredicate = useIsAuthenticated();

  const keychain = [
    msalInterchange({ instance: msalProvider, predicate: msalPredicate }),
    homebrewInterchange(),
  ];

  return (
    <div className={[style.container, className].join(' ')}>
      <div className={style.menuButtonContainer}>
        <MenuButton isOpen={true} onClick={onMenuButtonClick} />
      </div>
      <div className={style.miscCommandsContainer}>
        <Button
          tag={Link}
          to={{ pathname: '/settings', state: { from: location.pathname } }}
        >
          <FontAwesomeIcon icon={faCog} fixedWidth={true} size="lg" />
          Settings
        </Button>

        <Button onClick={() => authentication.interchangeLogoff(keychain)}>
          <FontAwesomeIcon icon={faSignOutAlt} fixedWidth={true} size="lg" />
          Log Off
        </Button>
      </div>
      <div className={style.activityContainer}>
        {isOpen && <ActivityList className={style.activityList} />}
      </div>
    </div>
  );
};

export { SideMenu };
