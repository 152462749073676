import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  ozoneSystemName: string;
  ozoneSystemId: string;
  onCompleted?: () => void;
}

interface Result {
  openOzoneRestrictionEnablePrompt: () => void;
  openOzoneRestrictionDisablePrompt: () => void;
  OzoneRestrictionPrompts: typeof OzoneRestrictionPrompts;
  ozoneRestrictionPromptsProps: Props;
}

export const UPDATE_OZONE_SYSTEM_OVERRIDE_RESTRICTION = gql`
  mutation updateOzoneSystemOverrideRestriction(
    $input: UpdateOzoneSystemOverrideRestrictionInputType!
  ) {
    updateOzoneSystemOverrideRestriction(input: $input) {
      ozoneSystem {
        ozoneSystemId
        overrideRestriction
      }
    }
  }
`;

interface Props extends Input {
  disableOzoneRestrictionPromptIsOpen: boolean;
  enableOzoneRestrictionPromptIsOpen: boolean;
  closeDisableOzoneRestrictionPrompt: () => void;
  closeEnableOzoneRestrictionPrompt: () => void;
}

const OzoneRestrictionPrompts = ({
  ozoneSystemName,
  ozoneSystemId,
  enableOzoneRestrictionPromptIsOpen,
  disableOzoneRestrictionPromptIsOpen,
  closeEnableOzoneRestrictionPrompt,
  closeDisableOzoneRestrictionPrompt,
  onCompleted,
}: Props) => {
  const [
    updateOzoneSystemOverrideRestriction,
    { error, loading: saving },
  ] = useMutation(UPDATE_OZONE_SYSTEM_OVERRIDE_RESTRICTION, {
    onCompleted: () => {
      closeEnableOzoneRestrictionPrompt();
      closeDisableOzoneRestrictionPrompt();
      onCompleted && onCompleted();
    },
  });

  return (
    <>
      <PromptModal
        title={`Enable the Ozone Restriction for Ozone System ${ozoneSystemName}`}
        isOpen={enableOzoneRestrictionPromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateOzoneSystemOverrideRestriction({
            variables: { input: { ozoneSystemId, overrideEnabled: false } },
          });
        }}
        onComplete={closeEnableOzoneRestrictionPrompt}
      >
        <p>
          Enabling the ozone restriction will prevent the ozone tank from
          potentially overflowing by halting zone irrigation if the ozone system
          stops communicating.
        </p>

        <p>
          Please verify that the ozone system is not being physically bypassed
          before continuing.
        </p>
        <p>Enable the ozone restriction for ozone system {ozoneSystemName}?</p>
      </PromptModal>

      <PromptModal
        title={`Disable the Ozone Restriction for Ozone System ${ozoneSystemName}`}
        danger={true}
        isOpen={disableOzoneRestrictionPromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateOzoneSystemOverrideRestriction({
            variables: { input: { ozoneSystemId, overrideEnabled: true } },
          });
        }}
        onComplete={closeDisableOzoneRestrictionPrompt}
      >
        <p>
          By disabling the ozone restriction, zones will continue to irrigate
          even if communication with the ozone system is lost, potentially
          overflowing the ozone tank.
        </p>

        <p>
          Please verify that the ozone system is physically being bypassed
          before continuing.
        </p>
        <p>Disable the ozone restriction for Ozone System {ozoneSystemName}?</p>
      </PromptModal>
    </>
  );
};

const useOzoneRestrictionPrompts: (input: Input) => Result = ({
  ozoneSystemName,
  ozoneSystemId,
  onCompleted,
}: Input) => {
  const [
    enableOzoneRestrictionPromptIsOpen,
    setEnableOzoneRestrictionPromptIsOpen,
  ] = useState(false);

  const [
    disableOzoneRestrictionPromptIsOpen,
    setDisableOzoneRestrictionPromptIsOpen,
  ] = useState(false);

  return {
    openOzoneRestrictionEnablePrompt: useCallback(
      () => setEnableOzoneRestrictionPromptIsOpen(true),
      [setEnableOzoneRestrictionPromptIsOpen]
    ),
    openOzoneRestrictionDisablePrompt: useCallback(
      () => setDisableOzoneRestrictionPromptIsOpen(true),
      [setDisableOzoneRestrictionPromptIsOpen]
    ),
    OzoneRestrictionPrompts,
    ozoneRestrictionPromptsProps: {
      ozoneSystemName,
      ozoneSystemId,
      enableOzoneRestrictionPromptIsOpen,
      disableOzoneRestrictionPromptIsOpen,
      onCompleted,
      closeEnableOzoneRestrictionPrompt: useCallback(
        () => setEnableOzoneRestrictionPromptIsOpen(false),
        [setEnableOzoneRestrictionPromptIsOpen]
      ),
      closeDisableOzoneRestrictionPrompt: useCallback(
        () => setDisableOzoneRestrictionPromptIsOpen(false),
        [setDisableOzoneRestrictionPromptIsOpen]
      ),
    },
  };
};

export default useOzoneRestrictionPrompts;
